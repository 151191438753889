import React from 'react';
import '../../assets/css/style.css'
import { Card } from 'react-bootstrap';
import style from './ChangePassword.module.css';
import classnames from 'classnames';
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../config/config";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from 'react-toastify'
import axios from 'axios';
function ChangePassword({ auth }) {
    const history = useHistory()
    const [values, setValues] = React.useState({});
    const [pwconfirm, setpwconfirm] = React.useState(true)
    const [pwcurrent, setpwcurrent] = React.useState(true)
    const [pwnew, setpwnew] = React.useState(true)

    const toasting = (type, text) => {
        if (type === 'error') {
            return toast.error(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        if (type === 'success') {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (!values?.current) return toasting('error', "Current password can't be empty");
        if (!values?.new) return toasting('error', "New password can't be empty");
        if (!values?.confirm) return toasting('error', "Confirm password can't be empty");
        if (values.new !== values.confirm) return toasting('error', "New password & Confirm password doesn't match")
        try {
            await axios.put(`${baseUrl}/vendor/changePassword`, {
                oldpassword: values.current,
                password: values.new
            }, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
                .then((response) => {
                    return toast.success('Password Changed Successfully.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                });
        }
        catch (err) {
            return toasting('error', err.response.message);
        }
    };

    const handledata = (e) => {
        const { name } = e.target
        setValues({ ...values, [name]: e.target.value })
    }

    const handleconfirm = () => {
        setpwconfirm(s => !s);
    };
    const handlenew = () => {
        setpwnew(h => !h);
    };
    const handlecurrent = () => {
        setpwcurrent(a => !a);
    };
    return (
        <Card className="card-white p-5" >
            <div className={classnames('row pl-0', style.cardtitle)}>
                <h1> Change password</h1>
            </div>
            <div className="row pl-0 mt-4" >
                <div className="col-4 pl-0">
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-12">
                            <p className={style.APCLabel}>Enter your current password</p>

                        </div>
                        <div className="col-12">
                            <input type={pwcurrent ? "password" : "text"} name="current" onChange={handledata} className={classnames(style.APInput, style.CPInput, 'p-2')} />
                            <i class={pwcurrent ? "fas fa-eye" : "fa fa-eye-slash"} onClick={handlecurrent} style={{ position: 'absolute', top: 12, right: 40 }}></i>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row pl-0">
                <div className="col-4 pl-0">
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-12">
                            <p className={style.APCLabel}>Enter your new password</p>
                        </div>
                        <div className="col-12">
                            <input type={pwnew ? "password" : "text"} name="new" onChange={handledata} className={classnames(style.APInput, style.CPInput, 'p-2')} />
                            <i class={pwnew ? "fas fa-eye" : "fa fa-eye-slash"} onClick={handlenew} style={{ position: 'absolute', top: 12, right: 40 }}></i>

                        </div>
                    </div>

                </div>
            </div>
            <div className="row pl-0">
                <div className="col-4 pl-0">
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-12">
                            <p className={style.APCLabel}>Confirm password</p>
                        </div>
                        <div className="col-12">

                            <input type={pwconfirm ? "password" : "text"} name="confirm" onChange={handledata} className={classnames(style.APInput, style.CPInput, 'p-2')} />
                            <i class={pwconfirm ? "fas fa-eye" : "fa fa-eye-slash"} onClick={handleconfirm} style={{ position: 'absolute', top: 12, right: 40 }}></i>

                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-4 pl-0">
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-12">
                            <button type="button" onClick={handlesubmit} className={classnames(style.blackButton)}>Submit</button>
                        </div>
                        <div className="col-12">
                            <button type="button" onClick={() => history.push("/account")} className={classnames(style.APMButton)}>Go back</button>
                        </div>
                    </div>

                </div>
            </div>

        </Card>

    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,

});

export default connect(mapStateToProps)(withRouter(ChangePassword));
