import React from 'react'
import { Button as ReactstrapButton, Spinner } from 'reactstrap'

const getVariantProps = {
    "primary": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width,
            backgroundColor:'black'
        },
        size: props.size || 'md'
    }),
    "outline-primary": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width,
            border: "1px solid black",
            color:'black'
        },
        // className: "text-black",
        color: "transparent",
        size: props.size || 'md'
    }),
    "secondary": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width
        },
        className: "text-black",
        color: "secondary",
        size: props.size || 'md'
    }),
    "outline-secondary": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width,
            border: "2px solid #CDC6C3"
        },
        className: "text-primary",
        color: "transparent",
        size: props.size || 'md'
    }),
    "outline-success": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: "7rem",
            border: "2px solid #228B22"
        },
        className: "text-success",
        color: "transparent",
        size: props.size || 'md'
    }),
    "outline-warning": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: "7rem",
            border: "2px solid #ff9966"
        },
        className: "text-warning",
        color: "transparent",
        size: props.size || 'md'
    }),
    "filter": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width,
            border: "2px solid #888888",
            paddingRight: "20px",
            paddingLeft: "20px"
        },
        color: "#888888",
        size: props.size || 'md'
    }),
    "danger": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            borderRadius: props.borderRadius || '42px',
            width: props.width
        },
        color: "danger",
        size: props.size || 'md'
    }),
    "outline-regular": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            width: props.width,
            border: "1px solid #DEDEDE"
        },
        className: "text-primary",
        color: "transparent",
        size: props.size || 'md'
    }),
    "link": ({ children, style, ...props }) => ({
        ...props,
        style: {
            ...style,
            width: props.width,
            color: "#1B74B9",
        },
        color: "link",
        className: "font-weight-400 text-xs",
        size: props.size || 'md'
    }),
}
const Button = (props) => {
    const {
        color,
        isLoading,
        children,
        variant
    } = props
    const getSpinner = () => {
        if (variant === 'primary') {
            return <Spinner size="sm" color="white" />
        } else {
            return <Spinner size="sm" color={color} />
        }
    }
    const variantProps = getVariantProps[variant || 'primary']
    return (
        <ReactstrapButton
            {...variantProps(props)}
        >
            {
                isLoading ? (getSpinner()) : (children || 'Submit')
            }
        </ReactstrapButton>
    )
}
export default Button