import { Badge } from 'reactstrap';
import React, { useState } from 'react';
import { Edit2 } from 'react-feather';

const StatusSelectBox = ({ options, onClickFunc, status, poEdit }) => {
    const statusColor = {
        "CANCELLED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
        "DELIVERED": { color: 'success', padding: 4 },
        "RETURNED": { color: 'danger', padding: 4 },
        "ACCEPTED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
        "SHIPPED": { color: 'success', padding: 4 },
        "UNACCEPTED": { color: 'danger', padding: 4 },
        "ACKNOWLEDGED": { color: "info", padding: 2 },
        "NEW": { color: 'primary', padding: 2 }
    }
    const [selected, setSelected] = useState('')
    const [openSelectBox, setOpenSelectBox] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(status)
    return <div>
        <div style={{ marginRight: 10 }}>
            <Badge color={statusColor[currentStatus].color} style={{ padding: statusColor[currentStatus].padding, cursor: 'pointer', marginleft: statusColor[currentStatus].marginLeft, marginRight: statusColor[currentStatus].marginRight }}>{currentStatus}</Badge>
            {poEdit ? <span> <Edit2 size={20} onClick={() => setOpenSelectBox(prev => !prev)} />
            </span> : null}
        </div>
        <div style={{
            marginRight: 10,
            boxShadow: "4px 8px 10px #cdc6c3",
            borderRadius: 5,
            alignItems: 'center',
            position: 'absolute',
            zIndex: 10,
            right: 5
        }}>
            {poEdit && openSelectBox && options.map((option, i) => {
                return <div style={{
                     display: 'flex', justifyContent: 'center',padding:10,
                    backgroundColor: selected === option.value ? '#F1EEEA' : 'white'
                }}>
                    <Badge onClick={() => {
                        setSelected(option.value)
                        onClickFunc(option.value)
                        setCurrentStatus(option.value)
                        setOpenSelectBox(false)
                    }} color={statusColor[option.value].color} style={{ padding: statusColor[option.value].padding, marginLeft: statusColor[option.value].marginLeft, cursor: 'pointer' }}>{option.label}</Badge>
                </div>
            })}
        </div>
    </div >
}
export default StatusSelectBox;
