/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from "react";
import "../../assets/css/style.css";
import { Card } from "react-bootstrap";
import style from "./AccountInfo.module.css";
import classnames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import { baseUrl } from "config/config";
import { Uploader } from "rsuite";
import classNames from "classnames";
import { toast } from "react-toastify";
import cities from "./Cities.json";
import { Modal, makeStyles } from "@material-ui/core";
import { logout } from "store/actions/authActions";
import ReactSelect from "react-select";
import { Col, Row } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper4: {
    position: "absolute",
    width: "28rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "15rem",
    border: 0,
  },
}));

function AccountInfo({ display, setDisplay, auth, logout }) {
  const [coverImage, setCoverImage] = useState(null);
  const [image, setImage] = useState(null);
  const [edit, setEdit] = useState(false);
  const [profile, setProfile] = useState({});
  const [errors, ] = useState({});
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const [editRequest, setEditRequest] = useState({});
  const [loading, setLoading] = useState(false)
  const adminApiUrl = process.env.REACT_APP_ADMIN_URL;
  let coverImageLink

  if (coverImage) {
    const arr = coverImage.split('/')
    coverImageLink = arr[arr.length - 1].split('-')[1]
  }
  useEffect(() => {
    const getVendor = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        };
        const res = await axios.get(
          `${baseUrl}/vendor/${auth.vendor._id}`,
          config
        );
        setProfile(res.data);
        res.data.bit_img && setImage(res.data.bit_img);
        res.data.cover_img && setCoverImage(res.data.cover_img);
      } catch { }
    };
    getVendor();
  }, []);

  const toasting = (type, text) => {
    if (type === 'error') {
      return toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const styles = {
    width: 200,
    height: 250,
    border: "none",
    background: "transparent",
  };

  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleRequestEdit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      };
      await axios.post(
        `${adminApiUrl}/v1/request`,
        {
          changes: profile,
          status: "REQUESTED",
          type: "VENDOR_PROFILE_EDIT_REQUEST",
          document_id: profile._id,
        },
        config
      );
      setEditRequest(false);
      setEdit(false);
      setModal(false);
      setLoading(false)
      toasting("success", "Edit profile requested successfully.");
    } catch (err) {
      setLoading(false)
      setModal(false)
      toasting("error", err?.response?.data?.error?.message || "Error Occured");
    }
  };

  const handleDeleteRequest = async () => {
    try {
      setLoading(true)
      await axios.post(`${adminApiUrl}/v1/request`, {
        status: "REQUESTED",
        document_id: auth.vendor._id,
        type: "VENDOR_DELETE_REQUEST",
      });
      toasting("success", "Requested successfully.");
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setModal(false)
      toasting("error", err?.response?.data?.error?.message || "Error Occured");
    }
  };

  const handleDeactivateRequest = async () => {
    try {
      setLoading(true)
      await axios.post(`${adminApiUrl}/v1/request`, {
        status: "REQUESTED",
        document_id: auth.vendor._id,
        type: "VENDOR_DEACTIVATE_REQUEST",
      });
      setLoading(false)
      toasting("success", "Requested successfully.");
    } catch (err) {
      setLoading(false)
      setModal(false)
      toasting("error", err?.response?.data?.error?.message || "Error Occured");
    }
  };

  return (
    <Fragment>
      <Card className="card-white p-5">
        <div
          className={classnames(
            "row d-flex justify-content-between",
            style.cardtitle
          )}
        >
          <div>
            <h1>Company Information</h1>
            {edit && (
              <p style={{ marginBottom: "10px" }}>
                *The changes reflect only after the P&M Admin approval
              </p>
            )}
          </div>
          {!edit ? (
            <div className="text-right">
              <h1 style={{ cursor: "pointer" }} onClick={() => logout()}>
                Log Out
              </h1>

              <div
                className="d-flex justify-content-between flex-row"
                style={{ fontSize: "12px" }}
              >
                {/* <div className="row d-flex justify-content-start align-items-center mr-3">
                  <div className="col-12">
                    {!coverImage ? (
                      <Uploader
                        fileListVisible={false}
                        disabled={!edit}
                        listType="picture"
                        onUpload={async (file) => {
                          previewFile(file.blobFile, async (value) => {
                            const formData = new FormData();
                            formData.append("file", file.blobFile);
                            const res = await axios.post(
                              `${baseUrl}/vendor/files`,
                              formData,
                              {
                                headers: {
                                  Authorization: `Bearer ${auth.token}`,
                                },
                              }
                            );
                            if (res) {
                              setCoverImage(res.data.Location);
                              setProfile((prev) => ({
                                ...prev,
                                cover_img: res.data.Location,
                              }));
                              toasting("success", "Cover Image is saved");
                            }
                          });
                        }}
                      >
                        <a
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          <u>Upload Cover Image</u>
                        </a>
                      </Uploader>
                    ) : (
                      <Uploader
                        fileListVisible={false}
                        disabled={!edit}
                        listType="picture"
                        onUpload={async (file) => {
                          previewFile(file.blobFile, async (value) => {
                            const formData = new FormData();
                            formData.append("file", file.blobFile);
                            const res = await axios.post(
                              `${baseUrl}/vendor/files`,
                              formData,
                              {
                                headers: {
                                  Authorization: `Bearer ${auth.token}`,
                                },
                              }
                            );
                            if (res) {
                              setCoverImage(res.data.Location);
                              setProfile((prev) => ({
                                ...prev,
                                cover_img: res.data.Location,
                              }));
                              toasting("success", "Cover Image is saved");

                            }
                          });
                        }}
                      >
                        <a
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          <u>Re-Upload</u>
                        </a>
                      </Uploader>
                    )}
                  </div>
                </div> */}
                <p
                  className="mr-2"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={(e) => {
                    setEdit(true);
                  }}
                >
                  <img
                    src={
                      "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/EditBtn.svg"
                    }
                    alt=""
                    className="mr-2"
                  />
                  {editRequest === true
                    ? "Edit Profile"
                    : "Request edit profile"}
                </p>

                <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/_%402x.svg" />
                <a
                  onClick={() => setDisplay(1)}
                  className="ml-2"
                  style={{ fontSize: "12px" }}
                >
                  Change Password
                </a>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center">
              <button
                className={style.gobackButton}
                onClick={() => setEdit(false)}
              >
                Go back
              </button>
              <button
                className={style.changesButton}
                onClick={() => setModal(true)}
              >
                Send Changes
              </button>
            </div>
          )}
        </div>
        <div className="row pl-5 pr-5 pb-0 mt-2 ">
          <div className="col-2">
            <div className="row">
              <div className="col-12 p-0">
                <div
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    overflow: "hidden",
                    borderRadius: 10,
                    border: "1px solid #CDC6C3",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  {edit && (
                    <img
                      className={style.profileEditIcon}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/EditBtn.svg"
                      }
                      alt=""
                    />
                  )}

                  <Uploader
                    fileListVisible={false}
                    disabled={!edit}
                    listType="picture"
                    onUpload={async (file) => {
                      previewFile(file.blobFile, async (value) => {
                        const formData = new FormData();
                        formData.append("file", file.blobFile);
                        const res = await axios.post(
                          `${baseUrl}/vendor/files`,
                          formData,
                          {
                            headers: {
                              Authorization: `Bearer ${auth.token}`,
                            },
                          }
                        );
                        if (res) {
                          setImage(res.data.Location);
                          setProfile({
                            ...profile,
                            bit_img: res.data.Location,
                          });
                          toasting("success", "Vendor Bit Image is saved");

                        }
                      });
                    }}
                  >
                    <button style={styles}>
                      {image ? (
                        <div
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            height: "100%",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            alt=''
                            src={profile.bit_img}
                            style={{ width: "auto", height: "auto" }}
                          />
                        </div>
                      ) : (
                        <img
                          alt=''
                          src={profile?.bit_img}
                          style={{ width: "auto", height: "auto" }}
                        />
                      )}
                    </button>
                  </Uploader>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 pl-0">
                <b style={{ fontSize: "12px" }}>
                  {profile?.rating ? profile?.rating : 0} Stars
                </b>{" "}
              </div>
              <div className="col-12 pl-0 mt-1">
                <Rating
                  name="read-only"
                  style={{ marginLeft: 0 }}
                  value={parseInt(profile?.rating)}
                  readOnly
                />
              </div>
            </div>
            <div className="row mt-3">
              <div
                className={classnames(
                  "col-12 pl-0 pr-0 ",
                  style.descriptionboxtext
                )}
                style={{ fontWeight: 700 }}
              >
                Company Description
              </div>
              <div className={classnames("col-12 mt-2 pl-0")}>
                <textarea
                  className={style.descriptionbox}
                  disabled={!edit}
                  value={profile?.description}
                  onChange={(e) =>
                    setProfile({ ...profile, description: e.target.value })
                  }
                />
                {errors.description && (
                  <p className={style.errorText}>{errors.description}</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-md-6" style={{ paddingLeft: "90px" }}>
                <div className="row mt-4">
                  <div>
                    <div className="row d-flex justify-content-start align-items-center">
                      <div className="col-12">
                        <p className={style.APLabel}>Vendor ID</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled
                          value={profile?.vendor_id}
                          className={style.APInput}
                        />

                      </div>
                    </div>
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Company Name (as per GST)*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          value={profile?.name}
                          className={style.APInput}
                          onChange={(e) =>
                            setProfile({ ...profile, name: e.target.value })
                          }
                        />
                        {errors.name && (
                          <p className={style.errorText}>{errors.name}</p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>
                          Company Contact Number 1*
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          value={profile?.phone_number}
                          className={style.APInput}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              phone_number: e.target.value,
                            })
                          }
                        />
                        {errors.phone_number && (
                          <p className={style.errorText}>
                            {errors.phone_number}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>
                          Company Contact Number 2*
                        </p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          value={profile?.phone_number2}
                          className={style.APInput}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              phone_number2: e.target.value,
                            })
                          }
                        />
                        {errors.phone_number2 && (
                          <p className={style.errorText}>
                            {errors.phone_number2}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Company Email*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          value={profile?.email}
                          className={style.APInput}
                          onChange={(e) =>
                            setProfile({ ...profile, email: e.target.value })
                          }
                        />
                        {errors.email && (
                          <p className={style.errorText}>{errors.email}</p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Company Address*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          value={profile?.address?.line1}
                          className={style.APInput}
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              address: {
                                ...profile.address,
                                line1: e.target.value,
                              },
                            })
                          }
                        />
                        {errors.addressLine1 && (
                          <p className={style.errorText}>
                            {errors.addressLine1}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="row ">
                          <div className="col-12">
                            <p className={style.APLabel}>Zip code *</p>
                          </div>
                         
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          type="number"
                          value={profile?.address?.pincode}
                          className={style.APInput}
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              address: {
                                ...profile.address,
                                pincode: e.target.value,
                              },
                            })
                          }
                        />
                        {errors.addressPinCode && (
                          <p className={style.errorText}>
                            {errors.addressPinCode}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Country*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              address: {
                                ...profile.address,
                                country: e.target.value,
                              },
                            })
                          }
                          value={profile?.address?.country}
                          className={style.APInput}
                        />
                        {errors.addressCountry && (
                          <p className={style.errorText}>
                            {errors.addressCountry}
                          </p>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ paddingLeft: "90px" }}>
                <div className="row mt-4">
                  <div>
                    <div className="row d-flex justify-content-start align-items-center">
                      <div className="col-12">
                        <p className={style.APLabel}>POC Name*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({ ...profile, poc_name: e.target.value })
                          }
                          value={profile?.poc_name}
                          className={style.APInput}
                        />
                        {errors.poc_name && (
                          <p className={style.errorText}>{errors.poc_name}</p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>POC Contact Number*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              poc_contact: e.target.value,
                            })
                          }
                          value={profile?.poc_contact}
                          className={style.APInput}
                        />
                        {errors.poc_contact && (
                          <p className={style.errorText}>
                            {errors.poc_contact}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>POC Email ID*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              poc_email: e.target.value,
                            })
                          }
                          value={profile?.poc_email}
                          className={style.APInput}
                        />
                        {errors.poc_email && (
                          <p className={style.errorText}>{errors.poc_email}</p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>CIN Number (if any)*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              cin_number: e.target.value,
                            })
                          }
                          value={profile?.cin_number}
                          className={style.APInput}
                        />
                        {errors.cin_number && (
                          <p className={style.errorText}>
                            {errors.cin_number}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Deliverable Location*</p>
                      </div>
                      <div className="col-12">
                        {edit ? (
                          <ReactSelect
                            options={[
                              ...cities.map((d) => ({
                                ...d,
                                label: d.name,
                                value: d.name,
                              })),
                            ]}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            required
                            placeholder="Select deliverable location"
                            // ref={component => {
                            //   this.node = component;
                            // }}
                            isMulti
                            // handleChange={}
                            // components={{ DropdownIndicator }}
                            components={{ LoadingIndicator: null }}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                address: {
                                  ...profile.address,
                                  deliverable_location: e?.map((d) => d.value),
                                },
                              })
                            }
                            isClearable={false}
                            hideSelectedOptions={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        ) : (
                          <input
                            type="text"
                            className={style.APInput}
                            style={{ width: "100%" }}
                            // onChange={(e) => setProfile({...profile,  average_delivery_hours: parseInt(e.target.value)  })}
                            value={profile?.address?.deliverable_location}
                          />
                        )}
                        {errors.addressDelivery && (
                          <p className={style.errorText}>
                            {errors.addressDelivery}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Average shipping time*</p>
                      </div>
                      <div className="col-7">
                        <div
                          style={{
                            borderBottom: "1px solid #CDC6C3",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <input
                            type="text"
                            disabled={!edit}
                            style={{ border: "none", width: "90%" }}
                            onChange={(e) =>
                              setProfile({
                                ...profile,
                                average_delivery_hours: parseInt(
                                  e.target.value
                                ),
                              })
                            }
                            defaultValue={parseInt(
                              profile.average_delivery_hours
                            )}
                          />
                          <span style={{ fontSize: "12px" }}>hrs</span>
                        </div>
                        {errors.average_delivery_hours && (
                          <p className={style.errorText}>
                            {errors.average_delivery_hours}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mt-3">
                      <div className="col-12">
                        <p className={style.APLabel}>Link to Vendor shipping and returns policy*</p>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          disabled={!edit}
                          onChange={(e) =>
                            setProfile({
                              ...profile,
                              shipping_returns_link: e.target.value,
                            })
                          }
                          value={profile?.shipping_returns_link}
                          className={style.APInput}
                        />
                        {errors.shipping_returns_link && (
                          <p className={style.errorText}>
                            {errors.shipping_returns_link}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row d-flex justify-content-start align-items-center mr-3">
                      <div className="col-12 mt-4">
                        {!coverImage ? (
                          <Uploader
                            fileListVisible={false}
                            disabled={!edit}
                            listType="picture"
                            onUpload={async (file) => {
                              previewFile(file.blobFile, async (value) => {
                                const formData = new FormData();
                                formData.append("file", file.blobFile);
                                const res = await axios.post(
                                  `${baseUrl}/vendor/files`,
                                  formData,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${auth.token}`,
                                    },
                                  }
                                );
                                if (res) {
                                  setCoverImage(res.data.Location);
                                  setProfile((prev) => ({
                                    ...prev,
                                    cover_img: res.data.Location,
                                  }));
                                  toasting("success", "Cover Image is saved");
                                }
                              });
                            }}
                          >
                            <a
                              style={{
                                color: "#000",
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              <u>Upload Cover Image</u>
                            </a>
                          </Uploader>
                        ) : (
                          <Row>
                            <Col> <a
                              style={{
                                color: "#000",
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                              href={coverImage}
                              download
                              target='_blank' rel="noreferrer"
                            >
                              <u>{coverImageLink}</u>
                            </a></Col>
                            <Col>
                              <Uploader
                                fileListVisible={false}
                                disabled={!edit}
                                listType="picture"
                                onUpload={async (file) => {
                                  previewFile(file.blobFile, async (value) => {
                                    const formData = new FormData();
                                    formData.append("file", file.blobFile);
                                    const res = await axios.post(
                                      `${baseUrl}/vendor/files`,
                                      formData,
                                      {
                                        headers: {
                                          Authorization: `Bearer ${auth.token}`,
                                        },
                                      }
                                    );
                                    if (res) {
                                      setCoverImage(res.data.Location);
                                      setProfile((prev) => ({
                                        ...prev,
                                        cover_img: res.data.Location,
                                      }));
                                      toasting("success", "Cover Image is saved");
                                    }
                                  });
                                }}
                              >
                                <a
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    // color: '#1B74B9'
                                  }}
                                >
                                  <u>Re-Upload</u>
                                </a>
                              </Uploader>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div >
            </div >
          </div >
        </div >
        <div
          className="row d-flex justify-content-between pl-5 pr-5 mt-5"
          style={{ fontWeight: "bold" }}
        >
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={handleDeleteRequest}
          >
            Request for Deleting account
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleDeactivateRequest}>
            Request to Deactivate account
          </div>
        </div>
      </Card >
      <Modal
        open={modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classnames(classes.modal1, "mui-modal")}
      >
        <form
          className={classes.paper4}
          onSubmit={handleRequestEdit}
          autoComplete="on"
        >
          <h6>Are you sure you want to send the changes for approval ?</h6>
          <div className="d-flex flex-row">
            <button className={classNames(style.blackYesButton)} type="submit" disabled={loading} style={{ opacity: loading ? 0.5 : 1 }}
            >
              {loading ? 'Loading...' : 'Yes'}
            </button>
            <button
              className={classNames(style.whiteNoButton)}
              type="button"
              onClick={() => setModal(false)}
            >
              No
            </button>
          </div>
          <p style={{ fontSize: "0.8rem" }}>
            *The changes reflect only after the P&M Admin approval
          </p>
        </form>
      </Modal>
    </Fragment >
  );
}

const mapsStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapsStateToProps, { logout })(withRouter(AccountInfo));

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 0,
    textAlign: "center",
    fontSize: "12px",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    borderBottom: "1px solid rgba(205, 198, 195, 1)",
    height: "30px",
    textAlign: "center",
    overflowY: "auto",
  }),

  dropdownIndicator: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
    "& svg": { display: "none" },
  }),

  dropdownIndicators: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
  placeholder: () => ({
    fontSize: "12px",
    padding: "0px",
    textAlign: "center",
  }),

  multiValue: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 100,
    background: "#7BC0D3",
    textAlign: "center",
    color: "#4DA9FF",
    display: "flex",
    fontSize: "12px",
    // marginTop: '10px'
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#FFFFFF",
    textAlign: "center",
    maxWidth: "120px",
    paddingRight: "6px",
    fontSize: "10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000",
    display: "none",
    ":hover": {
      color: "white",
      fontSize: "12px",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 0px",
  }),
};
