/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Switch, withRouter } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import Home from '../views/Dashboard';
import Account from "../views/Account/Account"
import sidebarImage from "assets/img/sidebar-3.jpg";
import Orders from '../views/Orders/Orders';
import StockMaintenance from "views/StockMaintenance/StockMaintenance";
import FinancesPage from "views/FinancesPage/FinancesPage";
import ProductCatalogue from '../views/ProductCatalogue/ProductCatalogue';
import NotificationPage from '../views/NotificationPage/NotificationPage';
import { searchAction } from '../store/actions/authActions'
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           render={(props) => <prop.component {...props} />}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const [query, setQuery] = useState("");
  const [string, setString] = useState("");

  const handleQuery = (value) => {
    searchAction(value);
    setQuery(value)
  }
  return (
    <>
      <ToastContainer />
      <div className="wrapper">

        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar query={handleQuery} searchString={(e) => setString(e)} />
          <div className="content" style={{ height: '100%', minHeight: '100vh' }}>
            <Switch>
              <Route path="/dashboard" render={() => <Home query={query} searchString={string} />} />
              <Route path="/orders" render={() => <Orders query={query} searchString={string} />} />
              <Route path="/stocks" render={() => <StockMaintenance query={query} searchString={string} />} />
              <Route path="/account" render={() => <Account query={query} searchString={string} />} />
              <Route path="/finances" render={() => <FinancesPage query={query} searchString={string} />} />
              <Route path="/product-catalogue" render={() => <ProductCatalogue query={query} searchString={string} />} />
              <Route path="/notification" render={() => <NotificationPage query={query} searchString={string} />} />
            </Switch>

          </div>

        </div>
      </div>
      <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      />
    </>
  );
}

const mapsStateToProps = (state) => ({
  auth: state.auth
})


export default connect(mapsStateToProps, { searchAction })(withRouter(Admin));

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener("resize", updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener("resize", updateScreenSize);
//   }, []);


//   return screenSize;
// }
