/* eslint-disable array-callback-return */
import VerticalCarousel from 'components/VerticalCarousel/VerticalCarousel';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import style from './Products.module.css'
import { useHistory } from 'react-router-dom';
import { fetchProducts } from "../../store/actions/productActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classnames from 'classnames';

import { likeIcon } from '../../assets/icons/index'
const Products = ({ products }) => {
    const history = useHistory()
    const data1 =
        [
            { id: 'All Products', introline: 'All Products' },
            { id: 'Bed', introline: 'Bed' },
            { id: 'Decor', introline: 'Decor' },
            { id: 'Dining Table', introline: 'Dining Table' },
            { id: 'Lighting', introline: 'Lighting' },
            { id: 'Mattress', introline: 'Mattress' },
            { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
            { id: 'Seating', introline: 'Seating' },
            { id: 'Sofa', introline: 'Sofa' },
            { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
            { id: 'Storage', introline: 'Storage' },
            { id: 'Tables', introline: 'Tables' },
            { id: 'Wall Coverings', introline: 'Wall Coverings' },
            { id: 'Wardrobes', introline: 'Wardrobes' }
        ]

    const [category, setcategory] = React.useState([])
    const handleElement = (e) => {
        // const {name,image,category} =products.filter((d)=> d.category === data1[e].id)
        if (e === 0) {
            setcategory(products.length && products.map((da) => da),
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31-1.png' },
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31.png' },
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31-1.png' },
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31.png' },
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31-1.png' },
                { name: 'Platair Chair', likes: 0, moodboards: 0, sold: 0, image: 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Rectangle+31.png' }
            )
        }
        else {
            setcategory(products.filter((d) => d.category === data1[e].id))
        }
    }
    const renderColumns = () => {

        let cols = [];
        let rows = [];
        category?.length && category.map((data,) => {
            cols.push(
                <div className={classnames("d-flex justify-content-between align-items-center flex-column", style.cardContainer)} >
                    <div className="d-flex justify-content-center align-items-center h-100 img">
                        <div style={{ overflow: 'hidden', maxWidth: '200px', maxHeight: '200px' }}>
                            <img alt='' src={data.image[0]} style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </div>

                    {data.tags[0] === "DP" && <button className={style.DPButton}>Designer's Pick</button>}
                    {data.tags[0] === "BS" && <button className={style.DPButton}>Best Seller</button>}

                    <Row style={{ width: '100%' }} >
                        <Col className="pr-0" sm={6}>
                            <p className="font-weight-700" style={{ fontSize: '0.9rem' }}>{data.name}</p>
                        </Col>
                        <Col className="pl-0" sm={6} >
                            <div className="d-flex justify-content-end align-item-center">
                                <p style={{ fontSize: '0.8rem' }}>0 Likes &nbsp;</p>
                                <img alt='' src={likeIcon} />
                            </div>
                            <div className="d-flex justify-content-end align-item-center mt-1">
                                <p style={{ fontSize: '0.8rem' }}>0 Moodboards &nbsp;</p>
                                <img alt='' src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+61.svg'} />

                            </div>
                            <div className="d-flex justify-content-end align-item-center mt-1 mb-1">
                                <p style={{ fontSize: '0.8rem' }}>0 Sold &nbsp; </p>
                                <img alt='' src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+62+(1).svg'} />
                            </div>

                        </Col>
                    </Row>
                </div>
            );
        });
        if (cols.length) {
            rows.push(
                <div className="row d-flex align-items-start justify-content-start w-100" key={rows.length}>
                    {cols}
                </div>
            );
        }
        return rows;
    };

    const handleproduct = () => {
        history.push("/product-catalogue")
    }
    return (
        <>

            <Row>
                <Col sm={5}>
                    <h2 className={classnames("font-weight-700", style.header)}>Know Your Products</h2>
                </Col>

                <Col className="d-flex justify-content-end align-items-center">
                    <p className={style.gotoproducts}><u onClick={handleproduct} style={{ cursor: "pointer" }}>Go to Product Catalogue</u></p>

                </Col>

            </Row>
            <br />
            <Card style={{ backgroundColor: '#fff', height: '100%', borderRadius: 10 }} >
                <Card.Body>
                    <Row>
                        <Col sm={5} style={{ paddingLeft: '24px', paddingTop: '24px' }}>
                            <h4 className="font-weight-700">Products</h4>
                            <VerticalCarousel data={data1} activeElement={handleElement} />
                        </Col>
                        <Col className="p-0 pl-3 mt-4" style={{ height: '100%', paddingLeft: '10px', overflow: 'scroll', overflowX: 'hidden', maxHeight: '60vh', marginRight: '10px', paddingTop: '24px' }} >
                            <h4 className="font-weight-700" style={{ marginLeft: '-0.8rem' }}>Insights</h4>
                            <div style={{ width: '100%' }}>
                                {renderColumns()}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}
const mapStateToProps = (state) => ({
    products: state.products.products,
});

export default connect(mapStateToProps, { fetchProducts })(
    withRouter(Products)
);