import React, { useEffect, useState } from "react";
import style from "./StockMaintenance.module.css";
import { Row, Card } from "react-bootstrap";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classnames from 'classnames';
import axios from "axios";
import { baseUrl } from "../../config/config";
import { fetchProducts } from "../../store/actions/productActions";
import UnopDropdown from "unop-react-dropdown";
import Loading from "react-fullscreen-loading";
import { Modal, makeStyles } from "@material-ui/core";
import ProductEdit from './EditProduct';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import close from "../../assets/img/Close.svg";
import { InStockButton } from "components";
import { StockingOutButton } from "components";
import { OutOfStockButton } from "components";
import { DownloadReport } from "components";
import { sortDown } from "assets/icons";
import { sortUp } from "assets/icons";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "auto",
  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "56vh",
  },

  paper4: {
    position: "absolute",
    width: 498,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    paddingLeft: '21px',
    paddingRight: '41px',
    paddingTop: '36px',
    paddingBottom: '36px',
    boxShadow: theme.shadows[5],
    height: "auto",
  },

  // root: {
  //   "&:hover": {
  //     backgroundColor: "transparent",
  //   },
  // },
  icon: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 10,
      height: 10,

      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  typo: {
    fontSize: "7px",
  },
  filterHeader: {
    fontSize: "7px",
  },
  root: {
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },

}));

function StyledRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checked={props.value}
      checkedIcon={
        <span className={classnames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const StockMaintenance = ({ products, location, auth, searchString }) => {
  const classes = useStyles();

  const columns = [
    { text: "Product Image", width: "14%" },
    { text: "Product ID", width: "11%" },
    { text: "Product Name", width: "10%" },
    { text: "Category", width: "10%" },
    { text: "Regular Price", width: "10%" },
    { text: "Sales Price", width: "10%" },
    { text: "Qty", width: "10%" },
    { text: "Stock Status", width: "15%" },
  ];

  const [editStock, setEditStock] = useState(false);
  const [prodData, setProdData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOption, setFilterOption] = useState({});
  const [downloadfilters, setDowloadfilters] = useState({});
  const [sortState, setSortState] = useState({
    product: false,
  });



  useEffect(() => {
    fetchProducts(auth.token)
  }, [auth.token])

  const handleIncrement = (product, index) => {
    if (parseInt(product.stock) < 0) return;
    setProdData(prodData.map((d) => ({ ...d, stock: d._id === product._id ? (d.stock + 1) : d.stock, edit: true })))
  }

  const handleDecrement = (product, index) => {
    if (parseInt(product.stock) <= 0) return;
    setProdData(prodData.map((d) => ({ ...d, stock: d._id === product._id ? (d.stock - 1) : d.stock, edit: true })));
  }


  const handleUpdate = async () => {
    let arr = prodData.filter((d) => d.stock !== products.find((f) => f._id === d._id).stock);
    try {
      setLoading(true);
      await axios.put(`${baseUrl}/product/stock`, { products: arr });
      window.setTimeout(() => {
        setLoading(false);
      }, 1000)

      fetchProducts(auth.token);
      setEditStock(false);

    }
    catch (err) {
      setLoading(false);
    }
  }




  useEffect(() => {
    if (location.state) {
      switch (location.state.activeTab) {
        case 2:
          return setProdData(products.filter((d) => parseInt(d.stock) > 15))
        case 1:
          return setProdData(products.filter((d) => (parseInt(d.stock) < 15) && (parseInt(d.stock) > 0)))
        case 0:
          return setProdData(products.filter((d) => parseInt(d.stock) <= 0))
        default:
          return null
      }
    } else {
      if (products) {
        setProdData(products)
      }
    }
  }, [location, products])


  const handleFilter = () => {
    switch (filterOption.value) {
      case 'instock':
        return setProdData(products.filter((d) => parseInt(d.stock) > 15))
      case 'stockingout':
        return setProdData(products.filter((d) => (parseInt(d.stock) > 0) && (parseInt(d.stock) < 15)))
      case 'outofstock':
        return setProdData(products.filter((d) => parseInt(d.stock) <= 0))
      case 'category':
        return setProdData(products.filter((d) => d.category === filterOption.category))
      default:
        return null
    }
  }

  const [modal, setModal] = useState({ bool: false, f: null });
  const [modal1, setModal1] = useState("");

  const data1 =
    [
      { id: 'All Products', introline: 'All Products' },
      { id: 'Bed', introline: 'Bed' },
    { id: 'Decor', introline: 'Decor' },
    { id: 'Dining Table', introline: 'Dining Table' },
    { id: 'Lighting', introline: 'Lighting' },
    { id: 'Mattress', introline: 'Mattress' },
    { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
    { id: 'Seating', introline: 'Seating' },
    { id: 'Sofa', introline: 'Sofa' },
    { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
    { id: 'Storage', introline: 'Storage' },
    { id: 'Tables', introline: 'Tables' },
    { id: 'Wall Coverings', introline: 'Wall Coverings' },
    { id: 'Wardrobes', introline: 'Wardrobes' }
    ]
  const [ap, setap] = useState([])
  const headers = [
    { label: "Product ID", key: "_id" },
    { label: "Product Name", key: "name" },
    { label: "category", key: "category" },
    { label: "Regular Price", key: "price" },
    { label: "Sales Price", key: "sales" },
    { label: "Qty", key: "stock" },
    { label: "color", key: "color" },
    { label: "style", key: "style" },
    { label: "sku", key: "SKU" },
    { label: "material", key: "material" }

  ];
  const addtype = (e) => {
    let arr1
    if (e.target.value === "All Products") {
      arr1 = products.map((p) => p)
    }
    else {
      arr1 = products.filter((p) => p.category === e.target.value)
    }
    setap(arr1)
  }

  const handleSort = (name, isDesc) => {
    if (isDesc && name === "product") {
      setSortState({ ...sortState, product: false });
      let arr = products.sort((a, b) =>
        a.stock < b.stock ? 1 : -1
      );
      setProdData(arr);
    }
    if (!isDesc && name === "product") {
      setSortState({ ...sortState, product: true });
      let arr = products.sort((a, b) =>
        a.stock > b.stock ? 1 : -1
      );

      setProdData(arr);
    }
  };


  return (
    <div style={{ paddingLeft: '16px', paddingRight: '24px', paddingTop: '60px', }} >
      {/* {loading&&<div style={{ position: 'fixed', zIndex: 100, top: '25%', left: '50%' }}> */}
      <Loading loading={loading} background=' rgba(0, 0, 0, 0.4)' loaderColor="#000" />
      {/* </div>} */}
      <div className="d-flex justify-content-between align-items-center flex-row mb-5 mt-3" >
        <div style={{ width: '60%' }}>
          <h2 className="font-weight-700">Stock Maintenance</h2>
        </div>
        <div className={"d-flex flex-row justify-content-between align-items-center"} style={{ width: 'auto' }}>
          {!editStock ?
            <>
              <UnopDropdown align="CENTER" closeOnClickOut={true}
                trigger={
                  <button type="button"
                    className={classnames(
                      "filter-button mr-2"
                    )}
                  >
                    <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Vector.png" style={{ marginTop: '7px', marginRight: '7px', width: '25px', height: '25px' }} />
                    &nbsp;
                    <span >Filters</span>
                  </button>}
              >
                <div style={{ backgroundColor: 'white', borderRadius: 10, width: '200px', padding: '20px', boxShadow: '4px 8px 10px rgba(99, 94, 88, 0.2)', marginTop: '10px', marginLeft: '-5%' }}>
                  <FormControl component="fieldset">
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "12px",
                          opacity: 0.5,
                          marginLeft: "-1%",
                          fontWeight: 700,
                        }}
                      >
                        FILTERS
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          opacity: 0.5,
                          marginLeft: "-1%",
                          textDecoration: 'underline',
                          fontWeight: 700,
                        }}
                        onClick={() => {
                          setFilterOption('');
                          setProdData(products)
                        }}
                      >
                        Clear All</p>
                    </div>

                    <RadioGroup
                      defaultValue="none"
                      aria-label="gender"
                      name="customized-radios"
                      value={filterOption.value}
                    // onChange={(e) => setFilterOption({ ...filterOption, value: e.target.value })}
                    >
                      <FormControlLabel
                        value="instock"
                        className={style.marginBottom}
                        control={<StyledRadio value={filterOption.value === 'instock'} />}
                        onClick={(e) => setFilterOption({ ...filterOption, value: 'instock' })}
                        label={<p style={{ fontWeight: 400, fontSize: '14px' }}>In Stock</p>}
                      />
                      <FormControlLabel
                        value="stockingout"
                        className={style.marginBottom}
                        control={<StyledRadio value={filterOption.value === 'stockingout'} />}
                        onClick={(e) => setFilterOption({ ...filterOption, value: 'stockingout' })}
                        label={<p style={{ fontWeight: 400, fontSize: '14px' }}>Stocking out</p>}
                      />
                      <div className={style.divider}></div>
                      <FormControlLabel
                        value="outofstock"
                        className={style.marginBottom}
                        control={<StyledRadio value={filterOption.value === 'outofstock'} />}
                        onClick={(e) => setFilterOption({ ...filterOption, value: 'outofstock' })}
                        label={<p style={{ fontWeight: 400, fontSize: '14px' }}>Out of stock</p>}
                      />
                      <div className="mt-3 mb-2" style={{ borderBottom: '1px solid rgba(205, 198, 195, 1)' }}></div>
                      <FormControlLabel
                        value="category"
                        className={style.marginBottom}
                        control={<StyledRadio value={filterOption.value === 'category'} />}
                        onClick={(e) => setFilterOption({ ...filterOption, value: 'category' })}
                        label={<p style={{ fontWeight: 400, fontSize: '14px' }}>Category</p>}
                      />
                    </RadioGroup>
                  </FormControl>


                  {filterOption.value === 'category' &&
                    <select onChange={(e) => setFilterOption({ ...filterOption, category: e.target.value })} className="form-control mt-2" style={{ fontSize: '14px' }}>
                      {category_data.sort((a, b) => a.id.localeCompare(b.id)).map((d) => <option value={d.id} >{d.id}</option>)}
                    </select>
                  }

                  <button
                    type="button"
                    className={classnames(
                      "btn btn-dark mt-3",
                      style.filterButton2
                    )}
                    style={{
                      width: '100%'
                    }}
                    onClick={handleFilter}
                  >
                    FILTER
                  </button>
                </div>
              </UnopDropdown>


              <DownloadReport type="button"
                className={classnames(
                  "mr-2"
                )}
                onClick={() => setEditStock(true)}
                title="Edit Stock"
              >

              </DownloadReport>
              <DownloadReport type="button" onClick={() => setModal1("DOW")}
                className={classnames(
                  ""
                )}
              >
                Download Report
              </DownloadReport>
            </>
            :
            <button type="button"
              className={classnames(
                "btn btn-dark",
                style.editStockBtn
              )}
              onClick={handleUpdate}
            >
              Update Stock
            </button>}
        </div>
      </div>
      <table className={classNames("mb-2", style.table)}>
        <tr style={{ height: 40 }}>
          {columns.map((d) => (
            <td className={style.tableColumntd} style={{ padding: "10px", width: d.width }}> <p style={{ fontWeight: 700 }}>
              {d.text}
              {d.text === "Qty" &&
                (sortState.product ? (
                  <img
                    alt=''
                    src={sortDown}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("product", true)}
                  />
                ) : (
                  <img
                    alt=''
                    src={sortUp}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("product", false)}
                  />
                ))}
            </p>
            </td>
          ))}
        </tr>
      </table>
      <Card className="card-white" style={{ borderRadius: "8px", marginTop: "16px" }}>
        <table className={style.table_tbody}>
          {prodData.filter((d) => searchString === "" || (d && d.name.toLowerCase().startsWith(searchString.toLowerCase())))?.map((f, i) => (
            <div style={{ width: '100%' }}>
              <tr style={{ verticalAlign: "initial", display: 'inline-table' }}>
                <td
                  className={style.tableContenttd}
                  style={{
                    textAlign: "center",
                    width: "15%",
                    top: 0,
                    padding: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "11.5vw",
                      maxHeight: '10.5vw',
                      marginLeft: '10%',
                      height: '10vw',
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      overflow: 'hidden',
                      borderRadius: "10px",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      alt=''
                      src={f.image[0]}
                      style={{
                        width: "10vw",
                        height: "auto"
                      }}
                    />
                  </div>
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ width: "10%" }}
                >
                  {f.SKU}
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ width: "10%" }}
                >
                  {f.name}
                </td>
                <td
                  style={{ width: "10%" }}
                  className={style.tableContenttd}
                >
                  {f.category}
                </td>
                <td
                  style={{ width: "10%" }}
                  className={style.tableContenttd}
                >
                  ₹ {f.price}
                </td>
                <td className={style.tableContenttd} style={{ width: "10%", justifyContent: "center" }}>
                  <p>₹ {(f.price - f.price * (f.sales / 100)).toFixed(0)}</p>
                </td>
                <td className={style.tableContenttd} style={{ width: "10%" }}>
                  {!editStock ?
                    <p>
                      {f.stock}
                      <br />
                    </p>
                    :
                    <div className="d-flex justify-content-center align-items-center">
                      <img onClick={() => handleDecrement(f, i)} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+3273.svg'} alt='' />
                      <input type="number" value={f.stock || ''} onChange={(e) => setProdData(prodData.map((d) => ({ ...d, stock: d._id === f._id ? e.target.value : d.stock })))} className={style.editInput} />
                      <img onClick={() => handleIncrement(f, i)} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+3274.svg'} alt='' />

                    </div>}
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ width: "15%", padding: "10px 10px", position: 'relative' }}
                >

                  {/* <button
                  type="button"
                  className={
                    f.stock_status === true
                      ? style.instockbutton
                      : style.nullstockbutton
                  }
                >
                  {f.stock_status === true ? "In Stock" : "Out of Stock"}
                </button>
                {/* {editStock && (<img className={style.editIcon} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(3).svg'} alt='' />)} */}
                  {/* <button
                  type="button"
                  style={{marginLeft: -30}}
                  className={
                    (parseInt(f.stock) > 15)
                      ? style.instockbutton
                      : (parseInt(f.stock) > 0 && parseInt(f.stock) < 15)
                        ?
                        style.stockingoutButton
                        :
                        style.nullstockbutton
                  }

                >
                  {(parseInt(f.stock) > 15)
                    ? "In Stock"
                    : (parseInt(f.stock) > 0 && parseInt(f.stock) < 15)
                      ? "Stocking out"
                      : "Out of stock"

                  }

                </button> */}
                  <div className="d-flex justify-content-center align-items-center">
                    {(parseInt(f.stock) > 15)
                      ? <InStockButton />
                      : (parseInt(f.stock) > 0 && parseInt(f.stock) < 15)
                        ?
                        <StockingOutButton />
                        :
                        <OutOfStockButton />}
                  </div>
                  {editStock && <div className={style.editProductContainer} onClick={() => setModal({ ...modal, bool: "EDIT", product: f })}>
                    <p className={style.editProduct}>Edit Product</p>
                  </div>}

                </td>
              </tr>
              <div style={{ width: '100%', zIndex: -1, }} className="d-flex justify-content-center align-items-center mt-2">
                <div style={{ width: '95%', border: '0.5px solid rgba(205, 198, 195, 0.5)' }} />
              </div>
            </div>
          ))}
        </table>
      </Card>

      <ProductEdit open={modal} setOpen={setModal} auth={auth} product={modal?.product} />

      <Modal
        open={modal1 === 'DOW'}
        aria-labelledby="simple-modal-title"
        className={classnames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"

      >
        <form className={classes.paper4}>
          <div className={style.APHeader}>
            <h2
              style={{ fontSize: "2rem", fontWeight: "bolder" }}

            >
              Report
            </h2>
            <img src={close} alt="close" onClick={() => setModal1(null)} />
          </div>
          <div
            className={classnames(style.APBody,)}
            style={{ padding: "10px" }}
          >
            <Row>
              <p
                className={classnames(style.APlab, "col-4 ")}
                style={{ width: "100%", marginTop: "25px" }}
              >
                Select report:
              </p>

              <select
                className={classnames(style.APborder, "col-8")}
                style={{ width: "10%", fontSize: '14px', textAlign: "center", marginTop: "20px" }}
                name="status"
                id="report"
                onChange={addtype}

              ><option value="none">Select report</option>
                {data1.sort((a, b) => a.id > b.id ? 1 : 0).map((d) => {
                  return (
                    <option value={d.id}>{d.id}</option>)
                })}
              </select>
            </Row>

            <Row>

              <p
                className={classnames(style.APlab, "col-3 ")}
                style={{ width: "20vw", marginTop: "25px" }}
              >
                Dates:
              </p>

              <div className="d-flex flex-row mt-4 col-9">
                <input type="date" style={{ width: '147px' }} className={style.APborder} onChange={(e) => setDowloadfilters({ ...downloadfilters, from: e.target.value })} />
                <span style={{ fontWeight: "900", fontSize: '14px', width: "1vw", marginTop: "3px", paddingRight: '21px', paddingLeft: '14px' }}>-</span>
                <input type="date" style={{ width: '147px' }} className={style.APborder} onChange={(e) => setDowloadfilters({ ...downloadfilters, to: e.target.value })} />
              </div>

            </Row>

            <Row>
              <p
                className={classnames(style.APlab, "col-4 ")}
                style={{ width: "20vw", marginTop: "25px", }}
              >
                Select format:
              </p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ marginTop: "20px", fontSize: '14px', textAlignLast: 'center' }}
                name="file"
                id="file"
              >
                <option lassName="col-4" value="pdf">
                  CSV
                </option>
              </select>
            </Row>
          </div>



          <div className={style.APDown}>
            <button
              type="button"
              className={classnames("btn btn-dark", style.filterButton1)}
            >

              <CSVLink
                data={ap}
                headers={headers}
                className={style.DownloadCatalouge}
                filename={"Report.csv"}
              >
                Download
              </CSVLink>

            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  products: state.products.products,
  newOrders: state.orders.newOrders,
  pastOrders: state.orders.pastOrders,
  openOrders: state.orders.openOrders,
  users: state.orders.users,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(StockMaintenance));

const category_data =
  [
    { id: 'Bed', introline: 'Bed' },
    { id: 'Decor', introline: 'Decor' },
    { id: 'Dining Table', introline: 'Dining Table' },
    { id: 'Lighting', introline: 'Lighting' },
    { id: 'Mattress', introline: 'Mattress' },
    { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
    { id: 'Seating', introline: 'Seating' },
    { id: 'Sofa', introline: 'Sofa' },
    { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
    { id: 'Storage', introline: 'Storage' },
    { id: 'Tables', introline: 'Tables' },
    { id: 'Wall Coverings', introline: 'Wall Coverings' },
    { id: 'Wardrobes', introline: 'Wardrobes' }
  ]