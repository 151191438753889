/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Account from "views/Account/Account.js";

import {
  ProductCatalogueIcon,
  Icon2,
  Icon3,
  Icon4, 
  Icon5,
  Icon6
} from './assets/icons/index';
const dashboardRoutes = [
  
  {
    path: "/dashboard",
    name: "Home",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/HOMMME.svg",
    active: Icon3,
    component: "",
    layout: "",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Order+Icon.svg",
    active: Icon4,
    component: UserProfile,
    layout: "",
  },
  {
    path: "/stocks",
    name: "Stock Maintenance",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Stonks.svg",
    active: Icon5,
    component: TableList,
    layout: "",
  },
  {
    path: "/product-catalogue",
    name: "Product Catalogue",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Product+Catalouge.svg",
    active: ProductCatalogueIcon,
    component: Typography,
    layout: "",
  },
  {
    path: "/finances",
    name: "Finances",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Finance.svg",
    active: Icon2,
    component: Icons,
    layout: "",
  },
  {
    path: "/account",
    name: "Account Settings",
    icon: "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Account.svg",
    active:Icon6,
    component:Account,
    layout: "",
  },
];

export default dashboardRoutes;
