import React from "react";
import '../assets/css/common.css';
import {
  Container,
} from "react-bootstrap";
import OrderStatus from "./OrderStatus/OrderStatus";
import Products from "./Products/Products";
import StockStatus from "./StockStatus/StockStatus";
import NOrders from "./newOrders/NOrders"
import Finances from "./Finances/Finances";
import Review from "./Review/Review";


function Dashboard({ query, searchString }) {

  return (
    <div>
      <Container fluid style={{ paddingTop: '60px' }}>
        {searchString.length === 0 ? <NOrders /> : ("new orders").toLowerCase().startsWith(searchString) && <NOrders />}
        <div style={{ marginTop: '72px' }}></div>
        {searchString.length === 0 ? <Finances /> : ('finances').toLowerCase().startsWith(searchString) && <Finances />}

        <div style={{ marginTop: '72px' }}></div>
        {searchString.length === 0 ? <Products /> : ('know your products').toLowerCase().startsWith(searchString) && <Products />}

        <div style={{ marginTop: '64px' }}></div>
        {searchString.length === 0 ? <OrderStatus /> : ('order status').toLowerCase().startsWith(searchString) && <OrderStatus />}

        <div style={{ marginTop: '64px' }}></div>
        {searchString.length === 0 ? <StockStatus /> : ('stock status').toLowerCase().startsWith(searchString) && <StockStatus />}

        <div style={{ marginTop: '64px' }}></div>
        {searchString.length === 0 ? <Review /> : ('reviews').toLowerCase().startsWith(searchString) && <Review />}



      </Container>
    </div>
  );
}

export default Dashboard;
