/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar, Container, Nav} from "react-bootstrap";
import { logout } from '../../store/actions/authActions';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import msgicon from "../../assets/img/Path_1432.svg"
import account_icon from "../../assets/img/account_icon.svg"

function Header({ logout, query, searchString }) {
  const location = useLocation();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  // const mobileSidebarToggle = (e) => {
  //   e.preventDefault();
  //   document.documentElement.classList.toggle("nav-open");
  //   var node = document.createElement("div");
  //   node.id = "bodyClick";
  //   node.onclick = function () {
  //     this.parentElement.removeChild(this);
  //     document.documentElement.classList.toggle("nav-open");
  //   };
  //   document.body.appendChild(node);
  // };

  const dispatchQuery = (value) => {
    query(value);
    return value;
  }

  useEffect(() => {
    searchString(searchQuery);
  }, [searchQuery])


  return (
    <Navbar expand="lg" style={{ zIndex: 10, width: '100%' }}>
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            <Nav className="nav" navbar>
              {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              > */}
              <div className='d-flex justify-content-between w-100'>
                <input placeholder={`Search Your ${location.pathname === '/dashboard'
                    ? dispatchQuery("Boards")
                    : location.pathname === '/orders'
                      ? dispatchQuery("Orders")
                      : location.pathname === '/stocks'
                        ? dispatchQuery("Stock Maintenance")
                        : location.pathname === "/product-catalogue"
                          ? dispatchQuery("Product Catalogue")
                          : location.pathname === "/finances"
                            ? dispatchQuery("Finances")
                            : location.pathname === "/account"
                              ? dispatchQuery("Finances")
                              : ""
                  }`} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ border: 'none', backgroundColor: '#fff', opacity: 0.5, width: '300px' }} />
                <img alt='' style={{ width: '8%', marginTop: '2%' }} src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Search+Icon.svg" /> <br />
              </div>
              {/* </Nav.Link>
            </Nav.Item> */}
            </Nav>
            <Nav className="" navbar >
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >

                  <div style={{ width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt='' src={msgicon} />
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  onClick={(e) => { e.preventDefault(); history.push('/notification') }}
                >
                  <div style={{
                    width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: location.pathname === '/notification' && 'rgb(204, 198, 195)',
                    borderRadius: location.pathname === '/notification' && 5
                  }}>
                    <img alt='' src="  https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/svg/Path+1947.svg" />
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  href="#pablo"
                  onClick={() => { history.push("/account") }}
                  style={{ paddingTop: '11px' }}
                >
                  <div style={{ width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt='' src={account_icon} />
                  </div>

                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(Header));
