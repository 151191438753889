/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./styles.css";


/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-vertical-carousel-component-in-react
 */

const VerticalCarousel = ({ data, leadingText, activeElement }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const scroll = useRef();

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 52;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  }


  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  React.useEffect(() => {
    activeElement(activeIndex)
  }, [activeIndex])

  //  useEffect(() => {
  //   window.addEventListener('scroll', (event) => {
  //     var ele = document.getElementById("vertical");
  //   })
  //  },[])

  const [scrollingValue, setScrollingValue] = useState(0);

  const handleScroll = () => {
    setScrollingValue(scroll.current.getBoundingClientRect().top)
    if (scroll.current.getBoundingClientRect().top > scrollingValue) {
      handleClick("prev")
      // determinePlacement(activeIndex+2)
    }
    if (scroll.current.getBoundingClientRect().top < scrollingValue) {
      handleClick("next")
    }
  }

  const dimensions = useMediaQuery();

  return (
    <div className="row">
      <div className="col-sm-11">
        <section className="outer-container1">
          <div className="carousel1-wrapper">
            <button
              type="button"
              className="carousel-button1 prev"
              onClick={() => handleClick("prev")}
            >
              {/* <Prev /> */}
            </button>

            <div className="carousel1">
              <div className="slides1">
                {/* <button  onClick={() => handleClick("prev")}></button> */}
                <div className="carousel-inner1" style={{ height: dimensions[0] > 1500 ? '50vh' : '44vh' }}>
                  {data.map((item, i) => (
                    <button
                      // type="button"
                      onClick={() => setActiveIndex(i)}
                      className={cn("carousel-item1 visible ", {
                        active: activeIndex === i,

                        visible:
                          Math.abs(determinePlacement(i)) <= visibleStyleThreshold
                      })}
                      key={item.id}
                      style={{
                        transform: `translateY(${determinePlacement(dimensions[0] > 2000 ? i + ((dimensions[0]) / 600) : i + 1)}px)`,
                        marginTop: activeIndex === i && '-10px'
                      }}
                    >
                      {item.introline}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <button
              type="button"
              className="carousel-button1 next"
              onClick={() => handleClick("next")}
            >
              {/* <Next /> */}
            </button>
          </div>
        </section>
      </div>
      <div className="col-sm-1">
        <div className="vertical" onScroll={handleScroll} style={{ height: '100%', maxHeight: '60vh', overflow: 'scroll' }}>
          <div ref={scroll} style={{ height: '40px' }}></div>
          {Array(16).fill().map((e) => <div style={{ height: '40px' }}></div>)}
        </div>
      </div>
    </div>
  );
};

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);


  return screenSize;
}


VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired
};

export default VerticalCarousel;
