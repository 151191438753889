import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from "./store/store";
// import { registerServiceWorker }from "./register-sw";
// import ReactNotification from 'react-notifications-component'

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/animate.min.css";
// import "./assets/css/demo.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/style.css";
import './assets/css/common.css';
// import 'react-notifications-component/dist/theme.css'

// import 'react-dropzone-uploader/dist/styles.css';
// registerServiceWorker();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ReactNotification /> */}
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
