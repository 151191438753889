const initialState = {
  products: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case "POST_PRODUCTS":
      return {
        ...state,
        products: action.products,
      };
    case "REMOVE_PRODUCTS":
      return {
        ...state,
        products: [],
      };
    default:
      return state;
  }

}
