/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, forwardRef, useRef } from 'react'
import { Row, Col, Input, FormFeedback, FormGroup } from 'reactstrap';
import { Modal, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { closeOutline } from "assets/icons";
import style from "../Orders.module.css"
import Button from 'components/uiComponents/Button';
import ProductListComponent from './ProductDetails'
import { useFormik } from "formik"
import { INITIAL_SHIPMENT } from './constants';
import { shipmentValidation } from './validation';
import ReactDatePicker from 'react-datepicker';
import { baseUrl } from "../../../config/config";
import axios from "axios";
import style2 from '../Drawers/Drawer.module.css';
import NotificationAlert from "react-notification-alert"
import { fetch_orders } from "../../../store/actions/ordersActions";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
        height: "auto",
    },
    paper1: {
        position: "absolute",
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(4, 4, 4),
        height: "auto",
    },

    paper2: {
        position: "absolute",
        width: 280,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        margin: "10px",
        height: "56vh",
    },

    paper4: {
        position: "absolute",
        width: 498,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 3),
        margin: "10px",
        height: "58vh",
    },

    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 10,
        height: 10,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "green",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 10,
            height: 10,

            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
    typo: {
        fontSize: "7px",
    },
    filterHeader: {
        fontSize: "7px",
    },
    // root: {
    //     "& .MuiFormLabel-root": {
    //         color: "red", // or black
    //     },
    // },
}));
const ShipmentModalComponent = ({ shipmentModal, setShipmentModal, currentOrder, setCurrentOrder, setSelectedOrderItemIds, selectedOrderItemIds, token, setToggleDrawer, orders, setOrders }) => {
    const classes = useStyles();
    const [selectProductsModal, setSelectProductModal] = useState(false)
    const [shipmentDetailsModal, setShipmentDetailsModal] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [mode, setMode] = useState('CREATE')
    const [createShipmentBody, setCreateShipmentBody] = useState({})
    const [shipments, setShipments] = useState([])
    const [currentShipment, setCurrentShipment] = useState({})
    const [invoiceFileUrl, setInvoiceFileUrl] = useState("")
    const [eWayBillUrl, setEWayBillUrl] = useState("")
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [invoiceFile, setInvoiceFile] = useState(null)
    const [eWayFile, setEwayFile] = useState(null)

    const notificationAlertRef = useRef(null)
    const notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title text-center" data-notify="title">
                        {message}
                    </span>
                </div>
            ),
            type: type,
            // icon: "ni ni-bell-55",
            autoDismiss: 7,
            zIndex: 20,
            closeButton: false

        }
        notificationAlertRef.current.notificationAlert(options)
    }
    useEffect(() => {
        const getShipments = async () => {
            if (currentOrder?.order?._id) {
                try {
                    const res = await axios.get(`${baseUrl}/shipment/order/${currentOrder.order.order_id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                    if (res.status === 200) {
                        setShipments(res.data)

                    }
                } catch (error) {

                }
            }

        }
        getShipments()
    }, [JSON.stringify(currentOrder)])
    const handleUploadFile = async (e, type) => {
        const formData = new FormData()
        formData.append("file", e.target.files[0])
        if (type === 'invoice') {
            setInvoiceFile(e.target.files[0])
        } else {
            setEwayFile(e.target.files[0])
        }
        try {
            const res = await axios.post(`${baseUrl}/shipment/upload-file/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (res.status === 200) {
                if (type === 'invoice') {
                    setInvoiceFileUrl(res.data.Location)
                } else {
                    setEWayBillUrl(res.data.Location)
                }
            }
        } catch (error) {
            // alert(error.response.data);
        }

    }
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) =>
        mode === 'CREATE' || mode === 'EDIT' ?
            <div className="example-custom-input d-flex flex-row w-100" style={{ border: '2px solid #CDC6C3', padding: 5, }} onClick={onClick} ref={ref}>
                <p style={{ width: '100px', }}> {value}</p>
                <img alt='' style={{ marginLeft: '4%' }} src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+158.svg" />
            </div> : <div>{value}</div>

    );
    const submitShipment = (data) => {
        if (selectedOrderItemIds.length) {
            setConfirmationModal(true)
            setShipmentDetailsModal(false)
            setCreateShipmentBody({
                ...data,
                order_item_ids: selectedOrderItemIds,
                orderId: currentOrder.order.order_id,
                order_id: currentOrder.order.order_id,
                vendor_id: currentOrder.vendorId,
                invoice: invoiceFileUrl,
                e_way_bill: eWayBillUrl
            })
            if (mode === 'CREATE') {
                formik.setValues(INITIAL_SHIPMENT)
                setSelectedOrderItemIds([])
            }
        }
    }

    const onConfirmation = async () => {
        try {
            let res
            setConfirmLoading(true)
            if (mode === 'CREATE') {
                res = await axios.post(`${baseUrl}/shipment`, createShipmentBody, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (res?.status === 200) {
                    const shipmentOutput = res.data
                    if (currentOrder.orderShipment) {
                        const orderItems = currentOrder.orderItem.filter(o => shipmentOutput.order_item_ids.includes(o.orderItemId))
                        currentOrder.shipments = [...currentOrder.shipments, { ...shipmentOutput, orderItems }]

                    } else {
                        currentOrder.orderShipment = true
                        const orderItems = currentOrder.orderItem.filter(o => shipmentOutput.order_item_ids.includes(o.orderItemId))
                        currentOrder.shipments = [{ ...shipmentOutput, orderItems }]
                    }
                    currentOrder.orderItem = currentOrder.orderItem.filter(o => !shipmentOutput.order_item_ids.includes(o.orderItemId))
                }
                setCurrentOrder(currentOrder)
                setConfirmationModal(false)
            } else {
                res = await axios.patch(`${baseUrl}/shipment/${currentShipment._id}`, createShipmentBody, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (res?.status === 200) {
                    let shipmentOrderItemData = []
                    let orderData = []
                    const shipmentIndex = currentOrder.shipments.findIndex(shipment => shipment._id === currentShipment._id)
                    currentOrder.shipments[shipmentIndex].orderItems.map(orderItem => {
                        if (selectedOrderItemIds.includes(orderItem.orderItemId)) {
                            shipmentOrderItemData.push(orderItem)
                        } else {
                            orderData.push(orderItem)
                        }
                    })
                    currentOrder.orderItem.map(orderItem => {
                        if (selectedOrderItemIds.includes(orderItem.orderItemId)) {
                            shipmentOrderItemData.push(orderItem)
                        }
                    })
                    currentOrder.orderItem = [...currentOrder.orderItem.filter(o => !selectedOrderItemIds.includes(o.orderItemId)), ...orderData]
                    currentOrder.shipments[shipmentIndex] = { ...currentOrder.shipments[shipmentIndex], ...createShipmentBody, orderItems: shipmentOrderItemData }
                    setCurrentOrder(currentOrder)
                    const index = shipments.findIndex(s => s._id === currentShipment._id)
                    shipments[index] = createShipmentBody
                    setShipments(shipments)
                    const orderIndex = orders.findIndex(o => o.order._id === currentOrder.order._id)
                    orders[orderIndex] = currentOrder
                    setOrders(orders)
                    setConfirmationModal(false)
                }
            }
            if (res?.status === 200) {
                setConfirmationModal(false)
                setToggleDrawer(true)
                setMode('CREATE')
                const pastStatus = ['DELIVERED', 'CANCELLED', 'UNACCEPTED']
                if (currentOrder.orderShipment && currentOrder.shipments.every(shipment => shipment.orderItems.every(ot => pastStatus.includes(ot.status)))) {
                    // setOrders(orders.filter(o => o.order._id == currentOrder.order._id))
                    fetch_orders(token)
                }
            }
            const formData = new FormData();
            eWayFile && formData.append("file", eWayFile);
            invoiceFile && formData.append("file", invoiceFile);
            formData.append('order_id', currentOrder.order.order_id)
            formData.append('vendor_id', currentOrder.vendorId)
            if (invoiceFile || eWayFile) {
                await axios.post(`${baseUrl}/shipment/attach-po`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
            }
            setConfirmLoading(false)
            notify("success", mode === 'CREATE' ? 'Shipment is successfully created' : 'Shipment is successfully updated')
            setInvoiceFile(null)
            setEwayFile(null)
        } catch (error) {
            setConfirmLoading(false)
            notify("danger", error?.response?.data?.error?.message || "Something went wrong")
        }
    }

    const formik = useFormik({
        initialValues: INITIAL_SHIPMENT,
        validationSchema: shipmentValidation,
        onSubmit: (data) => {
            submitShipment(data)
        },
    })

    const columns = [
        { text: "S.No", width: "10%" },
        { text: "Shipment Name", width: "30%" },
        { text: "No.of Items", width: "30%" },
        { text: "Action", width: "30%" },
    ];
    return <>
        <NotificationAlert ref={notificationAlertRef} />

        <Modal
            open={shipmentModal}
            aria-labelledby="simple-modal-title"
            className={classnames(classes.modal, 'mui-modal')}
            aria-describedby="simple-modal-description"
        >
            <form className={classes.paper1}   >
                <div className={style.APHeader} style={{ margin: 20 }} >
                    <h2 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>Manage Orders</h2>
                    <img alt='' src={closeOutline} onClick={() => setShipmentModal(false)} />

                </div>
                <div className="p-divider" style={{ marginTop: 20 }}></div>
                {shipments.length === 0 ? <div style={{ minHeight: "50vh" }} className='mt-5'>
                    <Row className='text-muted d-flex justify-content-center ' tag='h3' >No Shipment found</Row>
                    <Row className='d-flex justify-content-center'>
                        <Button
                            size="sm"
                            variant="primary"
                            style={{ backgroundColor: 'black' }}
                            onClick={() => {
                                setMode('CREATE')
                                setEwayFile(null)
                                setEWayBillUrl("")
                                setInvoiceFile(null)
                                setInvoiceFileUrl("")
                                formik.setValues(INITIAL_SHIPMENT)
                                setSelectedOrderItemIds([])
                                setShipmentModal(false)
                                setSelectProductModal(true)
                            }}
                        >Create Shipment</Button>
                    </Row>
                </div> : <div style={{ marginTop: 10, margin: 5, maxHeight: 400, minHeight: 300, overflow: 'scroll', overflowX: 'hidden', }}>
                    <table className="mb-2 w-100">
                        <tr
                            style={{
                                textAlign: "center",
                                borderRadius: "8px",
                                backgroundColor: "#CDC6C3",
                                marginTop: "10%",
                                width: "100%",

                            }}
                        >
                            {
                                columns.map(d => {
                                    return <td style={{ padding: "10px", width: d.width, fontWeight: 700 }}>
                                        {
                                            d.text
                                        }
                                    </td>

                                })
                            }
                        </tr>
                    </table>
                    <table className={style2.table} style={{ width: "100%" }}>
                        {
                            shipments.map((shipment, i) => {
                                return <tr style={{
                                    textAlign: "center",
                                    // verticalAlign: "initial",
                                    // display: 'inline-table',
                                    marginTop: 0,
                                    fontWeight: 100,

                                }}>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            width: "10%",
                                            padding: "10px",
                                            // position: "relative",

                                        }}
                                    >
                                        {i + 1}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            width: "30%",
                                            padding: "10px",
                                            // position: "relative",

                                        }}
                                    >
                                        {shipment.name}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            width: "30%",
                                            padding: "10px",
                                            // position: "relative",

                                        }}
                                    >
                                        {shipment.order_item_ids.length}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            width: "30%",
                                            padding: "10px",
                                            // position: "relative",
                                        }}
                                    >
                                        <a className="text-blue pb-2"
                                            style={{ fontSize: 12, textDecoration: "underline", pointer: 'cursor' }}
                                            onClick={() => {
                                                setCurrentShipment(shipment)
                                                setInvoiceFileUrl(shipment.invoice)
                                                setEWayBillUrl(shipment.e_way_bill)
                                                formik.setValues({ ...shipment, delivery_date: new Date(shipment.delivery_date), dispatch_date: new Date(shipment.dispatch_date) })
                                                setSelectedOrderItemIds(shipment.order_item_ids)
                                                setMode('EDIT')
                                                setShipmentModal(false)
                                                setShipmentDetailsModal(true)

                                            }}>Edit</a>
                                    </td>
                                </tr>

                            })
                        }
                    </table>
                    {currentOrder.orderItem.length ? <Row style={{ position: 'absolute', bottom: 20, right: 30 }}>
                        <Button
                            size="sm"
                            variant="primary"
                            style={{ backgroundColor: 'black' }}
                            onClick={() => {
                                setMode('CREATE')
                                setEwayFile(null)
                                setEWayBillUrl("")
                                setInvoiceFile(null)
                                setInvoiceFileUrl("")
                                formik.setValues(INITIAL_SHIPMENT)
                                setSelectedOrderItemIds([])
                                setShipmentModal(false)
                                setSelectProductModal(true)
                            }}
                        >Create Shipment</Button>
                    </Row> : null}

                </div>}
            </form>
        </Modal>
        <Modal open={selectProductsModal}
            aria-labelledby="simple-modal-title"
            className={classnames(classes.modal, 'mui-modal')}
            aria-describedby="simple-modal-description"
        >
            <form className={classes.paper1} style={{ margin: 20 }} >
                <div className={style.APHeader} style={{ margin: 20 }} >
                    <h2 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>Select product for current shipment</h2>
                    <img alt='' src={closeOutline} onClick={() => setSelectProductModal(false)} />

                </div>
                <div className='d-flex justify-content-end mr-3'>
                    <a className="text-blue pb-2"
                        style={{ fontSize: 12, textDecoration: "underline" }}
                        onClick={() => {
                            const temp = currentOrder.orderItem.map(o => o.orderItemId)
                            setSelectedOrderItemIds([...temp])
                        }}
                    >Select All</a>
                </div>
                <div className={style.divider} />
                <div style={{ margin: 10, maxHeight: 400, overflow: 'scroll', overflowX: 'hidden', }}>
                    {
                        mode === 'EDIT' ? (currentOrder.orderShipment ? <>{currentOrder.shipments.map(shipment => {
                            return shipment._id === currentShipment._id ? shipment.orderItems.map((p, i) => {
                                return <ProductListComponent
                                    selectableProducts
                                    index={i}
                                    orderItem={p}
                                    columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                    setSelectedOrderItemIds={setSelectedOrderItemIds}
                                    selectedOrderItemIds={selectedOrderItemIds} poEdit={false} />
                            }) : null
                        })}
                            <div>
                                {currentOrder?.orderItem?.length ? currentOrder.orderItem.map((p, i) => {
                                    return <ProductListComponent
                                        selectableProducts
                                        index={i}
                                        orderItem={p}
                                        columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                        setSelectedOrderItemIds={setSelectedOrderItemIds}
                                        selectedOrderItemIds={selectedOrderItemIds} poEdit={false} />
                                }) : null
                                }
                            </div>
                        </>
                            : currentOrder?.orderItem?.length && currentOrder.orderItem.map((p, i) => {
                                return <ProductListComponent
                                    selectableProducts
                                    index={i}
                                    orderItem={p}
                                    columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                    setSelectedOrderItemIds={setSelectedOrderItemIds}
                                    selectedOrderItemIds={selectedOrderItemIds} poEdit={false} />
                            })) : currentOrder?.orderItem?.length && currentOrder.orderItem.map((p, i) => {
                                return <ProductListComponent
                                    selectableProducts
                                    index={i}
                                    orderItem={p}
                                    columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                    setSelectedOrderItemIds={setSelectedOrderItemIds}
                                    selectedOrderItemIds={selectedOrderItemIds} poEdit={false} />
                            })
                    }
                </div>

                <div style={{ backgroundColor: '#EFEFEF', paddingTop: 10, paddingBottom: 10 }}>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col sm={8} className='ml-2'>{selectedOrderItemIds?.length} Item selected</Col>
                        <Col><Button size="sm"
                            className='mr-1'
                            block
                            variant="primary"
                            style={{ backgroundColor: 'black' }}
                            onClick={() => {
                                if (selectedOrderItemIds.length) {
                                    setSelectProductModal(false)
                                    setShipmentDetailsModal(true)
                                }
                            }}>
                            Next
                        </Button></Col>
                    </Row>

                </div>
            </form>
        </Modal>
        <Modal open={shipmentDetailsModal}
            aria-labelledby="simple-modal-title"
            className={classnames(classes.modal, 'mui-modal')}
            aria-describedby="simple-modal-description"
        >
            <form className={classes.paper1} style={{
                width: "50%",
            }}
                onSubmit={formik.handleSubmit}
            >
                <div className={style.APHeader} style={{ margin: 20 }} >
                    <h2 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>Add Shipment Details</h2>
                    <img alt='' src={closeOutline} onClick={() => setShipmentDetailsModal(false)} />
                </div>
                <div style={{ margin: 20 }}>
                    <Row style={{ fontSize: 12 }}>
                        <Col>
                            <FormGroup>
                                <label >
                                    Tracking ID
                                </label>
                                <Input
                                    id="tracking_id"
                                    variant="secondary"
                                    placeholder="Type here"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_id}
                                    invalid={
                                        formik.touched.tracking_id && formik.errors.tracking_id && true
                                    }
                                    style={{ fontSize: 12, }}
                                />
                                <FormFeedback>{formik.errors.tracking_id}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label >
                                    E- way bill no
                                </label>
                                <Input
                                    id="e_way_bill_no"
                                    variant="secondary"
                                    placeholder="Type here"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.e_way_bill_no}
                                    invalid={
                                        formik.touched.e_way_bill_no && formik.errors.e_way_bill_no && true
                                    }
                                    style={{ fontSize: 12 }}
                                />
                                <FormFeedback>{formik.errors.e_way_bill_no}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <label >
                                Invoice*  (PDF Format)
                            </label>
                            {invoiceFileUrl ? <a className="text-blue pb-2"
                                style={{ fontSize: 12, textDecoration: "underline", marginRight: 3 }}
                                href={invoiceFileUrl}
                                download
                            >invoice.pdf</a> : null}
                            <input type="file" id="invoice" name="invoice" onChange={(e) => {
                                handleUploadFile(e, "invoice")
                            }} accept="application/pdf" style={{ display: 'none' }} />
                            <label
                                style={{ fontSize: 12, textDecoration: "underline", color: 'blue' }}
                                htmlFor="invoice"
                            >
                                {invoiceFileUrl ? "re-upload" : "upload"}
                            </label>
                        </Col>
                        <Col>
                            <label >
                                E-way Bill  (PDF Format)
                            </label>
                            {eWayBillUrl ? <a className="text-blue pb-2"
                                style={{ fontSize: 12, textDecoration: "underline" }}
                                href={eWayBillUrl}
                                download
                            >e_way_bill.pdf</a> : null}
                            <input type="file" id="eWayBill" name="eWayBill" onChange={(e) => {
                                handleUploadFile(e, "eWayBill")
                            }} accept="application/pdf" style={{ display: 'none' }} />
                            <label
                                style={{ fontSize: 12, textDecoration: "underline", color: 'blue' }}
                                htmlFor="eWayBill"
                            >
                                {eWayBillUrl ? "re-upload" : 'upload'}
                            </label>


                        </Col>
                    </Row>
                    <Row style={{ fontSize: 12 }}>
                        <Col sm={3}>
                            <FormGroup>
                                <label >
                                    Dispatch Date*
                                </label>
                                <ReactDatePicker
                                    id="dispatch_date"
                                    name="dispatch_date"
                                    selected={formik.values.dispatch_date}
                                    onChange={(date) => {
                                        formik.setValues({ ...formik.values, dispatch_date: date })

                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    customInput={<ExampleCustomInput />}
                                    invalid={
                                        formik.touched.dispatch_date && formik.errors.dispatch_date && true
                                    }
                                />
                                <FormFeedback>{formik.errors.dispatch_date}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <label >
                                    Delivery Date*
                                </label>
                                <ReactDatePicker
                                    id="delivery_date"
                                    name="delivery_date"
                                    selected={formik.values.delivery_date}
                                    onChange={(date) => {
                                        formik.setValues({ ...formik.values, delivery_date: date })
                                    }}
                                    dateFormat="dd-MM-yyyy"
                                    customInput={<ExampleCustomInput />}
                                    invalid={
                                        formik.touched.delivery_date && formik.errors.delivery_date && true
                                    }

                                />
                                <FormFeedback>{formik.errors.delivery_date}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label >
                                    Tracking Link*
                                </label>
                                <Input
                                    id="tracking_link"
                                    variant="secondary"
                                    placeholder="Type here"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tracking_link}
                                    invalid={
                                        formik.touched.tracking_link && formik.errors.tracking_link && true
                                    }
                                    style={{ fontSize: 12 }}
                                />
                                <FormFeedback>{formik.errors.tracking_link}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ fontWeight: 700, marginTop: 10 }}>Selected Items ({selectedOrderItemIds?.length})</div>
                    <div className={style.divider} />
                    <div style={{ maxHeight: 250, overflow: 'scroll', overflowX: 'hidden', }} >
                        {
                            currentOrder.orderShipment ? currentOrder.shipments.map(shipment => {
                                return shipment._id === currentShipment._id ? shipment.orderItems.map((p, i) => {
                                    return (selectedOrderItemIds.length && selectedOrderItemIds.findIndex(a => a === p.orderItemId) !== -1) ? <ProductListComponent
                                        token={token}
                                        currentOrder={currentOrder}
                                        setCurrentOrder={setCurrentOrder}
                                        index={i}
                                        orderItem={p}
                                        columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                        setSelectedOrderItemIds={setSelectedOrderItemIds}
                                        selectedOrderItemIds={selectedOrderItemIds}
                                        poEdit={mode === 'EDIT'}
                                        orders={orders}
                                        setOrders={setOrders}
                                    /> : null
                                }) : null
                            }) : null}
                        {currentOrder?.orderItem?.length ? currentOrder.orderItem.map((p, i) => {
                            return selectedOrderItemIds.length && selectedOrderItemIds.findIndex(a => a === p.orderItemId) !== -1 ? <ProductListComponent
                                token={token}
                                currentOrder={currentOrder}
                                setCurrentOrder={setCurrentOrder}
                                index={i}
                                orderItem={p}
                                columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                                setSelectedOrderItemIds={setSelectedOrderItemIds}
                                selectedOrderItemIds={selectedOrderItemIds}
                                poEdit={mode === 'EDIT'}
                                orders={orders}
                                setOrders={setOrders}
                            /> : null
                        }) : null}

                    </div>
                </div>
                <div style={{ backgroundColor: '#EFEFEF', padding: 10 }}>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col sm={3} className='ml-1'><Button size="sm"
                            block
                            variant="outline-primary"
                            onClick={() => {
                                setSelectProductModal(true)
                                setShipmentDetailsModal(false)
                            }}>
                            Go Back
                        </Button></Col>
                        <Col sm={6}></Col>
                        <Col><Button size="sm"
                            block
                            variant="primary"
                            style={{ backgroundColor: 'black' }}
                            type="submit">
                            Submit
                        </Button></Col>
                    </Row>

                </div>
            </form>
        </Modal>
        <Modal open={confirmationModal}
            aria-labelledby="simple-modal-title"
            className={classnames(classes.modal, 'mui-modal')}
            aria-describedby="simple-modal-description"
        >
            <form className={classes.paper1}   >
                <div style={{ margin: 20 }}>
                    <p >Are you sure want to submit changes for selected order?</p>
                    <div style={{ marginTop: 20 }}>
                        <Row >
                            <Col sm={3}><Button size="sm"
                                block
                                variant="primary"
                                style={{ backgroundColor: 'black' }}
                                isLoading={confirmLoading}
                                disabled={confirmLoading}
                                onClick={() => {
                                    onConfirmation()
                                }}>
                                Yes
                            </Button></Col>
                            <Col sm={3} className='ml-1'><Button size="sm"
                                block
                                variant="outline-primary"
                                disabled={confirmLoading}
                                onClick={() => {
                                    setConfirmationModal(false)
                                }}>
                                No
                            </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </form>
        </Modal>
    </>
}
export default ShipmentModalComponent