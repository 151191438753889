/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import ReactSelect from "react-select";
import { fetchProducts } from "../../store/actions/productActions";
import style from "../StockMaintenance/EditProduct.module.css";
import classNames from "classnames";
import axios from "axios";
import Upload from "rc-upload";
import "../../assets/css/style.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { baseUrl } from "config/config";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-awesome-slider/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import convert from "convert-units";
import UPLOAD from "../../assets/img/UPLOAD.PNG";
import Alertpage from "../ProductCatalogue/Alertpage";
import { LeftArrow } from "assets/icons";
import { LeftArrowActive } from "assets/icons";
import { RightArrow } from "assets/icons";
import { RightArrowActive } from "assets/icons";
import { TAX_SLAB_ARRAY } from "./constants";
import { skipPartiallyEmittedExpressions } from "typescript";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 40000,
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 0),
    height: "auto",
  },
  paper3: {
    position: "absolute",
    width: 327,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: 400,
    border: 0,
  },
  paper4: {
    position: "absolute",
    width: "25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "13rem",
    border: 0,
  },
}));

const EditProduct = ({
  open,
  setOpen,
  setactiveProducts,
  auth,
  parsedData,
  product,
  vendorData
}) => {
  const classes = useStyles();
  const [threeDFiles, setthreeDFiles] = useState(null);

  const [previewImages, setPreviewImages] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parsedCSV, setParsedCSV] = useState([]);
  const [productDetail, setProductDetails] = useState([])
  let [index, setIndex] = useState(1);
  const [bulkSuccessAlert, setBulkSuccessAlert] = useState(false)
  const [bulkImport, setBulkImport] = useState({})
  const [inputs, setInput] = React.useState({
    dimensions: {
      unit: "F&I",
      l: 0,
      b: 0,
      h: 0,
    },
  });
  const [imageLocations, setImageLocations] = useState([])
  const [modelLocations, setModelLocations] = useState("")
  let updatedProductDetails = []
  useEffect(() => {
    let csv = [...parsedData];
    setParsedCSV(csv);
  }, [parsedData]);

  useEffect(() => {
    let obj = {};
    for (var i = 0; i < parsedCSV[0]?.length; i++) {
      if (parsedCSV[0][i] === "dimensions") {
        obj = { ...obj, dimensions: JSON.parse(parsedCSV[index][i]) };
      } else if (parsedCSV[0][i] === "image") {
        let image = JSON.parse(parsedCSV[index][i] || []);
        obj = { ...obj, image: image };
      } else if (parsedCSV[0][i] === "three_model") {
        obj = (parsedCSV[index][i] && Object.keys(parsedCSV[index][i]).length) ? { ...obj, "three_model": JSON.parse(parsedCSV[index][i]) } : { ...obj, three_model: "" }
      } else if (parsedCSV[0][i] === "material") {
        obj = {
          ...obj,
          material: parsedCSV[index][i] ? JSON.parse(parsedCSV[index][i])?.some((d) => d.value)
            ? JSON.parse(parsedCSV[index][i])
            : JSON.parse(parsedCSV[index][i]).map((d) => ({
              ...d,
              label: d,
              value: d,
            })) : [],
        };
      } else if (parsedCSV[0][i] === "room_type") {
        obj = {
          ...obj,
          room_type: parsedCSV[index][i] ? (JSON.parse(parsedCSV[index][i]).some((d) => d.value)
            ? JSON.parse(parsedCSV[index][i])
            : JSON.parse(parsedCSV[index][i]).map((d) => ({
              ...d,
              label: d,
              value: d,
            }))) : [],
        };
      } else if (parsedCSV[0][i] === "SKU") {
        obj = { ...obj, sku: parsedCSV[index][i] };
      } else {
        obj = { ...obj, [parsedCSV[0][i]]: parsedCSV[index][i] };
      }
    }
    Object.keys(obj).length && setInput({ ...obj });
    const arr = obj.image?.length && obj.image.map((d) => ({ ...d, previewUrl: d }));
    setPreviewImages(arr)
  }, [parsedCSV, index]);

  const Preview = () => {
    return (
      <span
        style={{
          alignSelf: "flex-start",
          margin: "10px 3%",
          fontFamily: "Open Sans",
        }}
      >
        <Carousel
          autoFocus={true}
          axis={"horizontal"}
          centerMode={true}
          showThumbs={false}
        >
          {previewImages?.map((d) => (
            <img src={d.previewUrl} alt={d.id} style={{ width: "100%" }} />
          ))}
        </Carousel>
      </span>
    );
  };

  useEffect(() => {
    if (open?.bool === "EDIT") {
      setInput({ ...product, sku: product.SKU, room_type: product.room_type });
      let arr = product.image.map((d) => ({ ...d, previewUrl: d }));
      setPreviewImages(arr);
    }
  }, [product, open]);


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInput({
      ...inputs,
      [name]: value,
      status: false,
      stock: 10,
      user_id: "",
    });
  };

  // function converBase64toBlob(content, contentType) {
  //   contentType = contentType || "";
  //   var sliceSize = 512;
  //   var byteCharacters = window.atob(content); //method which converts base64 to binary
  //   var byteArrays = [];
  //   for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     var slice = byteCharacters.slice(offset, offset + sliceSize);
  //     var byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }
  //     var byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }
  //   var blob = new Blob(byteArrays, {
  //     type: contentType,
  //   }); //statement which creates the blob
  //   return blob;
  // }
  let bgImages = []
  let productIndex = 0
  const recursive2 = async (imgArray, dummyProductDetails) => {
    if (imgArray.length) {
      const imgLink = imgArray[0]
      axios.put(`${baseUrl}/product/upload/bg-image`, {
        image: imgLink,
      }, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }).then(res => {
        if (res.status === 200 && typeof (res.data) === 'string') {
          bgImages.push(res.data)
        }
        const [, ...newArray] = imgArray
        recursive2([...newArray], dummyProductDetails)
      }).catch(err => {
        setLoading(false)

      })
    } else {
      updatedProductDetails[productIndex] = { ...updatedProductDetails[productIndex], no_bg_images: bgImages }
      bgImages = []
      const [, ...newArray] = dummyProductDetails
      productIndex = productIndex + 1
      recursive(newArray)
    }
  }
  const recursive = async (dummyProductDetails) => {
    if (dummyProductDetails.length) {
      const imgArray = dummyProductDetails[0].image
      recursive2([...imgArray], dummyProductDetails)

    } else {
      const blob = new Blob([JSON.stringify(updatedProductDetails)], { type: 'application/json' })
      const fd = new FormData()
      fd.append('file', blob)
      const res1 = await axios.post(`${baseUrl}/product/bulk-import`, fd, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setBulkImport(res1.data)
      if (res1.data.failedSkuArr && res1.data.failedSkuArr.length) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var json = JSON.stringify(res1.data.failedSkuArr)
        const blob2 = new Blob([json], { type: "text/plain" })
        const url = window.URL.createObjectURL(blob2);
        a.href = url;
        a.download = 'errors';
        a.click();
        window.URL.revokeObjectURL(url);
      }
      setOpen({ ...open, bool: "" })
      setBulkSuccessAlert(true)
      setLoading(false)
      window.setTimeout(() => {
        setBulkSuccessAlert(false);
      }, 5000);
      fetchProducts(auth.token)

    }

  }
  const handledata = async (e) => {
    // parsedCSV.length - 1 > index && setIndex(index + 1);
    let data = [...parsedCSV];
    e.preventDefault();
    try {
      data[index] = [
        inputs.sku,
        inputs.category,
        inputs.color,
        inputs.description,
        JSON.stringify(inputs.material),
        inputs.name,
        inputs.pattern,
        inputs.price,
        JSON.stringify(inputs.room_type),
        inputs.stock,
        JSON.stringify(inputs.three_model),
        inputs.style,
        JSON.stringify(inputs.dimensions),
        inputs.sales,
        JSON.stringify(inputs.image),
        inputs.tax_slab,
        inputs.hsn_code,
        inputs.subcategory,
        inputs.variant


        // image,
        // image?.length,
        // threeDFiles,
        // threeDFiles?.length,
        // previewImages.map((d) => ({ ...d, previewUrl: d.previewUrl })),
      ];  
      console.log("data", data, data[index])
      setParsedCSV(data);
      // setImage([]);
      setthreeDFiles(null);
      // setPreviewImages([]);
      if (productDetail.length === index - 1) {
        let obj = {};
        const values = data[index]
        values.forEach(async (value, id) => {
          if (data[0][id] === "dimensions") {
            obj = { ...obj, dimensions: JSON.parse(values[id]) };
          } else if (data[0][id] === "three_model") {
            obj = (values[id] && Object.keys(values[id]).length) ? { ...obj, "three_model": JSON.parse(values[id]) } : { ...obj, three_model: "" }
          } else if (data[0][id] === "image") {
            let image = JSON.parse(values[id]);

            obj = { ...obj, image: image };

          } else if (data[0][id] === "material") {
            obj = {
              ...obj,
              material: values[id] ? JSON.parse(values[id]).map((d) =>
                d?.value ? d.value : d
              ) : [],
            };
          } else if (data[0][id] === "room_type") {
            obj = {
              ...obj,
              room_type: value[id] ? JSON.parse(values[id]).map((d) =>
                d?.value ? d.value : d
              ) : [],
            };
          } else if (data[0][id] === "SKU") {
            obj = { ...obj, SKU: values[id] };
          } else if (data[0][id] === "sales") {
            obj = { ...obj, sales: values[id] };
          } else {
            obj = { ...obj, [data[0][id]]: values[id] };
          }
        });
        productDetail.push({
          ...obj,
          cost_price: inputs?.price - inputs?.price * ((vendorData?.affiliation_rate || 5) / 100),
          pixel_price: inputs?.price - (inputs.price * ((vendorData?.affiliation_rate || 5) / 100) / 2),
          image: [...obj.image, ...imageLocations],
          '3d_models': { ...obj.three_model, ...modelLocations }, vendor_id: auth.vendor._id
        })
        let arr = productDetail.image && productDetail.image.length && productDetail.image.map((d) => ({ ...d, previewUrl: d }));
        setPreviewImages(arr);
        setProductDetails(productDetail)
        setImageLocations([])
        setModelLocations("")
      }
      const dummyProductDetails = productDetail.slice()
      updatedProductDetails = productDetail
      setLoading(true)
      recursive(dummyProductDetails)

      // setactiveProducts(prev => [...prev, ...res1.data])
    } catch (err) {
      setLoading(false);
    }
  };

  const [image, setImage] = React.useState([]);

  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === "done") {
      if (!image.some((el) => file.name === el.name)) {
        setImage((prev) => [...prev, file]);
        setPreviewImages((prev) => [...prev, meta]);
        const fd = new FormData()
        fd.append('file', file)
        try {
          setLoading(true)
          const response = await axios.post(`${baseUrl}/product/upload-image`, fd, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          });
          setImageLocations(prev => [...prev, response.data.Location])
          setLoading(false)
        } catch (err) {
        }
      } else {
        setImage(image.filter((d) => d.name !== file.name));
        setPreviewImages(previewImages.filter((d) => d.id !== meta.id));
      }
    } else if (status === "aborted") {
    }
  };

  const [start, setStart] = React.useState(null);
  const handlecart = () => {
    setStart(true);
  };
  const close = () => {
    setStart(null);
  };


  const props = {
    accept: ".glb, .usdz",
    multiple: false,
    async onStart(file) {
      setthreeDFiles(file);
      let csv = [...parsedCSV];
      csv[index][17] = file;
      setParsedCSV(csv);
      const fd = new FormData()
      fd.append('file', file)
      setLoading(true)
      const res1 = await axios.post(`${baseUrl}/product/upload-image`, fd, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const filename = file.name.split('.')[1]
      let data = {
        [`${filename}`]: res1.data.Key,
        [`${filename}FileUrl`]: res1.data.Location,
      }
      Object.keys(data).length && setModelLocations(prev => ({ ...prev, ...data }))
      setLoading(false)

    },
    onSuccess(ret) {
    },
    onError(err) { },
  };

  //   useEffect(()=>{
  //     if(open === null){
  //       setImage([]);
  //       setPreviewImages([]);
  //       setInput({...inputs, dimensions: {...inputs.dimensions, l: 0, b:0, h: 0}, price: 0 })
  //     }
  //   },[open])

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  function ImgComponent({ src, alt, classId }) {
    return (
      <div className={"d-flex align-items-center justify-content-center mt-3"}>
        <img
          src={src}
          alt={alt}
          style={{
            width: "17vw",
            height: "24vh",
            margin: "-15px",
            border: "50%",
          }}
        />
        <p>{alt}</p>
      </div>
    );
  }

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axios.put(
        `${baseUrl}/product/delete`,
        {
          _id: inputs._id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setLoading(false);
      setactiveProducts(product.filter((d) => d._id !== inputs._id));
      setOpen({ ...open, bool: "", product: null });
      setSuccessAlert(true);
      window.setTimeout(() => {
        setSuccessAlert(false);
      }, 3000);
      fetchProducts(auth.token);
    } catch (err) { }
  };

  // carousel data iterator
  const ImgDataIterator = previewImages && previewImages.length && previewImages
    ?.map((d) => ({ ...d, src: d.previewUrl }))
    .map((element) => <ImgComponent src={element.src} alt={element.alt} />);

  const [errors, setErrors] = useState(true);

  useEffect(() => {
    if (Object.keys(inputs).length < 16) {
      return setErrors(true);
    } else if (Object.keys(inputs?.dimensions).length < 4) {
      return setErrors(true);
    } else {
      setErrors(false);
    }
  }, [inputs, image, threeDFiles]);

  const handleChangeProduct = () => {
    let data = [...parsedCSV];
    if (data.length) {
      data[index] = [
        inputs.sku,
        inputs.category,
        inputs.color,
        inputs.description,
        JSON.stringify(inputs.material),
        inputs.name,
        inputs.pattern,
        inputs.price,
        JSON.stringify(inputs.room_type),
        inputs.stock,
        JSON.stringify({ ...inputs.three_model, ...modelLocations }),
        inputs.style,
        JSON.stringify(inputs.dimensions),
        inputs.sales,
        JSON.stringify([...inputs.image, ...imageLocations]),
        inputs.tax_slab,
        inputs.hsn_code,
        inputs.subcategory,
        inputs.variant,
      ];
      setParsedCSV(data);
      setImage([]);
      setImageLocations([])
      setModelLocations("")
      setthreeDFiles(null);
      let obj = {};
      if (productDetail.length === index - 1) {
        const values = data[index]
        values.forEach(async (value, id) => {
          if (data[0][id] === "dimensions") {
            obj = { ...obj, dimensions: JSON.parse(values[id]) };
          } else if (data[0][id] === "three_model") {
            obj = values[id] ? { ...obj, "three_model": JSON.parse(values[id]) } : { ...obj }
          } else if (data[0][id] === "image") {
            let img = JSON.parse(values[id]);
            obj = { ...obj, image: img };

          } else if (data[0][id] === "material") {
            obj = {
              ...obj,
              material: values[id] ? JSON.parse(values[id]).map((d) =>
                d?.value ? d.value : d
              ) : [],
            };
          } else if (data[0][id] === "room_type") {
            obj = {
              ...obj,
              room_type: values[id] ? JSON.parse(values[id]).map((d) =>
                d?.value ? d.value : d
              ) : [],
            };
          } else if (data[0][id] === "sales") {
            obj = { ...obj, sales: Number(values[id]) };
          }
          else if (data[0][id] === "price") {
            obj = { ...obj, price: Number(values[id]) };
          }
          else if (data[0][id] === "stock") {
            obj = { ...obj, stock: Number(values[id]) };
          } else if (data[0][id] === "tax_slab") {
            obj = { ...obj, tax_slab: Number(values[id]) || 0 };
          } else if (data[0][id] === "name") {
            obj = { ...obj, name: values[id] };
          } else {
            obj = { ...obj, [data[0][id]]: values[id] || "" };
          }
        });
        productDetail[index - 1] = {
          ...obj, '3d_models': obj.three_model, vendor_id: auth.vendor._id,
          image: obj.image,
          cost_price: inputs?.price - inputs?.price * ((auth.vendor.affiliation_rate || 5) / 100),
          pixel_price: inputs?.price - (inputs.price * ((auth.vendor.affiliation_rate || 5) / 100)) / 2,
        }
        setProductDetails([...productDetail])
      }
    }
  };

  return (
    <div>
      <Modal
        open={open?.bool === "PREVIEW"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal, "mui-modal")}
      >
        <form
          className={classes.paper1}
          onSubmit={(e) => {
            e.preventDefault();
            index !== parsedCSV.length - 1 && parsedCSV.length - 1 > index && setIndex(index + 1);
            index !== parsedCSV.length - 1
              ? handleChangeProduct() : handledata(e);
          }}
          autoComplete="on"
        >
          <div className="form-group">
            <div className={style.APHeader}>
              <h5 className="font-weight-700">Add Product</h5>
              <div
                className="d-flex justify-content-around align-items-center"
                style={{ width: "10vw" }}
              >
                <img
                  alt=''
                  src={index !== 1 ? LeftArrow : LeftArrowActive}
                  onClick={() => {
                    index > 1 && setIndex(index - 1);
                    handleChangeProduct()

                  }}
                />
                <div>
                  {index} - {parsedCSV.length - 1}
                </div>
                <img
                  alt=''
                  onClick={() => {
                    parsedCSV.length - 1 > index && setIndex(index + 1);
                    handleChangeProduct()
                  }}
                  src={
                    index === parsedCSV?.length - 1
                      ? RightArrow
                      : RightArrowActive
                  }
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {/* <p onClick={() => setOpen({...open, bool: 'DELETE'})}><a style={{ color: '#963240', textDecoration: 'underline', fontWeight: 700 }}>Delete product</a></p> */}
                <i
                  class="far fa-times-circle fa-lg ml-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen({ ...open, bool: "" })}
                ></i>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div
                  style={{
                    border: "2px solid #d9d9d9",
                    borderRadius: "0px",
                    height: "25vh",
                  }}
                >
                  {previewImages && previewImages.length === 0 ? (
                    <Dropzone
                      PreviewComponent={Preview}
                      inputContent={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "25vh",
                            flexDirection: "column",
                            fontFamily: "Open Sans",
                            color: "black",
                          }}
                        >
                          <p style={{ fontSize: "12px", display: "contents" }}>
                            <img alt='' style={{ width: "40%" }} src={UPLOAD} />
                            <u>Upload Images</u>
                          </p>
                        </div>
                      }
                      name="images"
                      // onChange={handleImage}
                      onChangeStatus={handleChangeStatus}
                      maxFiles={10}
                      multiple={true}
                      accept=".png, .jpeg, .jpg"
                      canCancel={false}
                    />
                  ) : (
                    <AliceCarousel
                      mouseTracking
                      items={ImgDataIterator}
                      responsive={responsive}
                      controlsStrategy="alternate"
                    />
                  )}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <span
                    style={{
                      fontSize: "0.8rem",
                      color: "#1B74B9",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImage([]);
                      setPreviewImages([]);
                    }}
                  >
                    <u>Remove background</u>
                  </span>
                  <span
                    style={{
                      fontSize: "0.8rem",
                      color: "#1B74B9",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setImage([]);
                      setPreviewImages([]);
                    }}
                  >
                    <u>Re-Upload</u>
                  </span>
                </div>
              </div>

              <div className="col-8 d-flex justify-content-center align-items-start flex-column ">
                <div className="d-flex flex-row">
                  <div className='col-6'>
                    <p className={style.APLabel}>Product SKU*</p>
                  </div>
                  <div className='col-6'>

                    <input
                      type="text"
                      style={{ marginLeft: 10 }}
                      value={inputs?.sku}
                      placeholder="Product ID"
                      required
                      className={style.APInput}
                      name="sku"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mt-2">
                  <div className='col-6'>
                    <p className={style.APLabel}>
                      Product Name*
                    </p>
                  </div>
                  <div className='col-6'>
                    <input
                      type="text"
                      placeholder="Product Name"
                      className={style.APInput}
                      name="name"
                      required
                      value={inputs?.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mt-2">
                  <div className='col-6'>
                    <p className={style.APLabel}>HSN Code*</p>
                  </div>
                  <div className='col-6'>
                    <input
                      type="text"
                      placeholder="ad123"
                      className={style.APInput}
                      style={{ marginLeft: 15 }}
                      name="hsn_code"
                      required
                      value={inputs?.hsn_code}
                      onChange={(e) => { setInput({ ...inputs, hsn_code: e.target.value }) }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column w-100 mt-2 ml-3">
                  <p className={style.APLabel}>Product Description*</p>
                  <textarea
                    className={style.APTextArea}
                    name="description"
                    required
                    value={inputs?.description}

                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>MRP*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="Regular price"
                      className={style.APInput}
                      required
                      value={inputs.price}
                      name="price"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Sale %*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="13%"
                      className={style.APInput}
                      name="sales"
                      required
                      value={inputs.sales}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-6">
                    <p className={style.APLabel}>Affilation %</p>
                  </div>
                  <div className="col-6">
                    <p className={style.APLabelStatic}>{vendorData?.affiliation_rate || 5}%</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-6">
                    <p className={style.APLabel}>Cost Price (Price to P&M)</p>
                  </div>
                  <div className="col-6">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ? inputs?.price - inputs?.price * ((vendorData?.affiliation_rate || 5) / 100)
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-6">
                    <p className={style.APLabel}>
                      Pixel Price(As Viewed on Website)
                    </p>
                  </div>
                  <div className="col-6">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ? inputs?.price - (inputs.price * ((vendorData?.affiliation_rate || 5) / 100)) / 2
                        : 0}
                    </p>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>GST Tax Rate*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Tax Slab"
                      className={style.APInput}
                      required
                      name="tax_slab"
                      onChange={handleChange}
                      value={inputs.tax_slab || 0}
                    >
                      <option value={""}>Select the GST Tax Rate</option>
                      {TAX_SLAB_ARRAY.map((d) => <option value={d}>{d}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Room Type*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>
                    {(
                      <ReactSelect
                        options={[
                          ...room_type.map((d) => ({
                            ...d,
                            label: d,
                            value: d,
                          })),
                        ]}
                        styles={customStyles}
                        closeMenuOnSelect={false}
                        required
                        value={inputs.room_type}
                        isMulti
                        // handleChange={}
                        // components={{ DropdownIndicator }}
                        onChange={(e) =>
                          setInput({ ...inputs, room_type: e, })
                        }
                        components={{ LoadingIndicator: null }}
                        isClearable={false}
                        hideSelectedOptions={false}
                        placeholder={
                          <p style={{ textAlign: "center", width: "100%" }}>
                            {" "}
                            Select room type{" "}
                          </p>
                        }
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                    <img
                      style={{ position: "absolute", right: 0, top: 13 }}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Material*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>
                    <ReactSelect
                      options={[
                        ...material.map((d) => ({
                          ...d,
                          label: d,
                          value: d,
                        })),
                      ]}
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      required
                      // ref={component => {
                      //   this.node = component;
                      // }}
                      isMulti
                      // handleChange={}
                      // components={{ DropdownIndicator }}
                      value={inputs.material}
                      components={{ LoadingIndicator: null }}
                      onChange={(e) => setInput({ ...inputs, material: e })}
                      isClearable={false}
                      hideSelectedOptions={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <img
                      style={{ position: "absolute", right: 0, top: 13 }}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Color*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>

                    <select
                      type="text"
                      placeholder=""
                      className={style.APSelect}
                      required
                      name="color"
                      value={inputs.color}
                      onChange={handleChange}
                    >
                      {color.map((color) => (
                        <option value={color === 'Choose color' ? "" : color}> {color} </option>
                      ))}
                    </select>

                    <img
                      style={{ position: "absolute", right: 0, top: 13 }}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Style*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      value={inputs.style}
                      name="style"
                      onChange={handleChange}
                    >
                      {styleArr.map((style) => (
                        <option value={style}> {style} </option>
                      ))}
                    </select>
                    <img
                      style={{ position: "absolute", right: 0, top: 13 }}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Pattern*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      value={inputs.pattern}
                      name="pattern"
                      onChange={handleChange}
                    >
                      {pattern.map((pattern) => (
                        <option value={pattern}> {pattern} </option>
                      ))}
                    </select>
                    <img
                      style={{ position: "absolute", right: 0, top: 13 }}
                      src={
                        "https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg"
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>
                      Stock status (no of item in stock)*
                    </p>
                  </div>
                  <div className="col-7 p-0" style={{ position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(205, 198, 195, 1)",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="13"
                        className={style.APInputWithoutBorder}
                        name="stock"
                        required
                        value={inputs.stock}
                        onChange={(e) =>
                          setInput({ ...inputs, stock: e.target.value })
                        }
                      />
                      <span style={{ fontSize: "12px", color: "#CDC6C3" }}>
                        Items
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Category*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: "center" }}
                      placeholder="Product ID"
                      className={style.APInput}
                      required
                      name="category"
                      onChange={handleChange}
                      value={inputs.category}
                    >
                      {/* <option value={"0"}>Select the category</option> */}
                      {category_data.map((d) => (
                        <option value={d.id}>{d.id}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Dimensions*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      placeholder="Product ID"
                      style={{ textAlignLast: "center" }}
                      className={style.APInput}
                      name="dimensions"
                      required
                      value={inputs.dimensions?.unit}
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            unit: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={"F&I"}>Feet & Inches</option>
                      <option value={"MM"}>Milimeters</option>
                      <option value={"M"}>Meters</option>
                    </select>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-1">
                    <p className={style.APLabel}>L*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="l"
                      required
                      value={inputs.dimensions.l}
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            l: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>B*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      value={inputs.dimensions.b}
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="b"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            b: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>H*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      value={inputs.dimensions.h}
                      name="h"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            h: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>As Viewed</p>
                  </div>
                  <div className="col-7">
                    <p className={style.APLabelStatic}>
                      {convert(parseInt(inputs?.dimensions?.l))
                        .from(
                          inputs?.dimensions?.unit === "F&I"
                            ? "in"
                            : inputs?.dimensions?.unit === "MM"
                              ? "mm"
                              : "m"
                        )
                        .to("mm")
                        .toFixed(0)}{" "}
                      x{" "}
                      {convert(parseInt(inputs?.dimensions?.b))
                        .from(
                          inputs?.dimensions?.unit === "F&I"
                            ? "in"
                            : inputs?.dimensions?.unit === "MM"
                              ? "mm"
                              : "m"
                        )
                        .to("mm")
                        .toFixed(0)}{" "}
                      x{" "}
                      {convert(parseInt(inputs?.dimensions?.h))
                        .from(
                          inputs?.dimensions?.unit === "F&I"
                            ? "in"
                            : inputs?.dimensions?.unit === "MM"
                              ? "mm"
                              : "m"
                        )
                        .to("mm")
                        .toFixed(0)}
                      &nbsp;mm
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex justify-content-between align-items-center w-80">
                <p style={{ fontSize: "12px", marginTop: 15, marginRight: 20 }}>
                  <u onClick={handlecart} style={{ cursor: "pointer" }}>
                    Preview Product card as in MX
                  </u>
                </p>
                <h5 className="mt-10">
                  {(!threeDFiles && !inputs["three_model"]) ? (
                    <Upload {...props}>
                      <a
                        style={{
                          color: "rgba(27, 116, 185, 1)",
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        <u>UPLOAD 3D</u>
                      </a>
                    </Upload>
                  ) : (
                    <div
                      className="d-flex justify-content-start align-items-center flex-row mt-4"
                      style={{ fontSize: "12px" }}
                    >
                      <p> {threeDFiles ? threeDFiles?.name : inputs['three_model'].glb || inputs['three_model'].usdz} </p>{" "}
                      &nbsp;&nbsp;&nbsp;
                      {threeDFiles && threeDFiles?.name && <p style={{ color: "#147C50" }}>Uploaded</p>}
                      &nbsp;&nbsp;&nbsp;
                      <Upload {...props}>
                        <a style={{ color: "#1B74B9" }}>
                          <u>Re-Upload</u>
                        </a>
                      </Upload>
                    </div>
                  )}
                </h5>
              </div>
              <button
                disabled={errors || loading}
                className={classNames(style.blackButton, " ")}
                style={{ opacity: errors || loading ? 0.5 : 1 }}
                type="submit"
              >
                {loading
                  ? "Loading..."
                  : index === parsedCSV.length - 1
                    ? "Submit"
                    : "Next"}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        open={start}
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        style={{ zIndex: 10000000 }}
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper3}>
          <div
            style={{ paddingTop: "30px", paddingBottom: "50px" }}
            className={style.middle}
          >
            <div className={classNames(style.APBody2, "mt-4")}>
              <div
                className={"d-flex w-100 justify-content-end align-item-center"}
              >
                <i
                  class="far fa-times-circle"
                  icon={faTimesCircle}
                  onClick={close}
                  style={{ marginTop: "-20px" }}
                ></i>
              </div>
              <div style={{ maxWidth: "250px" }}>
                <img
                  alt=''
                  style={{ maxWidth: "21vw", height: "21vh", width: "100%" }}
                  src={previewImages && previewImages.length && previewImages[0]?.previewUrl}
                />
              </div>
              <p
                style={{
                  // paddingLeft: "14px",
                  paddingTop: "30px",
                  fontWeight: 100,
                }}
              >
                {vendorData?.name}
              </p>
              <h5
                style={{
                  paddingLeft: "0px",
                  paddingTop: "5px",
                  fontFamily: "Poppins",
                }}
                className="font-weight-500"
              >
                {inputs.name}
              </h5>
              <p style={{ marginTop: "5px" }}>INR {inputs.price}</p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={open?.bool === "CONFIRM"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal1, "mui-modal")}
      >
        <form
          className={classes.paper4}
          onSubmit={() => alert("Comali")}
          autoComplete="on"
        >
          <h6>Are you sure you want to save the changes ?</h6>
          <div className="d-flex flex-row">
            <button className={classNames(style.blackYesButton)} type="submit">
              {loading ? "Loading.." : "Yes"}
            </button>
            <button
              className={classNames(style.whiteNoButton)}
              type="button"
              onClick={() => setOpen({ ...open, bool: "" })}
            >
              No
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        open={open?.bool === "DELETE"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal1, "mui-modal")}
      >
        <form
          className={classes.paper4}
          onSubmit={handleDelete}
          autoComplete="on"
        >
          <h6>Are you sure you want to delete ?</h6>
          <div className="d-flex flex-row">
            <button className={classNames(style.blackYesButton)} type="submit">
              {loading ? "Loading.." : "Yes"}
            </button>
            <button
              className={classNames(style.whiteNoButton)}
              type="button"
              onClick={() => setOpen({ ...open, bool: "" })}
            >
              No
            </button>
          </div>
        </form>
      </Modal>
      {
        successAlert && (
          <div
            style={{ position: "fixed", top: "-1.5rem", width: "90vw", right: 0 }}
          >
            <Alertpage title={"Product Updated"} />
          </div>
        )
      }
      {
        bulkSuccessAlert && (
          <div
            style={{ position: "fixed", top: "-1.5rem", width: "90vw", right: 0 }}
          >
            <Alertpage title={`Bulk Upload successful,  ${bulkImport.updatedCount ? `${bulkImport.updatedCount} products are updated,` : ''} ${bulkImport.createCount ? `${bulkImport.createCount} products are created,` : ''} ${bulkImport.failedCount ? `${bulkImport.failedCount} products failed to import` : ''}`} />
          </div>
        )
      }
    </div >
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.orders.product,
});

export default connect(mapStateToProps, { fetchProducts })(
  withRouter(EditProduct)
);

const material = [
  "Wood",
  "Silver Metal",
  "Rattan",
  "Marble",
  "Modern Wood",
  "Leather",
  "Jute",
  "Inlay",
  "Gold",
  "Glass",
  "Fabric",
  "Concrete",
  "Brass",
  "Bamboo",
  "Black Metal",
];

const color = [
  "Choose color",
  "White",
  "Grey",
  "Black",
  "Brown",
  "Beige",
  "Yellow",
  "Orange",
  "Red",
  "Pink",
  "Magenta/Burgundy",
  "Purple",
  "Blue",
  "Aqua/Teal",
  "Green",
  "Rust",
  "Multicolor",
];

const styleArr = [
  "North Indian",
  "South Indian",
  "Classic Indian",
  "Minimalistic",
  "Contemporary",
  "Mid Century modern",
  "Eclectic",
  "Pop Art",
  "Art Deco",
  "Industrial",
  "Gothic",
  "Rustic",
  "Farm House",
  "Boho",
  "Coastal",
];

const pattern = [
  "Animal Prints",
  "Indian Prints",
  "Abstract",
  "Argyle",
  "Chevron",
  "Floral",
  "Geometric",
  "Intricate",
  "Moroccon",
];

const room_type = [
  "",
  "Living Room",
  "Dining Room",
  "Bed Room",
  "Kids Room",
  "Puja Room",
  "Foyer",
  "Balcony",
  "Study Entertainment Room",
  "Others",
];

// const category_data = [
//   "Armchairs",
//   "Bed",
//   "Side Tables",
//   "Rugs"
// ];

const category_data = [
  { id: "", introline: "Choose Category" },
    { id: 'Bed', introline: 'Bed' },
    { id: 'Decor', introline: 'Decor' },
    { id: 'Dining Table', introline: 'Dining Table' },
    { id: 'Lighting', introline: 'Lighting' },
    { id: 'Mattress', introline: 'Mattress' },
    { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
    { id: 'Seating', introline: 'Seating' },
    { id: 'Sofa', introline: 'Sofa' },
    { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
    { id: 'Storage', introline: 'Storage' },
    { id: 'Tables', introline: 'Tables' },
    { id: 'Wall Coverings', introline: 'Wall Coverings' },
    { id: 'Wardrobes', introline: 'Wardrobes' }
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 0,
    textAlign: "center",
    fontSize: "12px",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    borderBottom: "1px solid rgba(205, 198, 195, 1)",
    height: "30px",
    textAlign: "center",
    overflowY: "auto",
  }),

  dropdownIndicator: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
    "& svg": { display: "none" },
  }),

  dropdownIndicators: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
  placeholder: () => ({
    fontSize: "12px",
    padding: "0px",
    textAlign: "center",
  }),

  multiValue: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 100,
    background: "#7BC0D3",
    textAlign: "center",
    color: "#4DA9FF",
    display: "flex",
    fontSize: "12px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#FFFFFF",
    textAlign: "center",
    maxWidth: "120px",
    paddingRight: "6px",
    fontSize: "10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000",
    display: "none",
    ":hover": {
      color: "white",
      fontSize: "12px",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
};
