import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/common.css";
import "../../assets/css/style.css";
import style from "./Finances.module.css";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Card,
  Container,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import CanvasJSReact from "../../assets/react-canvasjs-chart-samples/src/assets/canvasjs.react";
import { YellowInfo } from "assets/icons";
import classNames from "classnames";

function Finances() {
  const history = useHistory();
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [selected, setSelected] = React.useState("Today");
  const [isActive, setIsActive] = React.useState(false);
  const Activeoptions = ["This Month", "This Year", "Today"];

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    axisY: {
      title: "Order Price",
      includeZero: false,
      suffix: "K",
    },
    axisX: {
      title: "Time",
      prefix: "",
      interval: 2,
    },
    data: [
      {
        type: "line",
        toolTipContent: "₹ {y}",
        lineColor: '#147C50',
        markerColor: '#000',
        dataPoints: [
          { x: 1, y: 64 },
          { x: 2, y: 61 },
          { x: 3, y: 64 },
          { x: 4, y: 62 },
          { x: 5, y: 64 },
          { x: 6, y: 60 },
          { x: 7, y: 58 },
          { x: 8, y: 59 },
          { x: 9, y: 53 },
          { x: 10, y: 54 },
          { x: 11, y: 61 },
          { x: 12, y: 60 },
          { x: 13, y: 55 },
          { x: 14, y: 60 },
          { x: 15, y: 56 },
          { x: 16, y: 60 },
          { x: 17, y: 59.5 },
          { x: 18, y: 63 },
          { x: 19, y: 58 },
          { x: 20, y: 54 },
          { x: 21, y: 59 },
          { x: 22, y: 64 },
          { x: 23, y: 59 },
        ],
      },
    ],
  };
  return (
    <div>
      <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Row className="mb-3">
          <Col sm={10} className="d-flex align-items-center">
            <h2 className={classNames("font-weight-700", style.header)}>
              Finances
            </h2>
            <Alert className={style.alertContainerYellow}>
              <img alt='' className="mr-2" src={YellowInfo} /> Vendors sales increases
              by 30% , if you upload higher resolution images.
            </Alert>
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-end align-items-end mb-3"
          >
            <p className={style.gotofinances}>
              <u
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/finances");
                }}
              >
                Go to Finances
              </u>
            </p>
          </Col>
        </Row>
        <div className="row">
          <div className="col-md-8 pr-0 grid-margin stretch-card">
            <Card className="card-white" style={{ padding: "0px", height: '100%'}}>
              <Card.Body style={{ padding: '30px 30px 0px 30px' }}>
                <Row>
                  <Col sm={4}>
                    <p className={style.title}>Overall Earnings</p>
                    <h3 className={classNames(style.price, "mt-2")}>₹ 2,550</h3>
                  </Col>
                  <Col sm={4}>
                    <p className={style.title}>Today Earnings</p>
                    <h3 className={classNames(style.price, "mt-2")}>₹ 3,860</h3>
                  </Col>
                  <Col sm={4} className="text-right">
                    {/* <p>Today &nbsp; <FontAwesomeIcon icon={faChevronDown} /> </p> */}

                    <div className="dropdown">
                      <div
                        className="dropdown-btn"
                        onClick={(e) => setIsActive(!isActive)}
                      >
                        {selected} <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                      {isActive && (
                        <div
                          className="dropdown-content"
                          style={{
                            border: "1px solid grey",
                            width: "61%",
                            marginLeft: "50%",
                            borderRadius: "10px",
                            paddingTop: "5px",
                          }}
                        >
                          {Activeoptions.map((option) => (
                            <div
                              onClick={(e) => {
                                setSelected(option);
                                setIsActive(false);
                              }}
                              className="dropdown-item"
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6} className="pr-0">
                    <Card className="card-blue">
                      <Card.Body>
                        <p>Payment Paid</p>
                        {/* <p>Deposited <span className="font-weight-700">₹ 2,550</span> &nbsp; &nbsp; Not Deposited <span className="font-weight-800">₹ 3,860</span></p> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="card-pink">
                      <Card.Body>
                        <p>Payment Due</p>
                        {/* <p>Overdue <span className="font-weight-700">₹ 3,860</span> &nbsp; &nbsp; Not Yet due <span className="font-weight-800">₹ 3,860</span></p> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <CanvasJSChart
                    style={{ border: "1px solid light grey" }}
                    className="mt-2"
                    options={options}
                    /* onRef={ref => this.chart = ref} */
                  />
                </Row>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 grid-margin stretch-card">
            <Row>
              <Col>
                <Card
                  className="card-white numberCard"
                  style={{ height: "200px" }}
                >
                  <Card.Body className="p-20">
                    <h1 className={classNames("font-weight-700", style.count)}>
                      60
                    </h1>
                    <h5>Live Products</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-1 mb-2">
              <Col sm={6} className="pr-2">
                <Card className="card-white numberCard mr-0">
                  <Card.Body className="p-20">
                    <h1 className="font-weight-700">60</h1>
                    <h5>Fulfilled Orders</h5>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="pl-2">
                <Card className="card-white numberCard ml-0">
                  <Card.Body className="p-20">
                    <h1 className="font-weight-700">60</h1>
                    <h5>On-Going Orders</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row style={{ height: "240px" }}>
              <Col className="mt-2">
                <Card className="card-white numberCard">
                  <Card.Body className="p-20">
                    <h1 className="font-weight-700">60</h1>
                    <h5>Products on MX Moodboards</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Finances;
