import React, { useState } from 'react';
import '../../assets/css/style.css'
import { Col, Row } from 'react-bootstrap';
import style from './Account.module.css';
import classnames from 'classnames';
import Notification from '../../components/Notification/Notification'
import AccountInfo from '../../components/AccountInfo/AccountInfo'
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Account = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [display, setDisplay] = useState(0);
  return (<>
    <div style={{ paddingLeft: '16px', paddingRight: '24px', paddingTop: '60px' }} >
      <Row>
        <Col sm={6}>
          <h2 className="font-weight-700">Account Settings</h2>
        </Col>
      </Row>
      <br />
      <div className={style.tab_container}>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 0 ? style.active : style.notactive)} onClick={() => { setActiveTab(0); setDisplay(0) }}>
          <h6>My Profile</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 1 ? style.active : style.notactive)} onClick={() => { setActiveTab(1); setDisplay(2) }}>
          <h6>Notification Settings</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 2 ? style.active : style.notactive)} onClick={() => setActiveTab(2)}>
          <h6>System Support</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 3 ? style.active : style.notactive)} onClick={() => setActiveTab(3)}>
          <h6>About P&M</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 4 ? style.active : style.notactive)} onClick={() => setActiveTab(4)}>
          <h6>Legals</h6>
        </div>
      </div>

      {/* The Company and User Information */}
      {display === 0 && <AccountInfo display={display} setDisplay={setDisplay} />
      }
      {/* Change Password Section */}
      {display === 1 && <ChangePassword display={display} setDisplay={setDisplay} />
      }
      {/* The Notifcation Section */}
      {display === 2 && <Notification />}
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  </>
  )
}

export default Account;