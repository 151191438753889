/* eslint-disable array-callback-return */
export const importFileValidation = (data) => {
    const HEADERS = [
        "SKU", "category", "color", "description", "material", "name", "pattern", "price", "room_type", "stock", "three_model", "style", "dimensions", "sales", "image", "tax_slab", "hsn_code", "subcategory", "variant"
    ];
    let message = ""
    let success = true
    if (HEADERS.length === data[0].length) {
        HEADERS.map((header, index) => {
            if (header !== data[0][index]) {
                success = false
                message = 'File format is not applicable. Download the sample file to see the format'
            }
        });
        data.map((input) => {
            if (input.length !== HEADERS.length) {
                success = false
                message = 'File format is not applicable. Either data are missing or there are empty columns'
            }
        })
    } else {
        success = false
        message = 'File format is not applicable. Download the sample file to see the format'
    }

    return {
        success,
        message
    }
}