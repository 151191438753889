/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import axios from "axios";
import download from 'js-file-download';
import style from "./ProductCatalogue.module.css";
import { Row, Card } from "react-bootstrap";
import classNames from "classnames";
import { Modal, makeStyles } from "@material-ui/core";
import Dropzone from "react-dropzone-uploader";
import AddProductModal from "./AddProductModal";
import UnopDropdown from "unop-react-dropdown";
import { fetchProducts } from "../../store/actions/productActions";
import "./Product.css";
import { createMuiTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import UPLOAD from "../../assets/img/UPLOAD.PNG";
import Accept from "./Accept";
import ProductEdit from '../StockMaintenance/EditProduct';
import PreviewProduct from './PreviewProduct';
import classnames from "classnames";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Alertpage from "./Alertpage";
import { DownloadReport } from "components";
import { closeOutline } from "assets/icons";
import { baseUrl } from "config/config";
import { importFileValidation } from "./importFileValidation";

export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: 400,
        color: "#888888",
        marginBottom: "0px !important",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 400000,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: 388,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 2, 4),
    height: "auto",
  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "44vh",
  },
  paper3: {
    position: "absolute",
    width: 316,
    height: 520,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),

  },
  paper4: {
    position: "absolute",
    width: 400,
    zIndex: 400000,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
    height: 353,
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // paper: {
  //   position: "absolute",
  //   width: 363,
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: 10,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 2, 3),
  //   height: "auto",
  // },
  // paper1: {
  //   position: "absolute",
  //   width: 398,
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: 10,
  //   paddingLeft: '21px',
  //   paddingRight: '21px',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(4, 2, 4),
  //   height: "auto",
  // },

  // paper2: {
  //   position: "absolute",
  //   width: 280,
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: 10,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 4),
  //   margin: "10px",
  //   height: "44vh",
  // },

  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 10,
      height: 10,

      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  typo: {
    fontSize: "7px",
  },
  filterHeader: {
    fontSize: "7px",
  },
  // root: {
  //   "& .MuiFormLabel-root": {
  //     color: "red", // or black,
  //     fontSize: "12px",
  //   },
  // },
}));

const ProductCatalogue = ({ products, auth, searchString }) => {
  const [message, setMessage] = useState("")
  const [type, setType] = useState('csv')
  const [category, setCategory] = useState("All Products")
  const [activeProducts, setactiveProducts] = useState([]);
  const [modal, setModal] = useState("");
  // const [modalStyle] = useState(getModalStyle);
  const [productsFilter, setProductsFilter] = useState("");
  const [vendorData, setVendorData] = useState("");

  const classes = useStyles();
  const getVendor = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      };
      const res = await axios.get(
        `${baseUrl}/vendor/${auth.vendor._id}`,
        config
      );
      setVendorData(res.data);
    } catch { }
  };
  useEffect(() => {
    fetchProducts(auth.token);
    setactiveProducts(products);
    getVendor();

  }, [auth.token, products]);
  useEffect(() => {
    setactiveProducts(products.reverse())
  }, [products])

  //Stores the columns for the Table
  const columns = [
    { text: "Product Image", width: "15%" },
    { text: "Product ID", width: "10%" },
    { text: "Product Name", width: "10%" },
    { text: "Category", width: "10%" },
    { text: "Regular Price", width: "10%" },
    { text: "Sales Price", width: "10%" },
    { text: "Upload Status", width: "10%" },
  ];

  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={classnames(classes.icon, classes.checkedIcon)} />
        }
        onClick={(e) => setProductsFilter(props.target)}
        checked={props.value}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  const handleClose = () => {
    setModal(null);
  };

  const [report, setReport] = useState("All Products");

  const addtype = (e) => {

    if (e.target.value === "All Products") {
      products.filter((p) => p);
      setReport(e.target.value)
    }
    else {
      products.filter((p) => p.category === e.target.value);
      setReport(e.target.value)
    }
    setCategory(e.target.value)
    // setap(arr1)
  }


  const handleProductsFilterSort = () => {
    let arr
    if (productsFilter === "most-viewed") {
      arr = products;
      arr.sort((a, b) => (a.view_count < b.view_count ? 1 : -1));
      setactiveProducts(arr);
    } else if (productsFilter === "best-seller") {
      arr = products;
      arr.sort((a, b) => (a.selling_count < b.selling_count ? 1 : -1));
      setactiveProducts(products.filter((d) => d.tags.includes("BS")));
    } else if (productsFilter === "published") {
      arr = products.filter((d) => d.upload_status.toLowerCase() === 'published');
      setactiveProducts(arr);
    }
    else if (productsFilter === "declined") {
      arr = products.filter((d) => d.upload_status.toLowerCase() === 'declined');
      setactiveProducts(arr);
    } else if (productsFilter === "verifying") {
      arr = products.filter((d) => d.upload_status.toLowerCase() === 'verifying');

      setactiveProducts(arr);
    } else if (productsFilter === "desinger-pick") {
      arr = products.filter((d) => d.tags?.includes("DP", 0));
      setactiveProducts(arr);
    }
  };

  const Preview = ({ meta, files }) => {
    const { name, previewUrl } = meta;

    return (
      <span
        style={{
          alignSelf: "flex-start",
          margin: "10px 3%",
          fontFamily: "Open Sans",
        }}
      >
        <img src={previewUrl} alt={name} style={{ width: "50%" }} />
      </span>
    );
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const [successAlert, setSuccessAlert] = useState(false);
  const data1 =
    [
      { id: 'All Products', introline: 'All Products' },
      { id: 'Bed', introline: 'Bed' },
    { id: 'Decor', introline: 'Decor' },
    { id: 'Dining Table', introline: 'Dining Table' },
    { id: 'Lighting', introline: 'Lighting' },
    { id: 'Mattress', introline: 'Mattress' },
    { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
    { id: 'Seating', introline: 'Seating' },
    { id: 'Sofa', introline: 'Sofa' },
    { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
    { id: 'Storage', introline: 'Storage' },
    { id: 'Tables', introline: 'Tables' },
    { id: 'Wall Coverings', introline: 'Wall Coverings' },
    { id: 'Wardrobes', introline: 'Wardrobes' }
    ]


  const handleRemoveProducts = (value) => {
    setactiveProducts(value)
  }


  const [parsedCSV, setParsedCSV] = useState({});
  return (
    <div style={{ paddingLeft: '16px', paddingRight: '24px', paddingTop: '60px', }} >

      <div className="d-flex justify-content-between align-items-center flex-row mt-3 mb-5">
        <div style={{ width: "50%" }}>
          <h2 className="font-weight-700">Product Catalogue</h2>
        </div>
        <div
          className={
            "d-flex flex-row justify-content-end align-items-center"
          }
          style={{ width: "50%" }}
        >
          <>

            <UnopDropdown align="CENTER" closeOnClickOut={true}
              trigger={
                <button type="button"
                  className={classnames(
                    "filter-button mr-2"
                  )}
                >
                  <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Vector.png" style={{ marginTop: '7px', marginRight: '7px', width: '25px', height: '25px' }} />
                  &nbsp;
                  <span >Filters</span>
                </button>}
            >

              <div style={{ backgroundColor: 'white', borderRadius: 10, width: '200px', padding: '20px', boxShadow: '4px 8px 10px rgba(99, 94, 88, 0.2)', marginTop: '10px', marginLeft: '-0%' }}>
                <FormControl component="fieldset">

                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        fontSize: "12px",
                        opacity: 0.5,
                        marginLeft: "-1%",
                        fontWeight: 700,
                      }}
                    >
                      FILTERS
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        opacity: 0.5,
                        marginLeft: "-1%",
                        textDecoration: 'underline',
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        setactiveProducts(products);
                        setProductsFilter(null);
                      }}
                    >
                      Clear All</p>
                  </div>

                  <RadioGroup
                    defaultValue="none"
                    aria-label="gender"
                    name="customized-radios"
                    value={productsFilter}
                  // onChange={(e) => setProductsFilter(e.target.value)}
                  >
                    {" "}
                    <FormControlLabel
                      value="published"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('published')}
                      control={<StyledRadio value={productsFilter === 'published'} target="published" />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Published</p>}
                    />
                    <FormControlLabel
                      value="verifying"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('verifying')}
                      control={<StyledRadio value={productsFilter === 'verifying'} onClick={(e) => setProductsFilter('verifying')} />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Verifying</p>}
                    />
                    <FormControlLabel
                      value="declined"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('declined')}
                      control={<StyledRadio value={productsFilter === 'declined'} onClick={(e) => setProductsFilter('declined')} />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Declined</p>}
                    />
                    <div className={style.divider}></div>
                    <FormControlLabel
                      value="most-viewed"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('most-viewed')}
                      control={<StyledRadio value={productsFilter === 'most-viewed'} onClick={(e) => setProductsFilter('most-viewed')} />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Most Viewed</p>}
                    />
                    <FormControlLabel
                      value="best-seller"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('best-seller')}
                      control={<StyledRadio value={productsFilter === 'best-seller'} onClick={(e) => setProductsFilter('best-seller')} />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Best Seller</p>}
                    />
                    <FormControlLabel
                      value="desinger-pick"
                      className={style.marginBottom}
                      onClick={(e) => setProductsFilter('desinger-pick')}
                      control={<StyledRadio value={productsFilter === 'desinger-pick'} onClick={(e) => setProductsFilter('desinger-pick')} />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Designer's Pick</p>}
                    />
                  </RadioGroup>
                </FormControl>

                <div className="mt-3 mb-2"></div>
                <button
                  type="button"
                  className={classnames("btn btn-dark mr-5 ", style.filterButton1)}
                  style={{
                    width: "100%",
                    height: '35px',
                    marginLeft: '-1%'
                  }}
                  onClick={handleProductsFilterSort}
                >
                  FILTER
                </button>

              </div>

            </UnopDropdown>
          </>
          <DownloadReport
            type="button"
            className={"mr-2"}
            onClick={() => setModal("ADD")}
            title="Add Products"
          >

          </DownloadReport>
          <DownloadReport
            type="button"
            style={{}}
            onClick={() => {
              setModal("DOW");
            }}
            className={""}
          >
            Download Catalogue
          </DownloadReport>
        </div>
      </div>


      <table className={classNames("mb-2", style.table)}>
        <tr style={{ height: 40 }}>
          {columns.map((d) => (
            <td className={style.tableColumntd} style={{ padding: "10px", width: d.width, fontWeight: 800 }}>
              {" "}
              {d.text}{" "}
            </td>
          ))}
        </tr>
      </table>
      <Card className="card-white" style={{ borderRadius: "8px" }}>
        <table className={style.table_tbody}>
          {activeProducts?.filter((d) => searchString === "" || (d && d.name.toLowerCase().startsWith(searchString.toLowerCase())))?.map((f, i) => (

            <div style={{ width: '100%' }}>
              <tr >
                <td
                  className={style.tableContenttd}
                  style={{
                    textAlign: "center",
                    width: "15%",
                    top: 0,
                    padding: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "11.5vw",
                      maxHeight: '10.5vw',
                      marginLeft: '10%',
                      height: '10vw',
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      overflow: 'hidden',
                      borderRadius: "10px",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={f?.image[0]}
                      style={{
                        width: "10vw",
                        height: "auto"
                      }}
                      alt="product image"
                    />
                  </div>
                  {f.tags && f.tags.includes("BS") ? (
                    <button className={style.BSButton}>Best Seller</button>
                  ) : f.tags && f.tags.includes("DP") ? (
                    <button className={style.DPButton}>Designer's Pick</button>
                  ) : (
                    ""
                  )}
                  {/* <button className={style.BSButton}>Best Seller</button> */}

                </td>
                <td
                  className={style.tableContenttd}
                  style={{ textAlign: "center", width: "10%", padding: "10px", paddingLeft: 0 }}
                >
                  {f.SKU}
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ textAlign: "center", width: "10%", padding: "10px" }}
                >
                  {f.name}
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ textAlign: "center", width: "10%", padding: "10px" }}
                >
                  {f.category}
                </td>
                <td
                  className={style.tableContenttd}
                  style={{ textAlign: "center", width: "10%", padding: "10px" }}
                >
                  ₹ {f.price}
                </td>
                <td className={style.tableContenttd} style={{ width: "10%", justifyContent: "center" }}>
                  <p>₹ {(f.price - f.price * (f.sales / 100)).toFixed(0)}</p>
                </td>
                {/* <td style={{ textAlign: 'center', width: '10%', padding: '10px' }}><button type="button" className={f.product_status===true? style.publishButton : style.verifyingButton} >{f.product_status===true? "Published" : "Verifying"}</button></td>*/}
                <td
                  className={style.tableContenttd}
                  style={{ textAlign: "right", width: "10%", padding: "10px" }}
                >
                  <button
                    type="button"
                    className={
                      f.upload_status?.toLowerCase() === 'published' ? style.publishButton : f.upload_status?.toLowerCase() === 'declined' ? style.rejectedButton : style.verifyingButton
                    }
                  >
                    {f.upload_status?.toLowerCase() === 'published' ? "Published" : f.upload_status?.toLowerCase() === 'declined' ? "Declined" : "Verifying"}
                  </button>
                  <div className={style.editProductContainer} onClick={() => setModal({ ...modal, bool: "EDIT", product: f })}>
                    <p className={style.editProduct}>Edit Product</p>
                  </div>
                </td>
              </tr>
              <div style={{ width: '100%', zIndex: -1, }} className="d-flex justify-content-center align-items-center mt-2">
                <div style={{ width: '95%', border: '0.5px solid rgba(205, 198, 195, 0.5)' }} />
              </div>
            </div>
          ))}
        </table>

      </Card>

      <Modal
        open={modal === "ADD"}
        aria-labelledby="simple-modal-title"
        className={classnames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper4}>
          <div className={style.APHeader}>
            <h5 className="font-weight-700">Add Product</h5>
            <i class="far fa-times-circle" onClick={handleClose}></i>
            {/* <FontAwesomeIcon icon={faTimesCircle} onClick={handleClose} />  */}
          </div>
          <div className={classNames(style.APBody)} style={{ marginTop: '10vh' }}>
            <h5>Choose your Option</h5>
            <button
              className={style.APMButton}
              onClick={() => setModal("ADD_B")}
            >
              Single Upload
            </button>
            <button
              className={style.APMButton}
              onClick={() => setModal("BULK")}
            >
              Bulk Upload
            </button>
            {/* <button className={classNames(style.blackButton, "mt-4 ")}>
              Next
            </button> */}
          </div>
        </div>
      </Modal>

      <AddProductModal
        open={modal}
        setOpen={handleClose}
        setModal={setModal}
        setactiveProducts={setactiveProducts}
        setSuccessAlert={setSuccessAlert}
        vendorData={vendorData}
      />

      {parsedCSV && parsedCSV?.data &&
        <PreviewProduct
          open={modal}
          parsedData={parsedCSV && parsedCSV.data}
          setOpen={setModal}
          clearData={() => setParsedCSV(null)}
          auth={auth}
          product={modal?.product}
          vendorData={vendorData}
        />
      }


      <Accept
        open={modal}
        setOpen={setModal}
        getCSVData={(value) => {
          setParsedCSV(value);
          const res = importFileValidation(value.data)
          if (res.success) {
            setModal({ ...modal, bool: 'PREVIEW' })
          } else {
            setMessage(res.message)
            setModal('UPLOAD_FAIL');
            window.setTimeout(() => {
              setModal("");
            }, 3000);
          }
        }}
      />

      <Modal
        open={modal === "BUffLK"}
        aria-labelledby="simple-modal-title"
        className={classNames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper3}>
          <div className={style.APHeader2}>
            {/* <FontAwesomeIcon icon={faCross} onClick={handleClose} /> */}
            <i class="far fa-times-circle" onClick={handleClose}></i>
          </div>
          <div>
            <h5
              style={{ paddingLeft: "14px", paddingTop: "24px" }}
              className="font-weight-700"
            >
              Product Bulk upload
            </h5>
            <p style={{ paddingLeft: "14px", paddingBottom: "10ox" }}>
              upload .csv files product list
            </p>
            <div style={{ paddingTop: "11px" }}>
              <a
                href="#"
                style={{
                  textDecoration: "underline",
                  color: "skyblue",
                  paddingLeft: "14px",
                }}
              >
                Download p&m template.csv
              </a>
            </div>
          </div>
          <div className={style.middle}>
            <div className={classNames(style.APBody2, "mt-3")}>
              <Dropzone
                PreviewComponent={Preview}
                onSubmit={handleSubmit}
                inputContent={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      overflow: "hidden",
                      alignItems: "center",
                      fontFamily: "Open Sans",
                      color: "black",
                    }}
                  >
                    <img alt='' style={{ width: "97%" }} src={UPLOAD} />
                    <p style={{ fontSize: "12px" }}>
                      <u>Upload .csv productlist</u>
                    </p>
                  </div>
                }
                disabled={(files) =>
                  files.some((f) =>
                    [
                      "preparing",
                      "getting_upload_params",
                      "uploading",
                    ].includes(f.meta.status)
                  )
                }
              />

            </div>
          </div>
          <div className={style.next}>
            <button
              className={classNames(style.blackButton, "mt-4 ")}
              onClick={() => setModal("Accept")}
            >
              Next
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={modal === "DOW"}
        aria-labelledby="simple-modal-title"
        className={classnames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"
        style={{ padding: '20px' }}
      >
        <form className={classes.paper1}   >
          <div className={style.APHeader}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }} onClick={() => setModal(null)}>Download catalogue</h2>
            <img alt='' src={closeOutline} onClick={() => setModal(null)} />
          </div>
          <div className={classnames(style.APBody, "mt-3")} style={{ padding: '10px' }} >
            <Row className={style.ApLarge} >
              <p className="col-4 APlab " style={{ width: '17vw', marginTop: '25px' }}>Select type:</p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ width: '10%', textAlignLast: 'left', marginTop: '20px' }}
                name="status"
                id="report"
                value={report}
                onChange={addtype}
              >
                <option className="col-4" value="0">select type</option>
                {data1.map((dk, index) => {
                  return (
                    <option key={index} className="col-4" value={dk.id}>
                      {dk.id}
                    </option>)
                })}
              </select>
            </Row>
            <Row className="APLarge">
              <p className="col-4 APlab " style={{ width: '20vw', marginTop: '25px' }}>Select format:</p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ marginTop: '20px' }}
                name="file"
                id="file"
                onChange={(e) => {
                  setType(e.target.value)
                }}
              >
                <option lassName="col-4" value="csv">
                  CSV
                </option>
                <option lassName="col-4" value="pdf">
                  PDF
                </option>
              </select>
            </Row>
          </div>

          <div className={style.APDown}>
            <button
              type='button'
              className={classnames(style.APMButton1, "mt-4 ")}
              onClick={async () => {
                const res = await axios.get(`${baseUrl}/product/download`, { params: { vendorId: auth.vendor._id, category, type } }, {
                  headers: {
                    Authorization: `Bearer ${auth.token}`,
                  },
                })
                type === "csv" ? download(res.data, `product_catalogue.${type}`) : download(res, `product_catalogue.${type}`)
                setModal("")
              }}
            >Download</button>
          </div>
        </form>
      </Modal>

      {
        successAlert && (
          <div
            style={{ position: "fixed", top: "-1rem", width: "90vw", right: 0 }}
          >
            <Alertpage title={'Product Catalogue updated'} />
          </div>
        )
      }

      <ProductEdit open={modal} setOpen={setModal} auth={auth} product={modal?.product} activeProducts={activeProducts} setactiveProducts={handleRemoveProducts} vendorData={vendorData} />
      {modal === 'UPLOAD_FAIL' && < div
        style={{ position: "fixed", top: "-1.5rem", width: "90vw", right: 0 }}
      >
        <Alertpage title={message} />
      </div>}
    </div >
  );
};

const mapStateToProps = (state) => ({
  products: state.products.products,
  auth: state.auth
});

export default connect(mapStateToProps, { fetchProducts })(
  withRouter(ProductCatalogue)
);
