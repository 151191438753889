import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import './component.css'
import Button from './uiComponents/Button';
import dateformat from "dateformat";
import StatusSelectBox from './uiComponents/StatusSelectBox';

export const InStockButton = () => {
    return (
        <button className="instock-button">
            In Stock
        </button>
    )
};

export const OutOfStockButton = () => {
    return (
        <button className="outstock-button">
            Out of stock
        </button>
    )
};

export const StockingOutButton = () => {
    return (
        <button className="stockingout-button">
            Stocking out
        </button>
    )
};

export const AcknowledgedButton = () => {
    return (
        <button className="acknowledged-button">
            Acknowledged
        </button>
    )
};

export const AcceptedButton = () => {
    return (
        <button className="accepted-button">
            Accepted
        </button>
    )
};

export const ShippedButton = () => {
    return (
        <button className="shipped-button">
            Shipped
        </button>
    )
};


export const CancelledButton = () => {
    return (
        <button className="cancelled-button">
            Cancelled
        </button>
    )
};

export const ReturnedButton = () => {
    return (
        <button className="returned-button">
            Returned
        </button>
    )
};


export const DeliveredButton = () => {
    return (
        <button className="delivered-button">
            Delivered
        </button>
    )
};


export const FilterButton = ({ icon, onclick, className }) => {
    return (
        <button className={classNames("filter-button", className)} type="button" onClick={onclick}>
            {icon && <img alt='' className="filter-icon" src={icon} />}
            Filters
        </button>
    )
}

export const DownloadReport = ({ title, onClick, className }) => {
    return (
        <button className={classNames("download-report", className)} onClick={onClick} >
            {title ? title : 'Download Report'}
        </button>
    )
}


export const ProductList = ({ orderItem, columns, tabName, updateOrderItem, updateOrderItemId, shipmentDetails, poEdit, orderItemStatusObj, setOrderItemStatusObj }) => {
    const disableEdit = (orderItem.status === 'CANCELLED' || orderItem.status === 'UNACCEPTED')
    const statusColor = {
        "CANCELLED": 'warning',
        "DELIVERED": 'success',
        "RETURNED": 'danger',
        "ACCEPTED": 'warning',
        "SHIPPED": 'success',
        "UNACCEPTED": 'danger',
        "ACKNOWLEDGED": "info",
        "NEW": 'primary'
    }

    const optionClick = async (status) => {
        setOrderItemStatusObj({ ...orderItemStatusObj, [orderItem.orderItemId]: status })
        //api call to change the status of the orderItem
    }
    return (
        <>
            <Row className="product-list-row mb-4">
                <Col>
                    <p className="p-header text-left" >
                        Order Sub ID <span className="p-content text-left">{orderItem.order_sub_id}</span>
                    </p>
                </Col>

                {
                    (shipmentDetails && Object.keys(shipmentDetails).length) ? <Col >
                        <p className="p-header text-left" >
                            Delivery Date <span className="p-content text-left">{`${dateformat(shipmentDetails.delivery_date, "dd-mm-yyyy")}`}</span>
                        </p>
                    </Col> : null
                }
                <Col sm className="d-flex justify-content-end">
                    {/* <Badge color={statusColor[orderItem.status]} size='small' style={{ padding: 4, marginRight: 2 }}>{orderItem.status}</Badge>
                    {poEdit ? <span> <Edit2 size={20} onClick={() => setOpenSelectBox(true)} />
                    </span> : null} */}
                    {(tabName === 'NEW_ORDERS' || disableEdit) ?
                        <Badge color={statusColor[orderItem.status]} size='small' style={{ padding: 4, marginRight: 2 }}>{orderItem.status}</Badge> :
                        <StatusSelectBox options={[{ label: "DELIVERED", value: "DELIVERED" }, { label: "RETURNED", value: "RETURNED" }]} onClickFunc={(data) => optionClick(data)} status={orderItem.status} poEdit={poEdit} setPOEdit />
                    }
                </Col>
            </Row>
            <Row className="w-100 product-list-row mb-4">
                <Col sm={3}>
                    <div className="img-container">
                        <img src={orderItem.products?.image[0]} alt='' />
                    </div>
                </Col>
                <Col sm={9}>
                    <Row>
                        <Col sm={4}>
                            <p className="p-header text-left" >
                                {columns[0]}
                            </p>
                            <p className="p-content text-left">
                                {orderItem.products?.name || ""}
                            </p>
                            <p className="p-content text-left">
                                {orderItem.products?.SKU || ""}
                            </p>
                        </Col>
                        <Col sm={2}>
                            <p className="p-header">
                                {columns[1]}
                            </p>
                            <p className="p-content">
                                {orderItem?.quantity || ""}
                            </p>
                        </Col>
                        <Col sm={3}>
                            <p className="p-header">
                                {columns[2]}
                            </p>
                            <p className="p-content">
                                ₹ {orderItem.products?.cost_price || ""}
                            </p>
                        </Col>
                        <Col sm={3}>
                            <p className="p-header">
                                {columns[3]}
                            </p>
                            <p className="p-content">
                                ₹ {orderItem.products?.pixel_price || ""}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {(shipmentDetails && Object.keys(shipmentDetails).length) ? <Row className="w-100 product-list-row" style={{ fontSize: 14 }}>
                <Col>
                    <p className="p-header text-left" >
                        Dispatch Date <span className="p-content text-left">{`${dateformat(shipmentDetails.dispatch_date, "dd-mm-yyyy")}`}</span>
                    </p>
                </Col>
                <Col>
                    <p className="p-header text-left" >
                        Tracking ID <span className="p-content text-left">{shipmentDetails.tracking_id}</span>
                    </p>
                </Col>
                <Col>
                    <p className="p-header text-left" >
                        Tracking Link <span className="p-content text-left"><a href={shipmentDetails.tracking_link} style={{ color: '#1B74B9', textDecoration: "underline", }} target="_blank" rel="noreferrer">Link</a></span>
                    </p>
                </Col>
            </Row> : null}
            {
                (tabName === 'NEW_ORDERS' && (orderItem.status !== 'ACCEPTED' && orderItem.status !== 'UNACCEPTED')) ? <Row className="mr-3">
                    <Col md='6'>
                    </Col>
                    <Col>
                        <Row><Col>
                            <Button
                                block
                                size="sm"
                                variant="outline-primary"
                                onClick={() => { updateOrderItem(orderItem.orderItemId, { status: 'UNACCEPTED' }) }}
                            >
                                Decline
                            </Button></Col><Col>
                                <Button
                                    block
                                    size="sm"
                                    variant="primary"
                                    onClick={() => { updateOrderItem(orderItem.orderItemId, { status: 'ACCEPTED' }) }}
                                >
                                    Accept
                                </Button></Col></Row>

                    </Col>
                </Row> : null
            }
            <Row className="w-100 product-list-row mb-3">
                <Col sm={12}><div className="p-divider mt-4"></div></Col>
            </Row>
        </>
    )
}