/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import style from './Login.module.css';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login } from '../../store/actions/authActions';
import PropTypes from "prop-types";
import axios from 'axios';
import { baseUrl } from '../../config/config';
import swal from 'sweetalert';
import { EyeIcon } from 'assets/icons';
import firebase from '../../config/firebase';
import { toast, ToastContainer } from "react-toastify";

const Login = ({ history }) => {
    const [inputs, setInputs] = useState({});
    const [display, setDisplay] = useState("login");
    const [otp, setOTP] = useState('');
    const [password, setPassword] = useState({});
    const [sentTo, setSentTo] = useState("");
    const toasting = (type, text) => {
        if (type === 'error') {
            return toast.error(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        if (type === 'success') {
            toast.success(text, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }
    const handleChange = (otp) => {
        setOTP(otp)

    };


    const handleOTP = (e) => {
        let recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha', {
            size: 'invisible',
            callback: (response) => {

            },
            defaultCountry: "IN"
        });
        var number = `+91${inputs.phone_number}`;
        firebase.auth().signInWithPhoneNumber(number, recaptcha)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setDisplay('enter_otp')
            }).catch((error) => {
                alert(error);
            });

    }

    const handleOTP2 = (e) => {
        let recaptcha2 = new firebase.auth.RecaptchaVerifier('recaptcha2', {
            size: 'invisible',
            callback: (response) => {

            },
            defaultCountry: "IN"
        });
        var number = `+91${inputs.phone_number}`;
        firebase.auth().signInWithPhoneNumber(number, recaptcha2)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setDisplay('enter_otp')
            }).catch((error) => {
                alert(error);
            });

    }
    const handleVerify = async (e) => {
        if (sentTo === 'phone') {
            window.confirmationResult.confirm(otp).then(function (result) {
                setDisplay('set_new_password')
            }).catch((err) => console.log(err))
        } else {
            const res = await axios.post(`${baseUrl}/vendor/verify/otp`, { email: inputs.phone_number, otp: otp });
            if (res.data.message === 'verified') {
                setDisplay("set_new_password")
            }

        }

    }

    const verifyUser = async (e, resent) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/vendor/verify`, inputs);
            if (res?.data?.message === "otp sent") {
                if (resent === 'resent') {
                    return toasting("success", "Otp resent successfully");
                }
                setSentTo('email')
                setDisplay("enter_otp")
            } else {
                setSentTo('phone')
                handleOTP();

            }
        } catch (error) {
            swal("Error", "There is no data found in the database.");
        }
    }

    const handleSignin = async (e) => {
        e.preventDefault();

        try {
            // const token = await messaging.getToken();
            const res = await axios.post(`${baseUrl}/vendor/signin`, {
                email: inputs.email,
                password: inputs.password,
                // fcm: token
            });
            if (res.data.message === 'Success') {
                login(res.data);
            }
        }
        catch (err) {
            swal("Error", "Username and password doesn't match !");
        }
    }

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (password.new_password !== password.repeat_password) return;
        try {
            let obj = {}
            if (sentTo === 'phone') {
                obj = { phone_number: inputs.phone_number }
            } else {
                obj = { email: inputs.phone_number }
            }
            await axios.post(`${baseUrl}/vendor/forgotPassword`, {
                ...obj,
                password: password.new_password
            });
            // swal("Success", "Password updated successfully !");
            setDisplay('success');
        }
        catch (err) {
            alert('Error');
            swal("Error", "Error occured while updating, Please try again !");
        }
    }

    const [hidePassword, setHidePassword] = useState(false);

    return (
        <div className={style.container}>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container d-flex flex-column justify-content-center align-items-center height-100">
                {display === 'login' && <form onSubmit={handleSignin} className="row width-100 height-100">
                    <div className="col-7 d-flex flex-column justify-content-center align-items-start">
                        <h1 className={style.header}>Welcome to <br></br> vendor dashboard </h1>
                        <br></br>
                        <span>Powered by </span>
                        <br></br>
                        <img src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Pixel+%26+Mortar+Logo.png'} alt='' />
                    </div>
                    <div className="col-5 p-0 d-flex flex-column justify-content-center align-items-end">
                        <div className="w-100 text-center">
                            <h1 className="font-weight-700 " style={{ fontSize: '48px', width: '109%', marginLeft: "-20px" }}>Log in to your store</h1>
                        </div>
                        <div className="mb-3 mt-3 width-100">
                            <label class="form-label" style={{ marginLeft: "-4px" }}>Email ID</label>
                            <input type="email" class="form-control" required onChange={(e) => setInputs({ ...inputs, email: e.target.value })} className={classNames(style.input, 'pl-3')} />
                        </div>
                        <div className="mt-3 width-100">
                            <label class="form-label" style={{ marginLeft: "-4px" }}>Password</label>
                            <div className="password-container">
                                <input type={!hidePassword ? "password" : "text"} class="form-control" required onChange={(e) => setInputs({ ...inputs, password: e.target.value })} className={classNames(style.input, 'pl-3')} />
                                {!hidePassword ? <i class="far fa-eye-slash fa-md" onClick={() => setHidePassword(true)}></i> : <img alt='' onClick={() => setHidePassword(false)} src={EyeIcon} />}
                            </div>
                        </div>
                        <a className="mt-2" onClick={() => setDisplay('forgot_password')} style={{ textDecoration: 'underline', color: 'black', cursor: "pointer" }}>Forgot Password?</a>
                        <button className={style.button} type="submit" >Sign In</button>

                    </div>
                </form>}

                {display === 'password' &&
                    <div className="d-flex flex-column justify-content-center align-items-center height-100">
                        <h1 className={style.header}>Set your password!</h1>
                        <div className={style.passswordFields}>
                            <div className="mb-3 mt-3 d-flex flex-column">
                                <label class="form-label font-weight-500">Enter you new password</label>
                                <input type="password" class="form-control " className={style.input} />
                            </div>
                            <div className="mt-3n d-flex flex-column">
                                <label class="form-label font-weight-500">Password</label>
                                <input type="password" class="form-control" className={style.input} />
                            </div>
                        </div>
                        <button className={style.button1}>Set a new password</button>
                        <button className={style.button2}>Skip for now</button>
                    </div>}

                {display === 'forgot_password' &&
                    <form onSubmit={(e) => verifyUser(e)} className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100%', }}>
                        <h1 className={style.header}>Forgot Password ?</h1>
                        <div className={style.passswordFields}>
                            <div className="mb-3 mt-3 d-flex flex-column">
                                <label class="form-label font-weight-700">Enter registered Email/mobile number</label>
                                <input type="text" class="form-control" onChange={(e) => setInputs({ ...inputs, phone_number: e.target.value })} className={style.input} />
                                <div id="recaptcha">

                                </div>
                                <br></br>
                                <span>We will send a verification code to your email/mobile number.</span>
                            </div>
                        </div>
                        <button type="submit" className={style.button1}>Send OTP</button>
                        <button type="button" onClick={() => setDisplay('login')} className={style.button2}>Go back</button>
                    </form>}

                {display === 'enter_otp' &&
                    <div className="d-flex flex-column justify-content-center align-items-center height-100">
                        <h1 className={style.header}>Enter you OTP</h1>
                        <br></br>
                        <p className="textAlign-left width-100 font-weight-700">Verification code send to <br></br>{sentTo === 'phone' ? `+91 - ${inputs.phone_number}` : inputs.phone_number} </p>
                        <OtpInput
                            numInputs={6}
                            inputStyle={{
                                width: 50,
                                height: 50,
                                margin: 10,
                                borderRadius: 7

                            }}
                            value={otp}
                            onChange={handleChange}
                        />
                        <div id="recaptcha2" ></div>
                        <p className="textAlign-right width-100 green font-weight-700" style={{ cursor: 'pointer' }} onClick={(e) => {
                            if (sentTo === 'phone') {
                                handleOTP2()
                            } else { verifyUser(e, "resent") }
                        }}
                        >Resend OTP</p>
                        <button onClick={() => {
                            if (otp.length === 6) {
                                handleVerify()
                            }
                        }
                        } className={style.button1}>Verify</button>
                        <button className={style.button2} onClick={() => setDisplay("forgot_password")}>Go back</button>
                    </div>}

                {display === 'set_new_password' &&
                    <form onSubmit={handleForgotPassword} className="d-flex flex-column justify-content-center align-items-center height-100">
                        <h1 className={style.header}>Set a new password</h1>
                        <div className={classNames(style.passswordFields, 'width-100')}>
                            <div className="mb-3 mt-3 d-flex flex-column w-100">
                                <label class="form-label">Enter your new password</label>
                                <input type="password" onChange={(e) => setPassword({ ...password, new_password: e.target.value })} class="form-control" className={style.input} />
                            </div>
                            <div className="mt-3n d-flex flex-column">
                                <label class="form-label">Confirm password</label>
                                <input type="password" onChange={(e) => setPassword({ ...password, repeat_password: e.target.value })} class="form-control" className={style.input} />
                            </div>
                        </div>
                        <button type="submit" className={style.button1}>Set new password</button>
                    </form>}

                {display === 'success' &&
                    <div className="d-flex flex-column justify-content-center align-items-center height-100 width-30">
                        <img src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+62.svg'} alt='' />
                        <br></br>
                        <h4 className="font-weight-800 textAlign-center width-100">Your password has been successfully updated.</h4>
                        <button className={style.button1} onClick={() => setDisplay('login')}>Login</button>
                    </div>}
            </div>
        </div>
    )
}

Login.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Login));