import { store } from '../store';
import axios from 'axios';
import { baseUrl } from '../../config/config';

export const fetch_orders = async (token)  => {
    try{
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const res2 = await axios.get(`${baseUrl}/vendor/order/neworders`, config);
        store.dispatch({
            type: 'POST_ORDERS',
            newOrders: res2.data,
        });
        // console.log("PRODUCTS--->", res1.data);
        const res3 = await axios.get(`${baseUrl}/vendor/order/openorders`, config);
        // console.log("PRODUCTS--->", res1.data);
        store.dispatch({
            type: 'POST_ORDERS',
            openOrders: res3.data,
        });
        const res4 = await axios.get(`${baseUrl}/vendor/order/pastorders`, config);
        store.dispatch({
            type: 'POST_ORDERS',
            pastOrders: res4.data,
        });
        const res = await axios.get(`${baseUrl}/vendor/order/list`, config);
        store.dispatch({
            type: 'POST_ORDERS',
            orders: res.data,
        });
        const res1 = await axios.get(`${baseUrl}/vendor/product/list`, config);
        store.dispatch({
            type: 'POST_ORDERS',
            products: res1.data,
        });
        const res5 = await axios.get(`${baseUrl}/user/list`, config);
        store.dispatch({
            type: 'POST_ORDERS',
            users: res5.data
        });
      
    }
    catch (error) {

    }
    // store.dispatch({ type: 'LOGIN' });
}

export const filterneworders = (data) => {

    store.dispatch({
        type: 'FILTER_ORDERS',
        new_orders: data
    })
}



export const filteropenorders = (data) => {

    store.dispatch({
        type: 'FILTER_OPEN',
        open_orders: data
    })
}
export const filterpastorders = (data) => {

    store.dispatch({
        type: 'FILTER_PAST',
        past_orders: data
    })
}