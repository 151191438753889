/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import style from '../Drawers/Drawer.module.css';
import { Card } from 'react-bootstrap';
import dateformat from "dateformat";
import { sortDown } from 'assets/icons';
import { sortUp } from 'assets/icons';
import Ellipsis from 'components/uiComponents/Ellipsis';
import { Badge } from 'reactstrap';
const OpenOrders = ({
  currentOrder,
  OOrders,
  token,
  users,
  products,
  Customer,
  setToggleDrawer,
  setCurrentOrder,
  toggleDrawer,
  searchString,
  getCustomerName,
  currentProducts,
  ODrawer
}) => {

  const columns1 = [
    { text: "S.No", width: "5%" },
    { text: "Orders ID", width: "10%" },
    { text: "Customer Name", width: "10%" },
    { text: "Products", width: "10%" },
    { text: "Delivery Region", width: "10%" },
    { text: "No. of Products", width: "10%" },
    { text: "Order Value", width: "10%" },
    { text: "Status", width: "10%" },
  ];

  useEffect(() => {
    setOrders(OOrders);
  }, [OOrders])

  const statusColor = {
    "CANCELLED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
    "DELIVERED": { color: 'success', padding: 4 },
    "RETURNED": { color: 'danger', padding: 4 },
    "ACCEPTED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
    "SHIPPED": { color: 'success', padding: 4 },
    "UNACCEPTED": { color: 'danger', padding: 4 },
    "ACKNOWLEDGED": { color: "info", padding: 2 },
    "NEW": { color: 'primary', padding: 2 }
  }
  const [selectedOrderItemIds, setSelectedOrderItemIds] = useState([])

  const [orders, setOrders] = useState(OOrders);
  const [sortState, setSortState] = useState({
    product: false,
  });

  const handleSort = (name, isDesc) => {
    if (isDesc && name === "product") {
      setSortState({ ...sortState, product: false });
      let arr = OOrders.sort((a, b) =>
        a.totalQuantity < b.totalQuantity ? 1 : -1
      );
      setOrders(arr);
    }
    if (!isDesc && name === "product") {
      setSortState({ ...sortState, product: true });
      let arr = OOrders.sort((a, b) =>
        a.totalQuantity > b.totalQuantity ? 1 : -1
      );
      setOrders(arr);
    }
    if (isDesc && name === "order") {
      setSortState({ ...sortState, order: false });
      let arr = OOrders.sort((a, b) =>
        a.order.createdAt < b.order.createdAt ? 1 : -1
      );
      setOrders(arr);
    }
    if (!isDesc && name === "order") {
      setSortState({ ...sortState, order: true });
      let arr = OOrders.sort((a, b) =>
        a.order.createdAt > b.order.createdAt ? 1 : -1
      );
      setOrders(arr);
    }
  };

  return (
    <>
      <table className="mb-2 w-100">
        <tr
          style={{
            textAlign: "center",
            borderRadius: "8px",
            backgroundColor: "#CDC6C3",
          }}
        >
          {columns1.map((d) => (
            <td style={{ padding: "10px", width: d.width, fontWeight: 700 }}>
              {" "}
              {d.text}{" "}
              {d.text === "Num. of Products" &&
                (sortState.product ? (
                  <img
                    alt=''
                    src={sortDown}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("product", true)}
                  />
                ) : (
                  <img
                    alt=''
                    src={sortUp}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("product", false)}
                  />
                ))}

              {d.text === "Order Date" &&
                (sortState.order ? (
                  <img
                    alt=''
                    src={sortDown}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("order", true)}
                  />
                ) : (
                  <img
                    alt=''
                    src={sortUp}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("order", false)}
                  />
                ))}
            </td>
          ))}
        </tr>
      </table>
      {orders?.length === 0 ? (
        <Card className="card-white d-flex align-items-center justify-content-center" style={{ borderRadius: "8px", height: '40vh' }}>
          <div className={style.noorder} style={{ textAlign: "center" }}>
            <h2 className="no-orders">No Open Orders</h2>
          </div>
        </Card>
      ) : (
        <Card className="card-white" style={{ borderRadius: "8px", marginTop: "16px", marginBottom: '46px', paddingBottom: '1%' }}>
          <table className={style.table} style={{ width: "100%" }}>
            {orders?.filter((d) => searchString === "" || (d && d.order.order_id.toLowerCase().startsWith(searchString.toLowerCase())))?.map((orderData, i) => (
              <div style={{ width: '100%', }}>
                <tr
                  style={{
                    textAlign: "center",
                    verticalAlign: "initial",
                    width: '100%',
                    display: 'inline-table',
                    // height: 100,
                    marginTop: 0,
                    fontWeight: 100,
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      width: "5%",
                      padding: "10px",
                      position: "relative",

                    }}
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",

                    }}
                  >
                    {orderData.order.order_id}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",

                    }}
                  >
                    {orderData.customer.name}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",

                    }}
                  >
                    {orderData.orderItem.map(orderItem => {
                      return <p>{Ellipsis(orderItem.products?.name || "", 16)} {orderItem.quantity > 1 && `X ${orderItem.quantity}`}</p>
                    })}
                    {
                      orderData.orderShipment && orderData?.shipments.map(shipment => {
                        return shipment.orderItems.map(orderItem => {
                          return <p>{Ellipsis(orderItem.products?.name || "", 16)} {orderItem.quantity > 1 && `X ${orderItem.quantity}`}</p>
                        })
                      })
                    }
                    {orderData.cancelledOrderItems?.length ? orderData.cancelledOrderItems.map(orderItem => {
                      return <p>{Ellipsis(orderItem.products?.name || "", 16)} {orderItem.quantity > 1 && `X ${orderItem.quantity}`}</p>
                    }) : null}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    {`${orderData.address?.address?.city || "-"}, ${orderData.address?.address?.state || "-"}`}

                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    {orderData.totalQuantity}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",
                    }}
                  >
                    {orderData.totalOrderValue}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10%",

                    }}
                  >
                    {orderData.orderItem.map(orderItem => {
                      return <div><Badge color={statusColor[orderItem.status].color} style={{ padding: statusColor[orderItem.status].padding, marginLeft: statusColor[orderItem.status].marginLeft, cursor: 'pointer' }}>{orderItem.status}</Badge></div>
                    })}
                    {
                      orderData.orderShipment && orderData?.shipments.map(shipment => {
                        return shipment.orderItems.map(orderItem => {
                          return <div><Badge color={statusColor[orderItem.status].color} style={{ padding: statusColor[orderItem.status].padding, marginLeft: statusColor[orderItem.status].marginLeft, cursor: 'pointer' }}>{orderItem.status}</Badge></div>
                        })
                      })
                    }
                    {orderData.cancelledOrderItems?.length ? orderData.cancelledOrderItems.map(orderItem => {
                      return <div><Badge color={statusColor[orderItem.status].color} style={{ padding: statusColor[orderItem.status].padding, marginLeft: statusColor[orderItem.status].marginLeft, cursor: 'pointer' }}>{orderItem.status}</Badge></div>
                    }) : null}
                  </td>

                </tr>
                <tr
                  style={{
                    textAlign: "center",
                    verticalAlign: "initial",
                    width: '100%',
                    display: 'inline-table',
                    marginTop: 0,
                    marginBottom: 10,
                    fontWeight: 100,
                  }}>
                  <td style={{ width: "5%", }}></td>
                  <td style={{ width: "10%", }}></td>

                  <td style={{ width: "10%", }}></td>
                  <td style={{ width: "10%", }}></td>
                  <td style={{ width: "20%", }}>
                    <p style={{ fontSize: 12, marginTop: '10%' }}>Order Date: <span style={{ fontWeight: 'bold' }}>{dateformat(orderData.order.createdAt, "mmm dd yyyy")}</span></p>
                  </td>
                  <td style={{ width: "10%", }}>
                    <a className={style.linktext} style={{ textDecoration: 'underline' }} onClick={() => {
                      setCurrentOrder(orderData)
                      setToggleDrawer(true)
                    }}>View details</a>
                  </td>
                </tr>
              </div>
            ))}
          </table>
        </Card>
      )}
      <ODrawer
        f={currentOrder}
        users={users}
        Customer={Customer}
        products={products}
        setToggleDrawer={setToggleDrawer}
        setCurrentOrder={setCurrentOrder}
        toggleDrawer={toggleDrawer}
        currentOrder={currentOrder}
        currentProducts={currentProducts}
        token={token}
        orders={orders}
        setOrders={setOrders}
        selectedOrderItemIds={selectedOrderItemIds}
        setSelectedOrderItemIds={setSelectedOrderItemIds}
      />
    </>
  );
};

export default OpenOrders;

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener('resize', updateScreenSize);
//   }, []);

//   useEffect(() => {
//   }, [screenSize]);

//   return screenSize;
// }