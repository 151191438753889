import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyBd_L5CmNf4vuYY1ViZhgNEkxxsoLDJfJU",
    authDomain: "pm-customer.firebaseapp.com",
    projectId: "pm-customer",
    storageBucket: "pm-customer.appspot.com",
    messagingSenderId: "927714709375",
    appId: "1:927714709375:web:abe0d597a99fc6c23dd44a",
    measurementId: "G-XGX9GTXL1C"
}
firebase.initializeApp(config);
export default firebase