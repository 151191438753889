const initialState = {
  orders: [],
  products: [],
  openOrders: [],
  users: [],
  newOrders: [],
  pastOrders: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, ...data } = action;
  switch (type) {
    case "POST_ORDERS":
      return {
        ...state,
        ...data
      };

    case "FILTER_ORDERS":
      return {
        ...state,
        newOrders: action.new_orders
      }
    case "FILTER_OPEN":
      return {
        ...state,
        openOrders: action.open_orders
      }
    case "FILTER_PAST":
      return {
        ...state,
        openOrders: action.past_orders
      }
    case "REMOVE_ORDERS":
      state = initialState
      break;
    default:
      return state;
  }
}
