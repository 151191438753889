import React, { useState } from "react";
import style from "./Accept.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from "classnames";
import { Modal, makeStyles } from "@material-ui/core";
import tick from "../../assets/img/tick.PNG";
import Dropzone from "react-dropzone-uploader";
import UPLOAD from "../../assets/img/UPLOAD.PNG";
import CSVReader from 'react-csv-reader';
import ProductSampleCSV from '../../assets/product_bulk_upload_sample.csv';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10000
  },
  paper: {
    position: "relative",
    width: 364,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: '1%',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 3),
    height: 520,
    paddingLeft: '2rem',
    paddingRight: '1%',
    paddingBottom: '4%',
  },
  paper1: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "auto",

  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "44vh",
  },
}));

const Accept = ({ open, setOpen, auth, getCSVData }) => {
  const classes = useStyles();

  const [status,] = useState(false);
  const [csvData, setcsvData] = useState({
    data: [],
    fileName: ''
  });



  const Preview = ({ meta }) => {
    const { name } = meta;
    return (
      <div
        style={{
          width: '92%',
          height: '250px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          border: "1px solid #CDC6C3",
          fontFamily: "Open Sans",
        }}
      >
        {status ? <img alt='' style={{ width: "60%" }} src={tick} /> : <p>Uploading</p>}

        <p style={{ fontWeight: 700, textAlign: 'center' }}>{name}</p>

      </div>
    );
  };

  const handleSubmit = async (e) => {
    try {
      getCSVData(csvData);
    }
    catch (error) {
      alert('Error Occured. Please try again later.')
    }
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      // setCsv(file);
    } else if (status === "aborted") {
      // setCsv(null);
    }
  };


  return (
    <Modal
      open={open === 'BULK'}
      aria-labelledby="simple-modal-title"
      className={classNames(classes.modal, 'mui-modal')}
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className={classNames(style.APHeader, "mr-0")}>
          <i class="far fa-times-circle fa-lg" onClick={() => setOpen(null)} ></i>
          {/* <FontAwesomeIcon icon={faTimesCircle} onClick={() => setOpen(null)} /> */}
        </div>
        <div className="mb-3">
          <h5
            style={{ paddingLeft: "0px", paddingTop: "24px", fontSize: '24px' }}
            className="font-weight-700"
          >
            Product Bulk upload
          </h5>
          <p style={{ paddingLeft: "0px", fontSize: '14px' }}>Upload .csv files products list</p>
          <a
            href={ProductSampleCSV}
            download
            target="_blank" rel="noreferrer"
          >
            <p className="mt-1" style={{ paddingLeft: "0px", fontSize: '14px', color: '#1B74B9' }}>
              <u>Download P&amp;M Template.csv</u>
            </p>
          </a>
        </div>
        <Dropzone
          PreviewComponent={Preview}
          style={{ margin: '20px', width: '100%', height: '400px' }}
          inputContent={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #CDC6C3",
                overflow: "hidden",
                height: '250px',
                alignItems: "center",
                fontFamily: "Open Sans",
                color: "black",
              }}
            >
              {!csvData?.fileName?.name ? <>
                <img style={{ width: "50%" }} src={UPLOAD} alt='' />
                <p style={{ color: 'rgb(27, 116, 185)', fontSize: '14px' }}>
                  <u>Upload product list .csv</u>
                </p>
              </> : <>
                <p>CSV Loaded</p>
                <p style={{ fontWeight: 700, textAlign: 'center' }}>{csvData?.fileName?.name}</p>
              </>}
            </div>
          }
          // eslint-disable-next-line react/jsx-no-duplicate-props
          disabled={(files) =>
            files.some((f) =>
              ["preparing", "getting_upload_params", "uploading"].includes(
                f.meta.status
              )
            )
          }
          accept=".csv"
          maxFiles={1}
          multiple={false}
          onChangeStatus={handleChangeStatus}
        />
        <div style={{
          position: 'absolute',
          top: '30%',
          width: '92%',
          height: '250px',
        }}>
          <CSVReader
            label={
              null
            }
            onFileLoaded={(data, fileInfo) => {
              setcsvData({ ...csvData, data: data, fileName: fileInfo });
            }}
          />
        </div>
        <div className={style.next}>
          <button disabled={!csvData?.fileName?.name} style={{ opacity: csvData?.fileName?.name ? 1 : 0.5 }} onClick={(e) => csvData?.fileName?.name ? handleSubmit(e) : setOpen(null)} className={classNames(style.blackButton, "mt-4 ")}>
            {status ? 'Close' : 'Next'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(
  withRouter(Accept)
);
