import React, { useEffect, useState } from 'react';
import '../../assets/css/style.css'
import { Card, Col, Row, Alert } from 'react-bootstrap';
import style from './OrderStatus.module.css';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import axios from 'axios';
import dateformat from 'dateformat'
import { baseUrl } from 'config/config';
import { useHistory } from 'react-router-dom';
import ShippingInfo from '../../assets/img/shippingInfo.svg'
import classNames from 'classnames';
import { AcknowledgedButton } from 'components';
import { AcceptedButton, ShippedButton, DeliveredButton } from 'components';

const OrderStatus = ({ orders }) => {

  const [activeTab, setActiveTab] = useState("NEW");
  const history = useHistory()

  const columns = [
    { text: 'S.No', width: '5%' },
    { text: 'Order ID', width: '10%' },
    { text: 'Customer', width: '10%' },
    { text: 'Num. of Products', width: '10%' },
    { text: 'Order Date', width: '10%' },
    { text: 'Order Status', width: '10%' },
    { text: 'Tracking ID', width: '10%' }
  ]


  const [users, setUsers] = useState([]);


  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseUrl}/user/list`);
      setUsers(res.data);
    })()
  }, [orders])



  return (
    <div>
      <Row>
        <Col sm={9} className="d-flex justify-content-start align-items-end">
          <h2 className={classNames("font-weight-700", style.header)}>Order Status</h2>
          {/* </Col>
            <Col sm={6} className="d-flex justify-content-start align-items-end"> */}
          {activeTab === "NEW" && <Alert className={style.alertContainerRed}>
            <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53+(2).svg" style={{ paddingRight: "10px" }} />
            Customers love your product, Keep Going!
          </Alert>}
          {activeTab === "ACCEPTED" && <Alert className={style.alertContainerYellow}>
            <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53.svg" style={{ paddingRight: "10px" }} />
            Thanks for accepting customer are waiting for you orders!
          </Alert>}
          {activeTab === "SHIPPED" && <Alert className={style.alertContainerPink}>
            <img alt='' src={ShippingInfo} style={{ paddingRight: "10px" }} />
            On the way to the Destination, Customers awaiting you Orders!
          </Alert>}
          {activeTab === "DELIVERED" && <Alert className={style.alertContainerGreen}>
            <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53+(1).svg" style={{ paddingRight: "10px" }} />
            Yes , We Did It Again
          </Alert>}

        </Col>
        <Col sm={3}  >
          <p><u style={{ cursor: 'pointer', float: "right", marginTop: "21px" }} onClick={() => history.push("/orders")}>Go to orders</u></p>
        </Col>
      </Row>
      <br />
      <div className={style.tab_container}>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === "NEW" ? style.AcknowledgedActive : style.Acknowledged)} onClick={() => setActiveTab("NEW")}>
          <h6>Acknowledged Orders ({orders.filter((d) => d.status === "NEW")?.length})</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === "ACCEPTED" ? style.AcceptedActive : style.Accepted)} onClick={() => setActiveTab("ACCEPTED")}>
          <h6>Accepted orders ({orders.filter((d) => d.status === "ACCEPTED")?.length})</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === "SHIPPED" ? style.ShippedActive : style.Shipped)} onClick={() => setActiveTab("SHIPPED")}>
          <h6>Shipped orders ({orders.filter((d) => d.status === "SHIPPED")?.length})</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === "DELIVERED" ? style.DeliveredActive : style.Delivered)} onClick={() => setActiveTab("DELIVERED")}>
          <h6>Delivered orders ({orders.filter((d) => d.status === "DELIVERED")?.length})</h6>
        </div>
      </div>
      <table className="w-100 mb-3" >
        <tr style={{ textAlign: 'center', borderRadius: '8px', backgroundColor: '#CDC6C3', height: 40 }}>
          {columns.map((d) => <td className={style.columntd} style={{ padding: '10px', width: d.width, fontWeight: 800 }} > {d.text} </td>)}
        </tr>
      </table>
      <Card className="card-white" style={{ borderRadius: '8px' }} >
        <table className={style.contenttable} style={{ width: '100%' }}>
          {orders.filter((d) => d.status === activeTab).map((f, i) => <div style={{ width: '100%' }}>
            <tr style={{ textAlign: 'center', height: 'auto', width: '100%', verticalAlign: 'initial', display: 'inline-table' }}>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '5%', padding: '10px', paddingBottom: '23px', paddingTop: '35px' }}>{i + 1}</td>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '11%', padding: '10px' }}>{f.order_id}</td>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>{users.find((find) => find._id === f.user_id)?.name}</td>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>{f.product.length}</td>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>{dateformat(f.createdAt, 'mmm d, yyyy')}</td>
              <td className={style.contenttabletd} style={{ textAlign: 'center', width: '10%', padding: '0px 0px 0px 22px' }}>

                {/* {f.status === 'SHIPPED' ?
                        <button className={style.shippedButton}>Shipped</button>
                        : f.status === 'DELIVERED'
                        ? <button className={style.deliveredButton}>Delivered</button>
                        : f.status === 'RETURNED'
                        ? <button className={style.returnedButton}>Returned</button>
                        : f.status === 'CANCELLED'
                        ? <button className={style.cancelledButton}>Cancelled</button>
                        : 
                        <button 
                          type="button" 
                          className={style.acceptedButton}
                          >
                          Accepted
                        </button>} */}
                <div className="d-flex justify-content-center align-items-center">
                  {f.status === 'NEW'
                    ? <AcknowledgedButton />
                    : f.status === 'ACCEPTED'
                      ? <AcceptedButton />
                      : f.status === 'SHIPPED'
                        ? <ShippedButton />
                        : <DeliveredButton />
                  }
                </div>
              </td>
              <td style={{ textAlign: 'center', width: '10%', padding: '10px' }}>{f.tracking_id ? f.tracking_id : 'NA'}</td>
            </tr>
            <div style={{ width: '100%', zIndex: -1, height: '0px', marginTop: '10px' }} className="d-flex justify-content-center align-items-center">
              <div style={{ width: '93%', border: '0.5px solid #CDC6C3', height: '0px' }} />
            </div>
          </div>
          )}

        </table>
        <table>
          <tr className="w-100 d-flex" >

            <td className="w-100 d-flex " >
              <div className="w-100 d-flex justify-content-center align-items-center" style={{ textDecoration: 'underline', cursor: 'pointer', height: '80px', fontWeight: '400' }}>

                {activeTab === "NEW" ?
                  <p onClick={() => history.push({ pathname: `/orders`, state: { activeTab } })}>+ View  All Acknowledged Orders</p>
                  : activeTab === "ACCEPTED"
                    ? <p onClick={() => history.push({
                      pathname: `/orders`,
                      state: { activeTab }
                    })}>+ View  All Accepted Orders</p>
                    : activeTab === "SHIPPED"
                      ? <p onClick={() => history.push({ pathname: `/orders`, state: { activeTab } })}>+ View  All Shipped Orders</p>
                      :
                      <p onClick={() => history.push({ pathname: `/orders`, state: { activeTab } })}>+ View  All Delivered Orders</p>
                }

              </div>
            </td>
          </tr>
        </table>
      </Card>
    </div>
  )
}


const mapsStateToProps = state => ({
  orders: state.orders.orders,
  products: state.orders.products,
  token: state.auth.token
})

export default connect(mapsStateToProps)(withRouter(OrderStatus));