import React, { useState } from 'react';
import '../../assets/css/style.css'
import { Card, Col, Row, Alert } from 'react-bootstrap';
import style from './StockStatus.module.css';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from 'classnames';
import { OutOfStockButton } from 'components';
import { StockingOutButton } from 'components';
import { InStockButton } from 'components';

const StockStatus = ({ products }) => {

  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory()
  const columns = [
    { text: 'S.No', width: '5%' },
    { text: 'Product ID', width: '10%' },
    { text: 'Product Name', width: '10%' },
    { text: 'Category', width: '10%' },
    { text: 'Cost Price', width: '10%' },
    { text: 'Pixel Price', width: '10%' },
    { text: 'Qty', width: '10%' },
    { text: <p style={{ transform: 'translateX(-12%)' }}>Stock Status</p>, width: '10%' }
  ]


  return (
    <div>
      <Row className="d-flex flex-row justify-content-center align-items-center">
        <Col sm={9} className="d-flex justify-content-left align-items-end">
          <h2 className={classNames("font-weight-700", style.header)}>Stock Status</h2>
          {activeTab === 0 && <Alert className={style.alertContainerRed}>
            <img alt='' className="mr-2" src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53.png'} /> Attention Required : Wohooo ! You are Sold Out, Please Refill !
          </Alert>}
          {activeTab === 1 && <Alert className={style.alertContainerYellow}>
            <img alt='' className="mr-2" src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53.svg'} /> Attention Required : Your Best Seller are stocking out
          </Alert>}
          {activeTab === 2 && <Alert className={style.alertContainerGreen}>
            <img alt='' src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Group+53+(1).svg'} className="mr-2" /> Yes, We are on track with these Products
          </Alert>}
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <p className={style.gotostock}><u style={{ cursor: 'pointer' }} onClick={() => history.push("/stocks")}>Go to Stock Maintenance</u></p>
        </Col>


      </Row>
      <br />
      <div className={style.tab_container}>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 0 ? style.OutofStockActive : style.OutofStock)} onClick={() => setActiveTab(0)}>
          <h6>Out of Stocks ({products.length && products.filter((d) => parseInt(d.stock) <= 0)?.length})</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 1 ? style.StockingOutActive : style.StockingOut)} onClick={() => setActiveTab(1)}>
          <h6>Stocking Out ({products.length && products.filter((d) => parseInt(d.stock) < 15)?.length})</h6>
        </div>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 2 ? style.InStockActive : style.InStock)} onClick={() => setActiveTab(2)}>
          <h6>In Stock ({products.length && products.filter((d) => parseInt(d.stock) > 15)?.length})</h6>
        </div>
      </div>
      <table className="w-100 mb-3" >
        <tr style={{ textAlign: 'center', borderRadius: '8px', backgroundColor: '#CDC6C3', fontWeight: 800 }}>
          {columns.map((d) => <td className={style.columntd} style={{ padding: '10px', width: d.width, fontWeight: 800 }} > {d.text} </td>)}
        </tr>
      </table>
      {activeTab === 0 && <Card className="card-white" style={{ borderRadius: '8px' }} >
        <table style={{ width: '100%' }}>
          {products.length && products.filter((d) => parseInt(d.stock) <= 0).filter((f, index) => index <= 3).map((f, i) => <div style={{ width: '100%' }}>
            <tr style={{ textAlign: 'center', height: 100, width: '100%', display: 'inline-table' }}>
              <td className={style.contenttd} style={{ width: '5%' }}>{i + 1}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.SKU}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.name}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.category}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.price}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{(f.price - f.price * (f.sales / 100)).toFixed(0)}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.stock}</td>
              <td className={style.contenttd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>
                <OutOfStockButton />
              </td>
            </tr>
            <div style={{ width: '100%', zIndex: -1, }} className="d-flex justify-content-center align-items-center">
              <div style={{ width: '93%', marginRight: '20px', border: '0.2px solid rgba(0,0,0, 0.2)' }} />
            </div>
          </div>
          )}
        </table>
        <table>
          <tr className="w-100 d-flex" style={{ height: '80px' }} >

            <td className="w-100 d-flex " >
              <div onClick={() => history.push({ pathname: '/stocks', state: { activeTab } })} className="w-100 d-flex justify-content-center align-items-center" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '400' }}>
                + View All Out of Stock Products
              </div>
            </td>
          </tr>
        </table>
      </Card>
      }
      {activeTab === 1 && <Card className="card-white" style={{ borderRadius: '8px' }} >
        <table style={{ width: '100%', }}>
          {products.filter((d) => (parseInt(d.stock) < 15) && (parseInt(d.stock) > 0)).filter((f, index) => index <= 3).map((f, i) => <div style={{ width: '100%' }}>
            <tr style={{ textAlign: 'center', height: 100, display: 'inline-table', width: '100%' }}>
              <td className={style.contenttd} style={{ width: '5%' }}>{i + 1}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.SKU}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.name}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.category}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.price}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{(f.price - f.price * (f.sales / 100)).toFixed(0)}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.stock}</td>
              <td className={style.contenttd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>
                <StockingOutButton />
              </td>
            </tr>
            <div style={{ width: '100%', zIndex: -1, }} className="d-flex justify-content-center align-items-center">
              <div style={{ width: '93%', marginRight: '20px', border: '0.2px solid rgba(0,0,0, 0.2)' }} />
            </div>
          </div>
          )}
        </table>
        <table>
          <tr className="w-100 d-flex" style={{ height: '80px' }} >

            <td className="w-100 d-flex " >
              <div onClick={() => history.push({ pathname: '/stocks', state: { activeTab } })} className="w-100 d-flex justify-content-center align-items-center" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '400' }}>
                + View All Stocking Out Products
              </div>
            </td>
          </tr>
        </table>
      </Card>
      }
      {activeTab === 2 && <Card className="card-white" style={{ borderRadius: '8px' }} >
        <table style={{ width: '100%', }}>
          {products.filter((d) => parseInt(d.stock) > 15).filter((f, index) => index <= 3).map((f, i) => <div style={{ width: '100%' }}>
            <tr style={{ textAlign: 'center', height: 100, width: '100%', display: 'inline-table' }}>
              <td className={style.contenttd} style={{ width: '5%' }}>{i + 1}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.SKU}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.name}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.category}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.price}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{(f.price - f.price * (f.sales / 100)).toFixed(0)}</td>
              <td className={style.contenttd} style={{ width: '10%' }}>{f.stock}</td>
              <td className={style.contenttd} style={{ textAlign: 'center', width: '10%', padding: '10px' }}>
                <InStockButton />
              </td>
            </tr>
            <div style={{ width: '100%', zIndex: -1, }} className="d-flex justify-content-center align-items-center">
              <div style={{ width: '93%', marginRight: '20px', border: '0.2px solid rgba(0,0,0, 0.2)' }} />
            </div>
          </div>
          )}
        </table>
        <table>
          <tr className="w-100 d-flex" style={{ height: '80px' }} >

            <td className="w-100 d-flex " >
              <div onClick={() => history.push({ pathname: '/stocks', state: { activeTab } })} className="w-100 d-flex justify-content-center align-items-center" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '400' }}>
                + View All In of Stock Products
              </div>
            </td>
          </tr>
        </table>
      </Card>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  products: state.products.products,
  newOrders: state.orders.newOrders,
  pastOrders: state.orders.pastOrders,
  openOrders: state.orders.openOrders,
  users: state.orders.users,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(StockStatus));

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener("resize", updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener("resize", updateScreenSize);
//   }, []);

//   return screenSize;
// }