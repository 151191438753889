/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  initialUser: null,
  errors: null,
  vendor: null,
  notification: null,
  searchQuery: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN":
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        vendor: action.vendor,
        token: action.token
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        vendor: action.vendor
      };
    case "UPDATE_PROFILE_IMAGE":
      return {
        ...state,
        vendor: {
          ...state.vendor,
          bit_img: action.vendor
        }
      };
    case "RESET":
      return {
        ...initialState
      }
    case "POST_NOTIFICATION":
      return {
        ...state,
        notification: action.notification
      }
    case 'SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.query
      };

    default:
      return state;
  }
}
