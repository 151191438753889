import * as Yup from "yup"

const shipmentValidation = Yup.object({
    tracking_id: Yup.string(),
    tracking_link: Yup.string().required("Required"),
    e_way_bill_no: Yup.string(),
    dispatch_date: Yup.date().required("Required"),
    delivery_date: Yup.date().required("Required"),
    invoice: Yup.string(),
    e_way_bill: Yup.string()
})
export { shipmentValidation }