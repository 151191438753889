/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

const Timer = ({ timeRemaining, render, setRender }) => {
  const [minute, setMinute] = useState("00");
  const [hour, setHour] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(timeRemaining / 1000);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = Math.floor(counter % 60);
        const minuteCounter = Math.floor((counter / 60) % 60);
        const hourCounter = Math.floor((counter / (60 * 60)) % 24);
        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;
        const computedHour =
          String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
        if (
          computedHour === "00" &&
          computedMinute === "00" &&
          computedSecond === "00"
        ) {
          setIsActive((isActive) => !isActive);
          setRender((render) => !render);
        }
        // setSecond(computedSecond);
        setMinute(computedMinute);
        setHour(computedHour);
        setCounter((counter) => counter - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  return (
      <span>
        {/* Time left {hour}h : {minute}m : {second}s */}
        {hour}h : {minute}m
      </span>
  );
};

export default Timer;
