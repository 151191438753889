import React from 'react';
import '../../assets/css/style.css'
import { Card } from 'react-bootstrap';
import style from './Notification.module.css'
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function Notification() {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
    checkedD: true
  });

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      '&container': {
        background: 'transparent'
      }
    },

    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'black',

        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
      background: 'white'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'grey',
    },
    checked: {},
  }))(Switch);
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Card className="card-white p-5">
      <div className={classnames('row d-flex pl-2', style.cardtitle)}>
        <h1>    Notifications</h1>
      </div>
      <div>
        <div className={classnames('row d-flex pl-2', style.subtitle)}>
          <h1>Push Notifications</h1>
        </div>
        <Typography component="div">
          <div className="row">
            <div className="col-4">
              <Grid item className={classnames('ml-2', style.label)} >Notify me about reminders</Grid>
            </div>
            <div>
              <AntSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />
            </div>
          </div>
        </Typography>
        <Typography component="div">
          <div className="row mt-1">
            <div className="col-4">
              <Grid item className={classnames('ml-2', style.label)} >Notify me about promtions, events and updates </Grid>
            </div>
            <div>
              <AntSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />
            </div>
          </div>
        </Typography>
      </div>
      <div>
        <div className={classnames('row d-flex pl-2', style.subtitle)}>
          <h1>Email</h1>
        </div>
        <Typography component="div">
          <div className="row">
            <div className="col-4">
              <Grid item className={classnames('ml-2', style.label)} >Notify me about reminders </Grid>
            </div>
            <div>
              <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />
            </div>
          </div>
        </Typography>
        <Typography component="div">
          <div className="row mt-1">
            <div className="col-4">
              <Grid item className={classnames('ml-2', style.label)} >Notify me about promtions, events and updates </Grid>
            </div>
            <div>
              <AntSwitch checked={state.checkedD} onChange={handleChange} name="checkedD" />
            </div>
          </div>
        </Typography>
      </div>

    </Card>

  )
}
export default Notification;