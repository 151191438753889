import { combineReducers } from "redux";

import authReducer from './auth';
import orders from "./orders";
import products from "./products";

const reducer = combineReducers({
  auth: authReducer,
  orders: orders,
  products: products
});
export default reducer;
