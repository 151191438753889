import React from "react";
import Carousel from "react-elastic-carousel";
import Card from "react-bootstrap/Card";
import style from "./Review.module.css";

const Review = () => {
  const datas = [
    {
      Title: "Adam",
      Text: "very nice product keep up the goood one order delivery at time very nice product keep up the goood one order delivery at time",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "david",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "Adam",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "Adam",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "Adam",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
    {
      Title: "joseph",
      Text: "very nice product keep up the goood one order delivery at time ",
    },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === 'PREV' ? 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/leftArrow.svg' : 'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(6).svg'
    return (
      <button onClick={onClick} disabled={isEdge} style={{ border: 'none', backgroundColor: 'transparent', height: '100%' }}>
        <img src={pointer} alt='' />
      </button>
    )
  }

  const breakingPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1250, itemsToShow: 3 },
    { width: 1350, itemsToShow: 4 },
    { width: 1450, itemsToShow: 5 },
    { width: 1550, itemsToShow: 5 },
    { width: 1650, itemsToShow: 5 },
    { width: 1750, itemsToShow: 4 },
    { width: 2050, itemsToShow: 4 },
    { width: 2350, itemsToShow: 7 },
  ]

  return (
    <>
      <h1 className={style.h1}> Rating & Reviews </h1>
      <Carousel renderArrow={myArrow} breakPoints={breakingPoints} pagination={false}>
        {datas.map((data, index) => (
          <div className={style.rec}>
            <Card className={style.cardfull} key={index}>
              <Card.Body>
                <Card.Title className={style.title}>{data.Title}</Card.Title>
                <Card.Text className={style.cardtext}>{data.Text}</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>

              <Card.Img
                variant="bottom"
                className={style.starimage}
                src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/stars.png"
              />
            </Card>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default Review;
