import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import style from "./Orders.module.css";
import classnames from "classnames";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { FilterButton } from 'components'
import { makeStyles } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import dateformat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cities from '../../components/AccountInfo/Cities.json'
export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: 400,
        color: "#888888",
        marginBottom: "0px !important",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "auto",
  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "44vh",
  },

  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 10,
      height: 10,

      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  typo: {
    fontSize: "7px",
  },
  filterHeader: {
    fontSize: "7px",
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      checked={props.value}
      color="default"
      checkedIcon={
        <span className={classnames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const FilterButtons = ({
  setNOrders,
  openOrders,
  setAnchorEl,
  products,
  newOrders,
  setPOrders,
  orders,
  pastOrders,
  setOOrders,
  handleClick,
  setCurrentFilterOption,
  currentFilterOption,
  activeTab,
  pastStates,
  NOrders
}) => {
  const [openOrderFilter, setOpenOrderFilter] = useState("");
  const [stocks, setStocks] = useState("");
  const [orderId, setOrderId] = useState("")
  const [deliveryDate, setDeliveryDate] = useState({
    from: null,
    to: null,
  });
  const [pastState, setPastState] = useState("");

  useEffect(() => {
    if (pastStates === "SHIPPED")
      setPastState("delivered")
    else
      setPastState("")
  }, [pastStates])

  const handleDeliveryDate = () => {
    const arr = openOrders.filter(
      (f) =>
        f.delivery_date >=
        dateformat(new Date(deliveryDate.from), "yyyy-mm-dd") &&
        f.delivery_date <=
        dateformat(new Date(deliveryDate.to), "yyyy-mm-dd")
    );
    setOOrders(arr);
    setAnchorEl(null);
  };


  const handleFilterNew1 = () => {
    switch (stocks) {
      case "orderIdSearch":
        return handleOrderIdSearch()

      case "region":
        return handleDeliveryRegion();
      default:
        return null
    }
  };

  const handleFilterPast1 = () => {
    switch (pastState) {
      case "deliverypast":
        return handleDeliveryDatePast();

      case "orderpast":
        return handleOrderDatePast();

      case "delivered":
        return handleFilterDeliveredPast();

      case "returned":
        return handleFilterReturned();

      case "cancelled":
        return handleFilterCancelled();
      default:
        return null
    }
  };

  const handleOrderDatePast = () => {
    const arr = pastOrders?.filter(
      (f) =>
        f.order.createdAt >=
        dateformat(new Date(deliveryDate.from), "yyyy-mm-dd") &&
        f.order.createdAt <=
        dateformat(new Date(deliveryDate.to), "yyyy-mm-dd")
    );
    setPOrders(arr);
  };

  const handleDeliveryDatePast = () => {
    const arr = pastOrders.filter(
      (f) =>
        f.shipments.length && f.shipments.some((shipment => {
          return shipment.delivery_date >=
            dateformat(new Date(deliveryDate.from), "yyyy-mm-dd") &&
            shipment.delivery_date <=
            dateformat(new Date(deliveryDate.to), "yyyy-mm-dd")
        }))
    );
    setPOrders(arr);
  };

  const handleOrderDate = () => {
    const arr = openOrders?.filter(
      (f) =>
        f.order.createdAt >=
        dateformat(new Date(deliveryDate.from), "yyyy-mm-dd") &&
        f.order.createdAt <=
        dateformat(new Date(deliveryDate.to), "yyyy-mm-dd")
    );
    setOOrders(arr);
    setAnchorEl(null);
  };

  const handleDeliveryRegion = () => {
    const farr = newOrders
      .filter(
        (orders) =>
          cities.findIndex(city => city.name === orders.address.city) !== -1
      )
    setNOrders(farr);
  };
  const handleOrderIdSearch = () => {
    const farr = newOrders.filter((order) => {
      return order.order.order_id === orderId
    }
    )
    setNOrders(farr);
  }


  const handleOpenFilter = () => {
    switch (openOrderFilter) {
      case "delivery":
        return handleDeliveryDate();

      case "delivery1":
        return handleOrderDate();

      case "accepted":
        return handleFilterAccepted();

      case "delivered":
        return handleFilterDelivered();
      default:
        return null
    }
  };

  const handleFilterAccepted = () => {

    const arr = pastOrders.filter(
      (f) => {
        return (f.shipments.length && f.shipments.some((shipment => {
          return shipment.orderItems.length && shipment.orderItems.some((orderItem => {
            return orderItem.status === 'ACCEPTED'
          }))
        }))) || (f.orderItem.length && f.orderItem.some((orderItem => {
          return orderItem.status === 'ACCEPTED'
        })))
      }
    );
    setOOrders(arr);
    setAnchorEl(null);

  };

  const handleFilterDelivered = () => {

    const arr = pastOrders.filter(
      (f) => {
        return (f.shipments.length && f.shipments.some((shipment => {
          return shipment.orderItems.length && shipment.orderItems.some((orderItem => {
            return orderItem.status === 'SHIPPED'
          }))
        }))) || (f.orderItem.length && f.orderItem.some((orderItem => {
          return orderItem.status === 'SHIPPED'
        })))
      }
    );
    setOOrders(arr);
  };

  const handleFilterDeliveredPast = () => {

    const arr = pastOrders.filter(
      (f) => {
        return (f.shipments.length && f.shipments.some((shipment => {
          return shipment.orderItems.length && shipment.orderItems.some((orderItem => {
            return orderItem.status === 'DELIVERED'
          }))
        }))) || (f.orderItem.length && f.orderItem.some((orderItem => {
          return orderItem.status === 'DELIVERED'
        })))
      }
    );
    setPOrders(arr);
  };

  const handleFilterCancelled = () => {

    const arr = pastOrders.filter(
      (f) => {
        return (f.shipments.length && f.shipments.some((shipment => {
          return shipment.orderItems.length && shipment.orderItems.some((orderItem => {
            return orderItem.status === 'CANCELLED'
          }))
        }))) || (f.orderItem.length && f.orderItem.some((orderItem => {
          return orderItem.status === 'CANCELLED'
        })))
      }
    );
    setPOrders(arr);
  };

  const handleFilterReturned = () => {
    const arr = pastOrders.filter(
      (f) => {
        return (f.shipments.length && f.shipments.some((shipment => {
          return shipment.orderItems.length && shipment.orderItems.some((orderItem => {
            return orderItem.status === 'RETURNED'
          }))
        }))) || (f.orderItem.length && f.orderItem.some((orderItem => {
          return orderItem.status === 'RETURNED'
        })))
      }
    );
    setPOrders(arr);
  };



  return (
    <>
      <NavDropdown
        id="basic-nav-dropdown"
        title={
          <FilterButton
            type="button"
            onClick={handleClick}
            icon={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Vector.png'}
          >
          </FilterButton>
        }
      // style={{ marginRight: '-5%' }}
      >
        <FormControl component="fieldset">
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontSize: "12px",
                opacity: 0.5,
                marginLeft: "-1%",
                fontWeight: 700,
              }}
            >
              FILTERS
            </p>
            <p
              style={{
                fontSize: "12px",
                opacity: 0.5,
                marginLeft: "-1%",
                textDecoration: 'underline',
                fontWeight: 700,
              }}
              onClick={() => {
                // setactiveProducts(products);
                // setProductsFilter(null);
                setOpenOrderFilter(null);
                setNOrders(newOrders);
                setCurrentFilterOption(0);
                setOpenOrderFilter("");
                setOOrders(openOrders);
                setPastState("");
                setPOrders(pastOrders);
              }}
            >
              Clear All</p>
          </div>
          {activeTab === 0 && (
            <RadioGroup
              defaultValue="none"
              aria-label="gender"
              name="customized-radios"
              value={currentFilterOption}
            >
              <FormControlLabel
                value="delivery"
                className={style.marginBottom}
                control={<StyledRadio value={currentFilterOption === 1} />}

                onClick={() => setCurrentFilterOption(1)}
                label="Delivery Region"
              />
              {currentFilterOption === 1 && (
                <>
                  <select
                    defaultValue="Chennai"
                    onChange={(e) => {
                      // setRegion(e.target.value);
                      setStocks("region");
                    }}
                    name="region"
                    className={style.selectFilter}
                    id="region"
                  >
                    {cities.map(city => {
                      return <option value={city.name}>{city.name}</option>
                    })}
                  </select>
                </>
              )}

              <FormControlLabel
                value="orderIdSearch"
                className={style.marginBottom}
                control={<StyledRadio value={currentFilterOption === 2} />}
                onClick={() => {
                  setCurrentFilterOption(2)
                }}
                label="By Order ID"
              />
              {currentFilterOption === 2 ? <input
                type="text"
                defaultValue=""
                onChange={(e) => {
                  setOrderId(e.target.value)
                  setStocks("orderIdSearch");
                }
                }
                style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
              // className={classnames(style.dateInput, "unstyled")}
              /> : null}
            </RadioGroup>


          )}

          {activeTab === 1 && (
            <RadioGroup
              defaultValue="none1"
              aria-label="gender1"
              name="customized-radioss"
            >
              <FormControlLabel
                value="delivery"
                className={style.marginBottom}
                control={<StyledRadio value={openOrderFilter === 'delivery'} />}
                onClick={() => setOpenOrderFilter("delivery")}
                label="Delivery date"
              />

              {openOrderFilter === "delivery" && (
                <div
                  className="d-flex justify-content-between align-items-center flex-row p-1"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    width: "100%",
                  }}
                >
                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.from}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, from: date })}
                    placeholderText="dd-mm-yyyy" peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select" />

                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.to}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, to: date })

                    } peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd-mm-yyyy" />

                  <div className="m-1">
                    <i
                      class="fa fa-calendar"
                      style={{ opacity: 0.5 }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )}

              <FormControlLabel
                value="delivery1"
                className={style.marginBottom}
                control={<StyledRadio value={openOrderFilter === 'delivery1'} />}
                onClick={() => setOpenOrderFilter("delivery1")}
                label="Order date"
              />

              {openOrderFilter === "delivery1" && (
                <div
                  className="d-flex justify-content-between align-items-center flex-row p-1"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    width: "100%",
                  }}
                >
                  {/* <input
                    type="date"
                    defaultValue="07-06-2021"
                    onChange={(e) =>
                      setDeliveryDate({ ...deliveryDate, from: e.target.value })
                    }
                    className={classnames(style.dateInput, "unstyled")}
                  /> */}

                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.from}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, from: date })}
                    placeholderText="dd-mm-yyyy" peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select" />

                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.to}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, to: date })

                    } peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd-mm-yyyy" />

                  {/* <input
                    type="date"
                    defaultValue="08-06-2021"
                    onChange={(e) =>
                      setDeliveryDate({ ...deliveryDate, from: e.target.value })
                    }
                    className={classnames(style.dateInput, "unstyled")}
                  /> */}
                  <div className="m-1">
                    <i
                      class="fa fa-calendar"
                      style={{ opacity: 0.5 }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )}

              <div
                style={{ border: "1px solid rgba(0,0,0,0.1)", width: "96%" }}
              ></div>

              <FormControlLabel
                value="delivery2"
                className={style.marginBottom}
                control={<StyledRadio value={openOrderFilter === 'accepted'} />}
                onClick={() => setOpenOrderFilter("accepted")}
                label="Accepted"
              />

              <FormControlLabel
                value="delivery3"
                className={style.marginBottom}
                onClick={() => setOpenOrderFilter("delivered")}
                control={<StyledRadio value={openOrderFilter === 'delivered'} />}
                label="Shipped"
              />
            </RadioGroup>
          )}

          {activeTab === 2 && (
            <RadioGroup
              defaultValue="none"
              aria-label="gender"
              name="customized-radios"
            >
              <FormControlLabel
                value="delivery"
                className={style.marginBottom}
                control={<StyledRadio value={pastState === 'deliverypast'} />}
                onClick={() => setPastState("deliverypast")}
                label="Delivery date"
              />

              {pastState === "deliverypast" && (
                <div
                  className="d-flex justify-content-between align-items-center flex-row p-1"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    width: "100%",
                  }}
                >
                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.from}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, from: date })}
                    placeholderText="dd-mm-yyyy" peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select" />

                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.to}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, to: date })
                    } peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd-mm-yyyy" />

                  <div className="m-1">
                    <i
                      class="fa fa-calendar"
                      style={{ opacity: 0.5 }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )}

              <FormControlLabel
                value="delivery3"
                className={style.marginBottom}
                control={<StyledRadio value={pastState === 'orderpast'} />}
                onClick={() => setPastState("orderpast")}
                label="Order date"
              />

              {pastState === "orderpast" && (
                <div
                  className="d-flex justify-content-between align-items-center flex-row p-1"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    width: "100%",
                  }}
                >
                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.from}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, from: date })}
                    placeholderText="dd-mm-yyyy" peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select" />

                  <DatePicker className={classnames(style.dateInput, "unstyled")}
                    selected={deliveryDate.to}
                    onChange={(date) => setDeliveryDate({ ...deliveryDate, to: date })

                    } peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd-mm-yyyy" />

                  <div className="m-1">
                    <i
                      class="fa fa-calendar"
                      style={{ opacity: 0.5 }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              )}

              <div
                style={{ border: "1px solid rgba(0,0,0,0.1)", width: "96%" }}
              ></div>

              <FormControlLabel
                value="delivery4"
                className={style.marginBottom}
                control={<StyledRadio value={pastState === 'delivered'} />}
                onClick={() => setPastState("delivered")}
                label="Delivered"
              />

              <FormControlLabel
                value="delivery6"
                className={style.marginBottom}
                control={<StyledRadio value={pastState === 'returned'} />}
                onClick={() => setPastState("returned")}
                label="Returned"
              />

              <FormControlLabel
                value="delivery7"
                className={style.marginBottom}
                control={<StyledRadio value={pastState === 'cancelled'} />}
                onClick={() => setPastState("cancelled")}
                label="Cancelled"
              />
            </RadioGroup>
          )}
        </FormControl>

        <div className="mt-3 mb-2"></div>
        {activeTab === 0 && (
          <button
            type="button"
            className={style.filterButton}
            onClick={handleFilterNew1}
          >
            FILTER
          </button>
        )}
        {activeTab === 1 && (
          <button
            type="button"
            className={style.filterButton}
            onClick={handleOpenFilter}
          >
            FILTER
          </button>
        )}
        {activeTab === 2 && (
          <button
            type="button"
            className={style.filterButton}
            onClick={handleFilterPast1}
          >
            FILTER
          </button>
        )}
      </NavDropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  products: state.orders.products,
  newOrders: state.orders.newOrders,
  pastOrders: state.orders.pastOrders,
  openOrders: state.orders.openOrders,
  users: state.orders.users,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(FilterButtons));
