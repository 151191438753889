/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import '../../assets/css/style.css'
import { Card, Row } from 'react-bootstrap';
import style from './FinancesPage.module.css';
import classnames from 'classnames';
import UnopDropdown from "unop-react-dropdown";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { Modal, makeStyles } from "@material-ui/core";
import { DownloadReport } from "components";
import { closeOutline } from "assets/icons";
import { createMuiTheme } from "@material-ui/core";
import { DeliveredButton } from 'components';
export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: 400,
        color: "#888888",
        marginBottom: "0px !important",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 400000,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: 388,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 2, 4),
    height: "auto",
  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "44vh",
  },
  paper3: {
    position: "absolute",
    width: 316,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper4: {
    position: "absolute",
    width: 400,
    zIndex: 400000,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
    height: 353,
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },


  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 10,
      height: 10,

      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  typo: {
    fontSize: "7px",
  },
  filterHeader: {
    fontSize: "7px",
  },
  // root: {
  //   "& .MuiFormLabel-root": {
  //     color: "red", // or black,
  //     fontSize: "12px",
  //   },
  // },
}));
const FinancesPage = () => {

  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState("");
  const classes = useStyles();


  const columns = [
    { text: 'S.No', width: '5%' },
    { text: 'Order ID', width: '15%' },
    { text: 'Order Value', width: '15%' },
    { text: 'Date of Payments', width: '15%' },
    { text: 'Status of Receivables', width: '15%' },

  ]

  const columnsub = [
    { text: 'S.No', width: '5%' },
    { text: 'Order ID', width: '10%' },
    { text: 'Customer Name', width: '10%' },
    { text: 'No. of. Products', width: '10%' },
    { text: 'Order Date', width: '10%' },
    { text: 'Order Status', width: '10%' },
    { text: 'Invoice', width: '10%' },
    { text: 'Order Value', width: '10%' },

  ]

  const data = [
    {
      order_id: 'ADGEGGFK',
      order_value: 10000,
      date_of_payments: 'Fed 27, 2020',
      payment_due: false,
      sub_status: 'over_due'
    },
    {
      order_id: 'ADGEGGFK',
      order_value: 10000,
      date_of_payments: 'Fed 27, 2020',
      payment_due: false,
      sub_status: 'not_deposited'
    },
    {
      order_id: 'ADGEGGFK',
      order_value: 10000,
      date_of_payments: 'Fed 27, 2020',
      payment_due: true,
      sub_status: 'deposited'
    },
    {
      order_id: 'ADGEGGFK',
      order_value: 10000,
      date_of_payments: 'Fed 27, 2020',
      payment_due: true,
      sub_status: 'not_yet_due'
    }
  ];


  const invoiceUpload = [
    {
      order_id: 'ADSKF02698',
      customer_name: 'Haylie Saris',
      no_of_products: 2,
      order_date: 'Feb 15 2021',
      order_value: '₹ 45,000'
    },
    {
      order_id: 'ADSKF02698',
      customer_name: 'Haylie Saris',
      no_of_products: 2,
      order_date: 'Feb 15 2021',
      order_value: '₹ 45,000'
    },
    {
      order_id: 'ADSKF02698',
      customer_name: 'Haylie Saris',
      no_of_products: 2,
      order_date: 'Feb 15 2021',
      order_value: '₹ 45,000'
    }
  ]

  function StyledRadio(props) {
    const classes = useStyles();

    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={classnames(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }



  return (
    <div style={{ paddingLeft: '16px', paddingRight: '24px', paddingTop: '60px', }} >
      <div className={"d-flex flex-row justify-content-between align-items-center"}>
        <div >
          <h2 className={classnames("font-weight-700", style.header)}>Finances</h2>
        </div>

        <div
          className={"d-flex flex-row justify-content-end align-items-center"}
          style={{ width: "50%" }}
        >
          <>

            <UnopDropdown align="CENTER" closeOnClickOut={true}
              trigger={
                <button type="button"
                  className={classnames(
                    "filter-button mr-2"
                  )}
                >
                  <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Vector.png" style={{ marginTop: '7px', marginRight: '7px', width: '25px', height: '25px' }} />
                  &nbsp;
                  <span >Filters</span>
                </button>}
            >
              <div style={{ backgroundColor: 'white', borderRadius: 10, width: '200px', padding: '20px', boxShadow: '4px 8px 10px rgba(99, 94, 88, 0.2)', marginTop: '10px', marginLeft: '-0%' }}>
                <FormControl component="fieldset">

                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{
                        fontSize: "12px",
                        opacity: 0.5,
                        marginLeft: "-1%",
                        fontWeight: 700,
                      }}
                    >
                      FILTERS
                    </p>
                  </div>
                  <RadioGroup
                    defaultValue="none"
                    aria-label="gender"
                    name="customized-radios"

                  >
                    {" "}
                    <FormControlLabel
                      value="published"
                      className={style.marginBottom}
                      control={<StyledRadio />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Payment Received</p>}
                    />
                    <FormControlLabel
                      value="verifying"
                      className={style.marginBottom}
                      control={<StyledRadio />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Payment Due</p>}
                    />
                    <div className={style.divider}></div>
                    <FormControlLabel
                      value="most-viewed"
                      className={style.marginBottom}
                      control={<StyledRadio />}
                      label={<p style={{ fontWeight: 200, fontSize: '14px' }}>Date of Payment</p>}
                    />

                  </RadioGroup>
                </FormControl>
                <div className="mt-3 mb-2"></div>
                <button
                  type="button"
                  className={classnames("btn btn-dark mr-5 ", style.filterButton1)}
                  style={{
                    width: "100%",
                    height: '35px',
                    marginLeft: '-1%'
                  }}

                >
                  FILTER
                </button>
              </div>
            </UnopDropdown>
          </>

          {activeTab === 1 && <button
            className={classnames("btn btn-dark mr-3 ", style.filterButton1)}
          >
            Bulk Upload
          </button>}

          <DownloadReport
            type="button"
            style={{}}
            // onClick={() => {
            //   setModal("DOW");
            // }}
            className={""}
          >
            Download Catalogue
          </DownloadReport>
        </div>
        {/* <Col sm={6} className="d-flex justify-content-end" style={{paddingTop:'25px'}}>
              <button type="button" class="btn btn-dark" onClick={handleClick}><i class="fas fa-filter"></i> <span>Filter</span></button>
              <Menu
                id='simple-menu'
                c
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: '5px',
                    marginTop: '8%',
                    width: 'auto',
                    
                    position: 'abo',
                  },
                }}>
                 
                        <MenuItem>
                          {' '}
                          &nbsp; &nbsp;hello
                        </MenuItem>
                        
                        <MenuItem>
                          {' '}
                          &nbsp; &nbsp;hello
                        </MenuItem>
                        
                        <MenuItem >
                          {' '}
                          &nbsp; &nbsp;hello        
                        </MenuItem>
                        <MenuItem >
                          {' '}
                          &nbsp; &nbsp;hello
                        </MenuItem>
                    
                  <MenuItem>
                    <button type="button" class="btn btn-dark w-100 justify-content-center text-center align-items-center pt-1" ><i class="fas fa-filter"></i> <span>Filter</span></button>
                  </MenuItem>
              </Menu>

              <button type="button" class="btn btn-dark ml-5">Download Report</button> 
            </Col> */}

      </div>
      <br />
      <div className={style.tab_container}>
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 0 ? style.active : style.notactive)} onClick={() => setActiveTab(0)}>
          <h6>P&M Receivable</h6>
        </div>
        {/* <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 1 ? style.active : style.notactive)} onClick={()=> setActiveTab(1)}>
                <h6>Campaign Payment</h6>
            </div> */}
        <div className={classnames("d-flex justify-content-center align-items-center", activeTab === 1 ? style.active : style.notactive)} onClick={() => setActiveTab(1)}>
          <h6>Invoice Upload</h6>
        </div>
      </div>
      <table className="mb-2" style={{ width: '100%' }}>
        <tr style={{ textAlign: 'center', borderRadius: '8px', backgroundColor: '#CDC6C3' }}>
          {activeTab === 0 ?
            columns.map((d) => <td style={{ padding: '10px', width: d.width }} > {d.text} </td>)
            :
            columnsub.map((d) => <td style={{ padding: '10px', width: d.width }} > {d.text} </td>)

          }
        </tr>
      </table>
      <Card className="card-white" style={{ borderRadius: '8px', paddingBottom: '10px' }} >
        {activeTab === 1 ?

          <table style={{ width: '100%' }}>
            {invoiceUpload.map((f, i) =>
              <div className="w-100">
                <tr className={style.tablerow} style={{ display: 'inline-table', width: '100%' }}>
                  <td className={style.tablecol} style={{ width: '5%' }}>{i + 1}</td>
                  <td className={style.tablecol}>{f.order_id}</td>
                  <td className={style.tablecol}>{f.customer_name}</td>
                  <td className={style.tablecol}>{f.no_of_products}</td>
                  <td className={style.tablecol}>{f.order_date}</td>
                  <td className={style.tablecol}>
                    <div className="d-flex justify-content-center">
                      <DeliveredButton />
                    </div>
                  </td>
                  <td className={style.tablecol}><a className={style.hyperlink}>Invoice</a></td>
                  <td className={style.tablecol}>
                    <div className="d-flex flex-column">
                      {f.order_value}
                      <a className={classnames(style.hyperlink, 'mt-3')}>Generate Invoice</a>
                    </div>
                  </td>
                </tr>
                {i !== invoiceUpload.length - 1 && <div style={{ width: '100%', zIndex: -1, height: '0px', marginTop: '10px' }} className="d-flex justify-content-center align-items-center">
                  <div style={{ width: '95%', border: '0.2px solid #CDC6C3', height: '0px' }} />
                </div>}
              </div>
            )}
          </table>

          :

          <table style={{ width: '100%' }}>
            {data.map((f, i) =>
              <div className="w-100">
                <tr className={style.tablerow} style={{ display: 'inline-table', width: '100%' }}>
                  <td className={style.tablecol1} style={{ width: '5%' }}>{i + 1}</td>
                  <td className={style.tablecol1}>{f.order_id}</td>
                  <td className={style.tablecol1}>{f.order_value}</td>
                  <td className={style.tablecol1}>{f.date_of_payments}</td>
                  <td className={style.tablecol1}><button type="button" style={{ width: '168px' }} className={classnames(f.payment_due === true ? style.paymentdue : style.notpaymentdue, style.paymentbutton)} >{f.payment_due === true ? "Payment Due" : "Payment received"}</button></td>
                  {/* Checks the Sub Status and renders the required button */}
                  {/* {f.sub_status === 'over_due' && <td className={style.tablecol}><button type="button" className={classnames(style.paymentbutton,style.paymentdue)}>Over Due</button></td>} */}
                  {/* {f.sub_status === 'not_deposited' && <td className={style.tablecol}><button type="button" className={classnames(style.paymentbutton,style.notdeposited)}>Not Deposited</button></td>} */}
                  {/* {f.sub_status === 'deposited'&& <td className={style.tablecol}><button type="button" className={classnames(style.paymentbutton,style.notpaymentdue)}>Deposited</button></td> }
                  {f.sub_status === 'not_yet_due'&& <td className={style.tablecol}><button type="button" className={classnames(style.paymentbutton,style.paymentdue)}>Not Yet Due</button></td> }
               */}
                </tr>
                {i !== data.length - 1 && <div style={{ width: '100%', zIndex: -1, height: '0px', marginTop: '10px' }} className="d-flex justify-content-center align-items-center">
                  <div style={{ width: '95%', border: '0.2px solid #CDC6C3', height: '0px' }} />
                </div>}
              </div>
            )}
          </table>

        }


      </Card>


      <Modal
        open={modal === "DOW"}
        aria-labelledby="simple-modal-title"
        className={classnames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"
        style={{ padding: '20px' }}
      >
        <form className={classes.paper1}   >
          <div className={style.APHeader}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: 'bolder' }} onClick={() => setModal(null)}>Download catalogue</h2>
            <img alt='' src={closeOutline} onClick={() => setModal(null)} />
          </div>
          <div className={classnames(style.APBody, "mt-3")} style={{ padding: '10px' }} >
            <Row className={style.ApLarge} >
              <p className="col-4 APlab " style={{ width: '17vw', marginTop: '25px' }}>Select type:</p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ width: '10%', textAlignLast: 'left', marginTop: '20px' }}
                name="status"
                id="report"
              >
                <option className="col-4" value="0">select type</option>
              </select>
            </Row>
            <Row className="APLarge">
              <p className="col-4 APlab " style={{ width: '20vw', marginTop: '25px' }}>Select format:</p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ marginTop: '20px' }}
                name="file"
                id="file"
              >
                <option lassName="col-4" value="csv">
                  CSV
                </option>
                <option lassName="col-4" value="pdf">
                  PDF
                </option>
              </select>
            </Row>
          </div>

          <div className={style.APDown}>
            {/* <CSVLink  
                    data={report === "All Products" ? products.map((d) => ({...d, room_type: `${d.room_type.map((e) => e)}`, material: `${d.material.map((e) => e)}` , dimensions: `${d.dimensions.l}X${d.dimensions.b}X${d.dimensions.h}`})) : ap}
                    headers={headers}
                    filename={"Report.csv"}
                    className={classnames(style.APMButton1, "mt-4 ")} 
                  >
                    Download
                  </CSVLink> */}
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default FinancesPage;