/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import ReactSelect from "react-select";
import { fetchProducts } from "../../store/actions/productActions";
import style from "./EditProduct.module.css";
import classNames from "classnames";
import axios from "axios";
import Upload from "rc-upload";
import "../../assets/css/style.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { baseUrl } from "config/config";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-awesome-slider/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import convert from "convert-units";
import UPLOAD from "../../assets/img/UPLOAD.PNG";
import Alertpage from "../ProductCatalogue/Alertpage";
import { toast } from "react-toastify";
import { Tags } from "utils/Tags";

import { TAX_SLAB_ARRAY } from "views/ProductCatalogue/constants";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 40000,
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 0),
    height: "auto",
  },
  paper3: {
    position: "absolute",
    width: 327,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: 400,
    border: 0,
  },
  paper4: {
    position: "absolute",
    width: "25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "13rem",
    border: 0,
  },
}));

const EditProduct = ({
  open,
  setOpen,
  setactiveProducts,
  activeProducts,
  auth,
  product,
  vendorData
}) => {
  const classes = useStyles();
  const [threeDModel, setThreeDModel] = useState()
  const [previewImages, setPreviewImages] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const [loading, setLoading] = useState(false);
  const [previousValues, setPreviousValues] = useState({})
  const [tags, setTags] = useState([]);
  const [categories,setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const Preview = () => {
    return (
      <span
        style={{
          alignSelf: "flex-start",
          margin: "10px 3%",
          fontFamily: "Open Sans",
        }}
      >
        <Carousel
          autoFocus={true}
          axis={"horizontal"}
          centerMode={true}
          showThumbs={false}
        >
          {previewImages.map((d) => (
            <img src={d.previewUrl} alt={d.id} style={{ width: "100%" }} />
          ))}
        </Carousel>
      </span>
    );
  };

  useEffect(() => {
    if (open?.bool === 'EDIT') {
      setInput({ ...product, sku: product.SKU, rooms: product.room_type, subcategory: product.subcategory });
      setPreviousValues({ ...product, sku: product.SKU, rooms: product.room_type })
      let arr = product.image.map((d) => ({ ...d, previewUrl: d }))
      if (product['3d_models']) {
        product['3d_models'].glb && setThreeDModel(product['3d_models'].glb)
        product['3d_models'].usdz && setThreeDModel(product['3d_models'].usdz)
      }
      setPreviewImages(arr);
    }
  }, [product, open])

  const [inputs, setInput] = React.useState({
    dimensions: {
      unit: "F&I",
      l: 0,
      b: 0,
      h: 0,
    },
  });

  const handleCategory = (e) => {
    const {name, value} = e.target;
    setInput({...inputs, [name]: value, subcategory:''})
    const tempCat = tags.find((tag) => tag.name === value)
    const subcategory = tempCat.subcat;
    let subcats = []
    for(const subcat of subcategory){
      subcats.push({id:subcat.name, introline:subcat.name})
    }
    setSubCategories(subcats)
  }
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInput({
      ...inputs,
      [name]: value,
      stock: 10,
      user_id: "",
    });
  };

  const getSubCat = async() => {
    const tags = await Tags()
    setTags(tags)
    let categorylist = []
    for (const category of tags){
      categorylist.push({id:category.name, introline:category.name})
    }
    setCategories(categorylist)
  }

  useEffect(() => {
    getSubCat()
  },[])
  useEffect(() => {
    if(inputs.category){
      const tempCat = tags.find((tag) => tag.name === inputs.category)
      const subcategory = tempCat.subcat;
      let subcats = [];
      for (const subcat of subcategory){
        subcats.push({id: subcat.name, introline: subcat.name})
      }
      setSubCategories(subcats)
    }
  },[inputs.category, tags])

  const toasting = (type, text) => {
    if (type === 'error') {
      return toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  const handledata = async (e) => {
    // console.log("here")
    e.preventDefault();
    setLoading(true);
    let changes = false
    if (image && Object.keys(image).length) {
      changes = true
    }
    // console.log({ previousValues })
    for (let i = 0; i < Object.keys(previousValues).length; i++) {
      const key = Object.keys(previousValues)[i]
      // console.log({ key })
      if (changes === true) {
        break
      } else if (Array.isArray(previousValues[key])) {
        if (previousValues[key].length !== inputs[key].length) {
          changes = true
          break;
        } else {
          for (let j = 0; j < previousValues[key].length; j++) {
            if (!previousValues[key].includes(inputs[key][j])) {
              changes = true
              break;
            }
          }
        }
      } else if (typeof (previousValues[key]) === 'object') {
        if (previousValues[key] !== null) {
          for (let k = 0; k < Object.keys(previousValues[key]).length; k++) {
            const innerKey = Object.keys(previousValues[key])[k]
            if (previousValues[key][innerKey] !== inputs[key][innerKey]) {
              changes = true
              break;
            }
          }
        } else {
          if (previousValues[key] !== inputs[key]) {
            changes = true
            break;
          }
        }

      } else {
        if (previousValues[key] !== inputs[key]) {
          changes = true
          break;
        }
      }
    }
    if (changes) {
      try {
        if (previewImages.length === 0) {
          toasting('error', 'Image required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        } else if (inputs?.rooms.length === 0) {
          toasting('error', 'Rooms required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (inputs.material?.length === 0) {
          toasting('error', 'Material required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (!inputs.color) {
          toasting('error', 'Color required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (!inputs.style) {
          toasting('error', 'Style required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (!inputs.category) {
          toasting('error', 'Category required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (!inputs.subcategory) {
          toasting('error', 'SubCategory required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else if (!inputs.pattern) {
          toasting('error', 'Pattern required.')
          setLoading(false)
          setOpen({ ...open, bool: '', product: null });
        }
        else {
          const fd = new FormData();
          inputs.product_id && fd.append("product_id", inputs.product_id);
          fd.append("name", inputs.name);
          fd.append("price", inputs.price);
          fd.append("description", inputs.description);
          fd.append("SKU", inputs.sku);
          fd.append("category", inputs.category);
          fd.append("subcategory", inputs.subcategory);
          fd.append("stock", inputs.stock);
          fd.append("vendor_id", auth.vendor._id);
          fd.append("status", false);
          fd.append("room_type", JSON.stringify(inputs.room_type));
          fd.append("material", JSON.stringify(inputs.material));
          fd.append("sales", inputs.sales);
          fd.append("dimensions", JSON.stringify(inputs.dimensions));
          fd.append("style", inputs.style);
          fd.append("pattern", inputs?.pattern);
          fd.append("color", inputs.color);
          fd.append("_id", product._id);
          fd.append("hsn_code", inputs.hsn_code);
          fd.append("tax_slab", inputs.tax_slab);
          fd.append("cost_price", Math.round(inputs?.price - inputs?.price * ((vendorData?.affiliation_rate || 5) / 100)))
          fd.append("pixel_price", Math.round(inputs?.price - ((inputs.price * ((vendorData?.affiliation_rate || 5) / 100)) / 2)))
          for (var i = 0; i < image.length; i++) {
            fd.append("image", image[i]);
          }
          const res = await axios
            .put(`${baseUrl}/product/update`, fd, {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }).then(async (ele) => {
              if (threeDFiles) {
                const fd1 = new FormData();
                fd1.append("id", ele.data.newproduct._id);
                fd1.append("3d-models", threeDFiles)
                const res1 = await axios.put(`${baseUrl}/product/3d-models`, fd1, {
                  headers: {
                    Authorization: `Bearer ${auth.token}`,
                  },
                });
                if (res1) {
                  if (res1.data['3d_models']) {
                    product['3d_models'].glb && setThreeDModel(product['3d_models'].glb)
                    product['3d_models'].usdz && setThreeDModel(product['3d_models'].usdz)
                  }
                  setthreeDFiles(null)
                  setLoading(false)
                  setSuccessAlert(true);
                  window.setTimeout(() => {
                    setSuccessAlert(false);
                  }, 1000)
                  fetchProducts(auth.token);
                }
              } else {
                setthreeDFiles(null)
                setLoading(false)
                setSuccessAlert(true);
                window.setTimeout(() => {
                  setSuccessAlert(false);
                }, 1000)
                fetchProducts(auth.token);
              }
              setImage([])
              const index = activeProducts.findIndex(product => product._id === ele.data._id)
              activeProducts[index] = ele.data
              setactiveProducts(activeProducts)
              setOpen({ ...open, bool: '', product: null });
            })
        }
      } catch (err) {
        setLoading(false)
        setOpen({ ...open, bool: '', product: null });
      }
    } else {
      setLoading(false)
      setOpen({ ...open, bool: '', product: null });
    }

  };

  const [image, setImage] = React.useState([]);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      if (!image.some((el) => file.name === el.name)) {
        setImage((prev) => [...prev, file]);
        setPreviewImages((prev) => [...prev, meta]);
      } else {
        setImage(image.filter((d) => d.name !== file.name));
        setPreviewImages(previewImages.filter((d) => d.id !== meta.id));
      }
    } else if (status === "aborted") {
    }
  };


  const [start, setStart] = React.useState(null);
  const handlecart = () => {
    setStart(true);
  };
  const close = () => {
    setStart(null);
  };

  const [threeDFiles, setthreeDFiles] = useState(null);

  const props = {
    accept: ".glb, .usdz",
    multiple: false,
    onStart(file) {
      setthreeDFiles(file);
    },
    onSuccess(ret) { },
    onError(err) { },
  };

  //   useEffect(()=>{
  //     if(open === null){
  //       setImage([]);
  //       setPreviewImages([]);
  //       setInput({...inputs, dimensions: {...inputs.dimensions, l: 0, b:0, h: 0}, price: 0 })
  //     }
  //   },[open])

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  function ImgComponent({ src, alt, classId }) {
    return (
      <div className={'d-flex align-items-center justify-content-center mt-3'}>
        <img src={src} alt={alt} style={{ width: '17vw', height: '24vh', margin: '-15px', border: '50%' }} />
        <p>{alt}</p>
      </div>
    );
  }


  const handleDelete = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await axios
        .put(`${baseUrl}/product/delete`, {
          _id: product._id,
        }, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
      setLoading(false);
      fetchProducts(auth.token);
      setOpen({ ...open, bool: '', product: null });
      setSuccessAlert(true);
      window.setTimeout(() => {
        setSuccessAlert(false);
      }, 3000)
      fetchProducts(auth.token);
    }
    catch (err) {
      setErrorAlert(true);
      window.setTimeout(() => {
        setErrorAlert(false);
      }, 1000)
    }
  }

  // carousel data iterator
  const ImgDataIterator = previewImages.map((d) => ({ ...d, src: d.previewUrl })).map((element) => (
    <ImgComponent src={element.src} alt={element.alt} />
  ));



  const handleConfirm = () => {
    setOpen({ ...open, bool: "CONFIRM" });
  }


  return (
    <div>
      <Modal
        open={open?.bool === "EDIT"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal, "mui-modal")}
      >
        <form className={classes.paper1} onSubmit={handleConfirm} autoComplete="on"  >
          <div className="form-group">
            <div className={style.APHeader}>
              <h5 className="font-weight-700">Edit Product</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p onClick={() => setOpen({ ...open, bool: 'DELETE' })}><a style={{ color: '#963240', textDecoration: 'underline', fontWeight: 700 }}>Delete product</a></p>
                <i class="far fa-times-circle ml-4" onClick={() => setOpen({ ...open, bool: "" })} ></i>
              </div>
            </div>
            <div className="row">
              <div className="col-4 mt-2">
                <div style={{ border: '2px solid #d9d9d9', borderRadius: '0px', height: '27vh' }}>
                  {previewImages.length === 0 ? <Dropzone
                    PreviewComponent={Preview}
                    inputContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          fontFamily: "Open Sans",
                          color: "black",
                        }}
                      >
                        <p style={{ fontSize: "12px", display: "contents" }}>

                          <img alt='' style={{ width: "70%" }} src={UPLOAD} />
                          <u>Upload Images</u>
                        </p>
                      </div>
                    }
                    name="images"
                    onChangeStatus={handleChangeStatus}
                    maxFiles={10}
                    multiple={true}
                    accept=".png, .jpeg, .jpg"
                    canCancel={false}
                  />
                    :
                    <AliceCarousel
                      mouseTracking
                      items={ImgDataIterator}
                      responsive={responsive}
                      controlsStrategy="alternate"
                    />}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <span style={{ fontSize: '0.8rem', color: '#1B74B9', cursor: 'pointer' }} onClick={() => { setImage([]); setPreviewImages([]) }}><u>Remove background</u></span>
                  <span style={{ fontSize: '0.8rem', color: '#1B74B9', cursor: 'pointer' }} onClick={() => { setImage([]); setPreviewImages([]) }}><u>Re-Upload</u></span>
                </div>
              </div>


              <div className="col-8 d-flex justify-content-center align-items-start flex-column w-100">
                {/* <div className="d-flex flex-row">
                  <p style={{ width: "100%" }} className={style.APLabel}>
                    Product Name*
                  </p>
                  <input
                    type="text"
                    placeholder="Product Name"
                    className={style.APInput}
                    name="name"
                    value={inputs?.name}
                    required
                    onChange={handleChange}
                  />
                </div>


                <div className="d-flex flex-column w-100 mt-3">
                  <p className={style.APLabel}>Product Description*</p>
                  <textarea
                    className={style.APTextArea}
                    name="description"
                    value={inputs.description}
                    required
                    onChange={handleChange}
                  ></textarea>
                </div> */}


                <div className="col-9 d-flex justify-content-center align-items-start flex-column w-100">
                  <div className="d-flex flex-row">
                    <div className='col-6'>
                      <p className={style.APLabel}>Product ID</p>
                    </div>
                    <div className='col-6'>
                      <p className={style.APLabelStatic}>
                        <input
                          type="text"
                          style={{ marginLeft: 10, border: 0 }}
                          value={inputs.product_id}
                          placeholder="Product SKU"
                          required
                          className={style.APInput}
                          name="product_id"
                          disabled={true}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row mt-2">
                    <div className='col-6'>
                      <p className={style.APLabel}>Product SKU*</p>
                    </div>
                    <div className='col-6'>
                      <input
                        type="text"
                        style={{ marginLeft: 10 }}
                        value={inputs?.sku}
                        placeholder="Product SKU"
                        required
                        className={style.APInput}
                        name="sku"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row mt-2">
                    <div className='col-6'>
                      <p className={style.APLabel}>
                        Product Name*
                      </p>
                    </div>
                    <div className='col-6'>
                      <input
                        type="text"
                        placeholder="Product Name"
                        className={style.APInput}
                        name="name"
                        value={inputs?.name}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row mt-2">
                    <div className='col-6'>
                      <p className={style.APLabel}>HSN Code*</p>
                    </div>
                    <div className='col-6'>
                      <input
                        type="text"
                        placeholder="ad123"
                        className={style.APInput}
                        style={{ marginLeft: 15 }}
                        name="hsn_code"
                        required
                        value={inputs.hsn_code}
                        onChange={(e) => { setInput({ ...inputs, hsn_code: e.target.value }) }}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-column w-100 mt-2 ml-3">
                    <p className={style.APLabel}>Product Description*</p>
                    <textarea
                      className={style.APTextArea}
                      name="description"
                      required
                      value={inputs.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4">



                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>MRP*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="Regular price"
                      className={style.APInput}
                      required
                      value={inputs.price}
                      name="price"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Sale %*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="13%"
                      className={style.APInput}
                      name="sales"
                      required
                      value={inputs.sales}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>Affilation %</p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>{(vendorData?.affiliation_rate || 5)}%</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>Cost Price (Price to P&M)</p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ? inputs?.price - inputs?.price * ((vendorData?.affiliation_rate || 5) / 100)
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>
                      Pixel Price(As Viewed on Website)
                    </p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ?

                        (inputs?.price - ((inputs.price * ((vendorData?.affiliation_rate || 5) / 100)) / 2))

                        : 0}
                    </p>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>GST Tax Rate*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Tax Slab"
                      className={style.APInput}
                      required
                      name="tax_slab"
                      onChange={handleChange}
                      value={inputs.tax_slab}

                    >
                      <option value={""}>Select the tax Slab</option>
                      {TAX_SLAB_ARRAY.map((d) => <option value={d}>{d}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-4">


                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Room Type*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <ReactSelect
                      options={[
                        ...rooms.map((d) => ({ ...d, label: d, value: d })),
                      ]}
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      required
                      defaultValue={inputs.rooms?.map((d) => ({ ...d, label: d, value: d })) || []}
                      isMulti
                      // handleChange={}
                      // components={{ DropdownIndicator }}
                      onChange={(e) => {
                        const data = e.map(data => data.value)
                        setInput({ ...inputs, room_type: data })
                      }}

                      components={{ LoadingIndicator: null }}
                      isClearable={false}
                      hideSelectedOptions={false}
                      placeholder={<p style={{ textAlign: 'center', width: '100%' }}> Select room type </p>}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Material*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <ReactSelect
                      options={[
                        ...material.map((d) => ({ ...d, label: d, value: d })),
                      ]}
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      required
                      // ref={component => {
                      //   this.node = component;
                      // }}
                      isMulti
                      // handleChange={}
                      // components={{ DropdownIndicator }}
                      defaultValue={inputs.material?.map((d) => ({ ...d, label: d, value: d })) || []}
                      components={{ LoadingIndicator: null }}
                      onChange={(e) => {
                        const data = e.map(data => data.value)
                        setInput({ ...inputs, material: data })
                      }}
                      isClearable={false}
                      hideSelectedOptions={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <img style={{ position: 'absolute', right: 0, top: 13 }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Color*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      name="color"
                      value={inputs.color}
                      onChange={handleChange}
                    >
                      {color.map((color) => <option value={color}> {color} </option>)}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13 }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Style*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      value={inputs.style}
                      name="style"
                      onChange={handleChange}
                    >
                      {styleArr.map((style) => (
                        <option value={style}> {style} </option>
                      ))}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Pattern*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      value={inputs.pattern}
                      name="pattern"
                      onChange={handleChange}
                    >
                      {pattern.map((pattern) => (
                        <option value={pattern}> {pattern} </option>
                      ))}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Stock  status (no of item in stock)*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(205, 198, 195, 1)'
                      }}
                    >
                      <input
                        type="number"
                        placeholder="13"
                        className={style.APInputWithoutBorder}
                        name="stock"
                        required
                        value={inputs.stock}
                        onChange={(e) => setInput({ ...inputs, stock: e.target.value })}
                      />
                      <span
                        style={{ fontSize: '12px', color: '#CDC6C3' }}
                      >Items</span>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-4">
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Category*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Product ID"
                      className={style.APInput}
                      required
                      name="category"
                      onChange={handleCategory}
                      value={inputs.category}
                    >
                      <option value={"0"}>Select the category</option>
                      {categories.map((d) => <option value={d.id}>{d.id}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>SubCategory*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Product ID"
                      className={style.APInput}
                      required
                      name="subcategory"
                      onChange={handleChange}
                      value={inputs.subcategory}
                    >
                      <option value={"0"}>Select the subcategory</option>
                      {subCategories.map((d) => <option value={d.id}>{d.id}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Dimensions*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      placeholder="Product ID"
                      style={{ textAlignLast: 'center' }}
                      className={style.APInput}
                      name="dimensions"
                      required
                      value={inputs.dimensions?.unit}
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            unit: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={"F&I"}>Feet & Inches</option>
                      <option value={"MM"}>Milimeters</option>
                      <option value={"M"}>Meters</option>
                    </select>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-1">
                    <p className={style.APLabel}>L*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="l"
                      required
                      value={inputs.dimensions.l}
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, l: e.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>B*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      value={inputs.dimensions.b}
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="b"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, b: e.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>H*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      placeholder={
                        inputs?.dimensions?.unit === "F&I"
                          ? '0"'
                          : inputs?.dimensions?.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      value={inputs.dimensions.h}
                      name="h"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, h: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>As Viewed</p>
                  </div>
                  <div className="col-7">
                    <p className={style.APLabelStatic}>
                      {convert(parseInt(inputs?.dimensions?.l)).from(inputs?.dimensions?.unit === 'F&I' ? 'in' : inputs?.dimensions?.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      {" "}x{" "}

                      {convert(parseInt(inputs?.dimensions?.b)).from(inputs?.dimensions?.unit === 'F&I' ? 'in' : inputs?.dimensions?.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      {" "}x{" "}
                      {convert(parseInt(inputs?.dimensions?.h)).from(inputs?.dimensions?.unit === 'F&I' ? 'in' : inputs?.dimensions?.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      &nbsp;mm
                    </p>
                  </div>
                </div>

              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="d-flex justify-content-between align-items-center w-80">
                <p style={{ fontSize: "12px" }}>
                  <u onClick={handlecart} style={{ cursor: "pointer" }}>
                    Preview Product card as in MX
                  </u>
                </p>
                <h5 className="mt-0 ml-5">
                  {(!threeDFiles && !threeDModel) ? (
                    <Upload {...props}>
                      <a style={{ color: "rgba(27, 116, 185, 1)", fontSize: "18px", fontWeight: 700 }}>
                        <u>UPLOAD 3D</u>
                      </a>
                    </Upload>
                  ) : (
                    <div
                      className="d-flex justify-content-start align-items-center flex-row mt-4"
                      style={{ fontSize: "12px" }}
                    >
                      <p> {threeDFiles ? threeDFiles?.name : threeDModel} </p>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <p style={{ color: "#147C50" }}>Uploaded</p>
                      &nbsp;&nbsp;&nbsp;
                      <Upload {...props}>
                        <a style={{ color: "#1B74B9" }}>
                          <u>Re-Upload</u>
                        </a>
                      </Upload>
                    </div>
                  )}
                </h5>
              </div>
              <button
                className={classNames(style.blackButton, " ")}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        open={start}
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        style={{ zIndex: 1000000 }}
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper3}>
          <div
            style={{ paddingTop: "30px", paddingBottom: "50px" }}
            className={style.middle}
          >
            <div className={classNames(style.APBody2, "mt-4")}>
              <div className={'d-flex w-100 justify-content-end align-item-center'}>
                <i class="far fa-times-circle" icon={faTimesCircle} onClick={close} style={{ marginTop: '-20px' }}  ></i>
              </div>
              <div style={{ maxWidth: '250px' }}>
                <img
                  alt=''
                  style={{ maxWidth: '21vw', height: '21vh', width: '100%' }}
                  src={previewImages[0]?.previewUrl}
                />
              </div>
              <p
                style={{
                  // paddingLeft: "14px",
                  paddingTop: "30px",
                  fontWeight: 100,
                }}
              >
                {vendorData?.name}
              </p>
              <h5
                style={{ paddingLeft: "0px", paddingTop: "5px", fontFamily: 'Poppins' }}
                className="font-weight-500"
              >
                {inputs.name}
              </h5>
              <p style={{ marginTop: "5px" }}>INR {inputs.price}</p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={open?.bool === "CONFIRM"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal1, "mui-modal")}
      >
        <form className={classes.paper4} onSubmit={handledata} autoComplete="on" >
          <h6>Are you sure you want to save the changes ?</h6>
          <div className="d-flex flex-row">

            <button
              className={classNames(style.blackYesButton)}
              type="submit"
              disabled={loading}
              style={{ opacity: loading ? 0.5 : 1 }}

            >
              {loading ? 'Loading..' : 'Yes'}
            </button>
            <button
              className={classNames(style.whiteNoButton)}
              type="button"
              onClick={() => {
                setOpen({ ...open, bool: '' })
                setLoading(false)
              }}
            >
              No
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        open={open?.bool === "DELETE"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal1, "mui-modal")}
      >
        <form className={classes.paper4} onSubmit={handleDelete} autoComplete="on" >
          <h6>Are you sure you want to delete ?</h6>
          <div className="d-flex flex-row">

            <button
              className={classNames(style.blackYesButton)}
              type="submit"
              disabled={loading}
              style={{ opacity: loading ? 0.5 : 1 }}

            >
              {loading ? 'Loading..' : 'Yes'}
            </button>
            <button
              className={classNames(style.whiteNoButton)}
              type="button"
              onClick={() => {
                setOpen({ ...open, bool: '' })
                setLoading(false)
              }}
            >
              No
            </button>
          </div>
        </form>
      </Modal>
      {successAlert && (
        <div
          style={{ position: "fixed", top: "-1.5rem", width: "90vw", right: 0 }}
        >
          <Alertpage title={"Product Updated"} />
        </div>
      )}
      {errorAlert && (
        <div
          style={{ position: "fixed", top: "-1.5rem", width: "90vw", right: 0 }}
        >
          <Alertpage title={"SOmethinf went wrong"} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.orders.product,
});

export default connect(mapStateToProps, { fetchProducts })(withRouter(EditProduct));

const material = [
  "Wood",
  "Silver Metal",
  "Rattan",
  "Marble",
  "Modern Wood",
  "Leather",
  "Jute",
  "Inlay",
  "Gold",
  "Glass",
  "Fabric",
  "Concrete",
  "Brass",
  "Bamboo",
  "Black Metal",
];

const color = [
  "Choose color",
  "White",
  "Grey",
  "Black",
  "Brown",
  "Beige",
  "Yellow",
  "Orange",
  "Red",
  "Pink",
  "Magenta/Burgundy",
  "Purple",
  "Blue",
  "Aqua/Teal",
  "Green",
  "Rust",
  "Multicolor",
  "Gold"
];
const styleArr = [
  "North Indian",
  "South Indian",
  "Classic Indian",
  "Minimalistic",
  "Contemporary",
  "Mid Century modern",
  "Eclectic",
  "Pop Art",
  "Art Deco",
  "Industrial",
  "Gothic",
  "Rustic",
  "Farm House",
  "Boho",
  "Coastal",
];

const pattern = [
  "Animal Prints",
  "Indian Prints",
  "Abstract",
  "Argyle",
  "Chevron",
  "Floral",
  "Geometric",
  "Intricate",
  "Moroccon",
  'Solids'
];

const rooms = [
  "Living Room",
  "Dining Room",
  "Bed Room",
  "Kids Room",
  "Puja Room",
  "Foyer",
  "Balcony",
  "Study Entertainment Room",
  "Others",
];

// const category_data = [
//   "Armchairs",
//   "Bed",
//   "Side Tables",
//   "Rugs"
// ];


const category_data =
  [
    { id: 'Bed', introline: 'Bed' },
    { id: 'Decor', introline: 'Decor' },
    { id: 'Dining Table', introline: 'Dining Table' },
    { id: 'Lighting', introline: 'Lighting' },
    { id: 'Mattress', introline: 'Mattress' },
    { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
    { id: 'Seating', introline: 'Seating' },
    { id: 'Sofa', introline: 'Sofa' },
    { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
    { id: 'Storage', introline: 'Storage' },
    { id: 'Tables', introline: 'Tables' },
    { id: 'Wall Coverings', introline: 'Wall Coverings' },
    { id: 'Wardrobes', introline: 'Wardrobes' }
  ]


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 0,
    textAlign: "center",
    fontSize: "12px",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    borderBottom: "1px solid rgba(205, 198, 195, 1)",
    height: "30px",
    textAlign: "center",
    overflowY: "auto",
  }),

  dropdownIndicator: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
    "& svg": { display: "none" },
  }),

  dropdownIndicators: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: "center",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
  placeholder: () => ({
    fontSize: "12px",
    padding: "0px",
    textAlign: "center",
  }),

  multiValue: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 100,
    background: "#7BC0D3",
    textAlign: "center",
    color: "#4DA9FF",
    display: "flex",
    fontSize: "12px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#FFFFFF",
    textAlign: "center",
    maxWidth: "120px",
    paddingRight: "6px",
    fontSize: "10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000",
    display: "none",
    ":hover": {
      color: "white",
      fontSize: "12px",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
};
