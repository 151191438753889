import React, { useEffect, useState } from 'react';
import '../../assets/css/style.css'
import { Card, Col, Row, Alert } from 'react-bootstrap';
import style from './newOrders.module.css';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { fetch_orders } from 'store/actions/ordersActions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from 'config/config';
import { YellowInfo } from 'assets/icons';
import { InStockButton } from 'components';
import { OutOfStockButton } from 'components';
import Alertpage from 'views/Orders/Alertpage';


const NOrders = ({ orders, products, token }) => {
  const history = useHistory()
  const [alert, setAlert] = useState(false);

  const columns = [
    { text: 'S.No', width: '5%' },
    { text: 'Product ID', width: '10%' },
    { text: 'Product', width: '10%' },
    { text: 'Stock Status', width: '10%' },
    { text: 'Delivery Region', width: '10%' },
    { text: 'Num.of Product', width: '10%' },
    { text: 'Order Value', width: '10%' },
    { text: 'Shipping Value', width: '10%' }
  ]

  useEffect(() => {
    fetch_orders(token);
  }, [token])


  const getStockStatus = (id) => {
    const arr = products.filter((d) => id.includes(d._id));
    let status = true;
    for (var i = 0; i < arr?.length; i++) {
      if (arr[i].stock === 0) {
        return status === false;
      }
    }
    return status;
  };

  const getProductName = (id) => {
    const arr = products?.filter((d) => id.map((e) => e === d._id))[0]?.name;
    return arr;
  }
  const handlegoto = () => {
    history.push("/orders")
  }

  const acceptOrder = async (id) => {
    try {
      await axios.get(`${baseUrl}/order/accept/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetch_orders(token);
      setAlert(true);
      window.setTimeout(() => {
        setAlert(false);
      }, 10000)
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <Row>
        <Col sm={12} className="d-flex align-items-center">
          <h2 className={classnames("font-weight-700", style.header)}>New Orders ({(orders?.filter((d) => d.status === 'NEW')?.length) <= 9 && '0'}{orders?.filter((d) => d.status === 'NEW')?.length})</h2>
          <Alert className={style.alertContainerYellow}>
            <img alt='' className="mr-2" src={YellowInfo} />   You Received New orders will be fullfilled ,unnoticed orders will be auto-cancel in 24 hrs
          </Alert>
          <Col className="d-flex justify-content-end align-items-end">
            <p className={style.gotoOrders}><u style={{ cursor: 'pointer' }} onClick={handlegoto}>Go to New Orders</u></p>
          </Col>
        </Col>
      </Row>
      <br />
      <table className={classnames("mb-2 w-100 mb-3", style.columntable)}>
        <tr style={{ textAlign: 'center', borderRadius: '8px', backgroundColor: '#CDC6C3', fontWeight: 700 }}>
          {columns.map((d) => <td style={{ padding: '10px', width: d.width, fontWeight: 700 }} > {d.text} </td>)}
        </tr>
      </table>

      <table className={style.table}>
        {orders.filter((d) => d.status === 'NEW').length === 0 ? (
          <Card className="card-white d-flex align-items-center justify-content-center" style={{ borderRadius: "8px", height: '320px' }}>
            <div className={style.noorder} style={{ textAlign: "center" }}>
              <h2>No New Orders</h2>
              <h4>0 Products are live in Mx Moodboard </h4>
            </div>
          </Card>
        ) : (orders.filter((d) => d.status === 'NEW').map((f, i) =>

          <div style={{ backgroundColor: 'white', borderRadius: '8px', display: 'table', width: '100%', marginBottom: '16px' }}>

            <Card style={{ borderRadius: '8px', display: 'table', width: '100%', marginBottom: '10px', backgroundColor: 'white' }}>
              <tr style={{ textAlign: 'center', fontSize: '1.5rem', verticalAlign: 'initial', fontWeight: 100 }} className={style.tabletr}>

                <td style={{ textAlign: 'center', width: '5%', padding: '10px', position: 'relative' }}>
                  {(i + 1) <= 9 && '0'}{i + 1}

                </td>
                <td className={style.tableContenttd} style={{ width: '10%' }}>{f.order_id}</td>
                <td className={style.tableContenttd} style={{ width: '10%' }}>{getProductName(f.product)}</td>
                <td className={style.tableContenttd} style={{ width: '10%' }}>
                  <div className="d-flex justify-content-center align-items-center">
                    {getStockStatus(f.product, i) ? <InStockButton /> : <OutOfStockButton />}
                  </div>
                </td>
                <td className={style.tableContenttd} style={{ width: '10%' }}>{f.delivery_region}</td>
                <td className={style.tableContenttd} style={{ width: '10%' }}>{(f.product?.length) <= 9 && '0'}{f.product?.length}</td>
                <td className={style.tableContenttd} style={{ width: '10%', position: 'relative' }}>
                  <p>₹ {f.order_value}</p>
                </td>
                <td className={style.tableContenttd} style={{ width: '10%', position: 'relative' }}>
                  <p>₹ {f.shipping_charge}</p>
                </td>
              </tr>

            </Card>
            <div className="w-100 d-flex justify-content-end align-items-center mb-4 pr-4">
              <button type="button" className={classnames(style.declinebutton, 'mr-5')} >Decline</button>
              <button type="button" onClick={() => acceptOrder(f._id)} className={style.acceptbutton}>Accept</button>
            </div>
          </div>
        ))}
      </table>

      {alert && (
        <div
          className={style.animation}
          style={{
            position: "fixed",
            top: "3rem",
            width: "100%",
            zIndex: 100,
            left: '-0.0%'
          }}
        >
          <Alertpage />
        </div>
      )}

    </div>
  )
}

const mapsStateToProps = state => ({
  orders: state.orders.orders,
  products: state.orders.products,
  token: state.auth.token
})

export default connect(mapsStateToProps)(withRouter(NOrders));