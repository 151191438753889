/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import ReactSelect from "react-select";
import { fetchProducts } from "../../store/actions/productActions";
import style from "./ProductCatalogue.module.css";
import classNames from "classnames";
import axios from "axios";
import Upload from "rc-upload";
import "../../assets/css/style.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { baseUrl } from "config/config";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-awesome-slider/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import convert from 'convert-units';
import UPLOAD from "../../assets/img/UPLOAD.PNG";
import { TAX_SLAB_ARRAY } from "./constants";
import { Tags } from "utils/Tags";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 4000,
    overflow: 'scroll',

  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 5),
    height: "auto",
    paddingTop: '20px'
  },
  paper3: {
    position: "absolute",
    width: 327,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: 400,
    border: 0,
  },
}));

const AddProductModal = ({
  open,
  setOpen,
  setactiveProducts,
  auth,
  products,
  setModal,
  setSuccessAlert,
  vendorData
}) => {
  const classes = useStyles();
 
  const [previewImages, setPreviewImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories,setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const Preview = () => {
    return (
      <span
        style={{
          alignSelf: "flex-start",
          margin: "10px 3%",
          fontFamily: "Open Sans",
        }}
      >
        <Carousel
          autoFocus={true}
          axis={"horizontal"}
          centerMode={true}
          showThumbs={false}
        >
          {previewImages.map((d) => (
            <img src={d.previewUrl} alt={d.id} style={{ width: "100%" }} />
          ))}
        </Carousel>
      </span>
    );
  };

  const [inputs, setInput] = React.useState({
    dimensions: {
      unit: "F&I"
    }
  });

  const [loading, setLoading] = useState(false);

  const handleCategory = (e, category) => {
    const {name, value} = e.target;
    setInput({...inputs, [name]: value})
    const tempCat = tags.find((tag) => tag.name === category)
    const subcategory = tempCat.subcat
    let subcats = []
    for(const subcat of subcategory){
      subcats.push({id:subcat.name, introline:subcat.name})
    }
    // console.log(subcats);
    setSubCategories(subcats)
  }
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInput({
      ...inputs,
      [name]: value,
      status: false,
      // stock: 10,
      user_id: "",
      SKU: "",
    });
  };

  const getSubCat = async() => {
    const tags = await Tags()
    setTags(tags)
    let categorylist = []
    for (const category of tags){
      categorylist.push({id:category.name, introline:category.name})
    }
    setCategories(categorylist)
  }
  // console.log(categories);

  useEffect(() => {
    getSubCat()
  },[])

  const [errors, setErrors] = useState(true);

  const handledata = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (image?.length === 0) return alert('Image required.')
      if (inputs?.rooms.length === 0) return alert('Rooms required.')
      if (inputs.material?.length === 0) return alert('Material required.')
      if (!inputs.color) return alert('Color required.')
      if (!inputs.style) return alert('Style required.')
      if (!inputs.category) return alert('Category required.')
      if (!inputs.subcategory) return alert('Subcategory required')
      if (!inputs.pattern) return alert('Pattern required.')

      const fd = new FormData();
      fd.append("name", inputs.name);
      fd.append("price", inputs.price);
      fd.append("description", inputs.description);
      fd.append("SKU", inputs.sku);
      fd.append("category", inputs.category);
      fd.append("subcategory", inputs.subcategory)
      fd.append("stock", inputs.stock);
      fd.append("vendor_id", auth.vendor._id);
      fd.append("status", false);
      fd.append("cost_price", Math.round(inputs?.price - inputs?.price * ((vendorData.affiliation_rate || 5) / 100)))
      fd.append("pixel_price", Math.round(inputs?.price - ((inputs.price * ((vendorData.affiliation_rate || 5) / 100)) / 2)))
      fd.append(
        "room_type",
        JSON.stringify(inputs.rooms?.map((d) => d.value)));
      fd.append(
        "material",
        JSON.stringify(inputs.material?.map((d) => d.value)));
      fd.append("sales", inputs.sales);
      fd.append("dimensions", JSON.stringify(inputs.dimensions));
      fd.append(
        "style",
        inputs.style
      );
      fd.append(
        "pattern",
        inputs?.pattern
      );
      fd.append(
        "color",
        inputs.color
      );
      fd.append("hsn_code", inputs.hsn_code);
      fd.append("tax_slab", inputs.tax_slab);
      for (var i = 0; i < image.length; i++) {
        fd.append("image", image[i]);
      }

      await axios
        .post(`${baseUrl}/product/create`, fd, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then(async (response) => {
          if (Object.keys(response).length) {
            // console.log("going here as well", response)
            if (threeDFiles) {
              const fd1 = new FormData();
              fd1.append("id", response.data.newproduct._id);
              fd1.append("3d-models", threeDFiles)
              const res1 = await axios.put(`${baseUrl}/product/3d-models`, fd1, {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                },
              })
              setModal(null);
              setthreeDFiles(null)
              // fetchProducts(auth.token);
              setLoading(false);
              setSuccessAlert(true);
              response?.data?.newproduct && setactiveProducts(prev => [...prev, { ...response.data.newproduct, ...res1.data["3d_models"] }])
              window.setTimeout(() => {
                // setactiveProducts(products)
                setSuccessAlert(false);
              }, 3000);
            } else {
              setModal(null);
              // fetchProducts(auth.token);
              setLoading(false);
              setSuccessAlert(true);
              response?.data?.newproduct && setactiveProducts(prev => [...prev, response.data.newproduct])
              window.setTimeout(() => {
                // setactiveProducts(products)
                setSuccessAlert(false);
              }, 3000);
            }
          }
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const [image, setImage] = React.useState([]);

  const handleChangeStatus = ({ meta, file }, status) => {

    // if(meta.width < 300 || meta.height <300) return alert('Please Upload image between 300 x 200')
    if (status === "done") {
      if (!image.some((el) => file.name === el.name)) {
        setImage((prev) => [...prev, file]);
        setPreviewImages((prev) => [...prev, meta]);
      } else {
        setImage(image.filter((d) => d.name !== file.name));
        setPreviewImages(previewImages.filter((d) => d.id !== meta.id));
      }
    } else if (status === "aborted") {

    }
  };
  const [start, setStart] = React.useState(null);
  const handlecart = () => {
    setStart(true);
  };
  const close = () => {
    setStart(null);
  };

  const [threeDFiles, setthreeDFiles] = useState(null);

  const props = {
    accept: ".glb, .usdz",
    multiple: false,
    onStart(file) {
      setthreeDFiles(file);
    },
    onSuccess(ret) {

    },
    onError(err) {

    },
  };

  useEffect(() => {
    if (open === null) {
      setImage([]);
      setPreviewImages([]);
      setInput({ ...inputs, dimensions: { ...inputs.dimensions, l: 0, b: 0, h: 0 }, price: 0 })
    }
  }, [open])

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  function ImgComponent({ src, alt, classId }) {
    return (
      <div className={'d-flex align-items-center justify-content-center mt-3'}>
        <div style={{ maxWidth: '17vw', maxHeight: '24vh', overflow: 'hidden' }}>
          <img src={src} alt={alt} style={{ width: 'auto', height: '24vh', margin: '-15px', border: '50%' }} />
        </div>
        <p>{alt}</p>
      </div>
    );
  }

  // carousel data iterator
  const ImgDataIterator = previewImages.map((d) => ({ ...d, src: d.previewUrl })).map((element) => (
    <ImgComponent src={element.src} alt={element.alt} />
  ));

  useEffect(() => {
    if (image.length === 0) {
      return setErrors(true);
    } else
      if (Object.keys(inputs).length < 15) {
        return setErrors(true);
      }
      else
        if (Object.keys(inputs?.dimensions).length < 4) {
          return setErrors(true);
        }
        else
          if (threeDFiles === null) {
            return setErrors(false);
          }
          else {
            setErrors(false)
          }
  }, [inputs, image, threeDFiles])

  return (
    <div>
      <Modal
        open={open === "ADD_B"}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classNames(classes.modal, 'mui-modal')}
      >
        <form className={classes.paper1} onSubmit={handledata} autoComplete="on"  >
          <div className="form-group">
            <div className={style.APHeader}>
              <h5 className="font-weight-700">Add Product</h5>
              {/* <FontAwesomeIcon
              icon={faTimesCircle}
            
            /> */}
              <i class="far fa-times-circle fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setLoading(false); setOpen(null) }} ></i>
            </div>
            <div className="row">
              <div className="col-3">
                <div style={{ border: '2px solid #d9d9d9', borderRadius: '0px', height: '30vh', width: '16vw', marginTop: 15 }}>
                  {image.length === 0 ? <Dropzone
                    PreviewComponent={Preview}
                    inputContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: '85%',
                          flexDirection: "column",
                          fontFamily: "Open Sans",
                          color: "black",
                          height: '200px'
                        }}
                      >
                        <p style={{ fontSize: "12px", display: "contents" }}>

                          <img alt='' style={{ width: "30%" }} src={UPLOAD} />
                          <u>Upload Images</u>
                        </p>
                      </div>
                    }
                    name="images"
                    // onChange={handleImage}
                    // onSubmit={(e) => console.log(e)}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={10}
                    multiple={true}
                    accept=".png, .jpeg, .jpg"
                    canCancel={false}
                  />
                    :
                    <AliceCarousel
                      mouseTracking
                      items={ImgDataIterator}
                      responsive={responsive}
                      controlsStrategy="alternate"
                    />}
                </div>
                <span style={{ fontSize: '0.8rem', color: '#1B74B9', cursor: 'pointer' }} onClick={() => { setImage([]); setPreviewImages([]) }}><u>Remove background</u></span>
              </div>
              <div className="col-9 d-flex justify-content-center align-items-start flex-column w-100">
                <div className="d-flex flex-row">
                  <div className='col-6'>
                    <p className={style.APLabel}>Product SKU*</p>
                  </div>
                  <div className='col-6'>

                    <input
                      type="text"
                      style={{ marginLeft: 10 }}

                      placeholder="Product ID"
                      required
                      className={style.APInput}
                      name="sku"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mt-2">
                  <div className='col-6'>
                    <p className={style.APLabel}>
                      Product Name*
                    </p>
                  </div>
                  <div className='col-6'>
                    <input
                      type="text"
                      placeholder="Product Name"
                      className={style.APInput}
                      name="name"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row mt-2">
                  <div className='col-6'>
                    <p className={style.APLabel}>HSN Code*</p>
                  </div>
                  <div className='col-6'>
                    <input
                      type="text"
                      placeholder="ad123"
                      className={style.APInput}
                      style={{ marginLeft: 15 }}
                      name="hsn_code"
                      required
                      value={inputs.hsn_code}
                      onChange={(e) => { setInput({ ...inputs, hsn_code: e.target.value }) }}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column w-100 mt-2 ml-3">
                  <p className={style.APLabel}>Product Description*</p>
                  <textarea
                    className={style.APTextArea}
                    name="description"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4">


                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>MRP*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="Regular price"
                      className={style.APInput}
                      required
                      name="price"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Sale %*</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="number"
                      placeholder="13%"
                      className={style.APInput}
                      name="sales"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>Affilation %</p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>{vendorData.affiliation_rate || 5}%</p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>Cost Price (Price to P&M)</p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ? inputs?.price - inputs?.price * ((vendorData.affiliation_rate || 5) / 100)
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-5">
                    <p className={style.APLabel}>
                      Pixel Price(As Viewed on Website)
                    </p>
                  </div>
                  <div className="col-5">
                    <p className={style.APLabelStatic}>
                      INR{" "}
                      {inputs.price
                        ?
                        (inputs?.price - ((inputs.price * ((vendorData.affiliation_rate || 5) / 100)) / 2))
                        : 0}
                    </p>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>GST Tax Rate*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Tax Slab"
                      className={style.APInput}
                      required
                      name="tax_slab"
                      onChange={handleChange}
                    >
                      <option value={""}>Select the GST Tax Rate</option>
                      {TAX_SLAB_ARRAY.map((d) => <option value={d}>{d}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-4">


                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Room Type*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <ReactSelect
                      options={[
                        ...rooms.map((d) => ({ ...d, label: d, value: d })),
                      ]}
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      required
                      // value={inputs.rooms?.map((d) => ({ ...d, label: d, value: d })) || []}
                      isMulti
                      // handleChange={}
                      // components={{ DropdownIndicator }}
                      onChange={(e) => setInput({ ...inputs, rooms: e })}
                      components={{ LoadingIndicator: null }}
                      isClearable={false}
                      hideSelectedOptions={false}
                      placeholder={<p style={{ textAlign: 'center', width: '100%' }}> Select room type </p>}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Material*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <ReactSelect
                      options={[
                        ...material.map((d) => ({ ...d, label: d, value: d })),
                      ]}
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      required
                      placeholder="Select material"
                      // ref={component => {
                      //   this.node = component;
                      // }}
                      isMulti
                      // handleChange={}
                      // components={{ DropdownIndicator }}
                      components={{ LoadingIndicator: null }}
                      onChange={(e) => setInput({ ...inputs, material: e })}
                      isClearable={false}
                      hideSelectedOptions={false}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <img style={{ position: 'absolute', right: 0, top: 13 }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Color*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    {/* <ReactSelect
                    options={[
                      ...color.map((d) => ({ ...d, label: d, value: d })),
                    ]}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    required
                    onChange={(e) => setInput({ ...inputs, color: e })}
                    isMulti
                    // handleChange={}
                    // components={{ DropdownIndicator }}
                    components={{ LoadingIndicator: null }}
                    isClearable={false}
                    hideSelectedOptions={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  /> */}
                    <select
                      type="text"
                      placeholder="Product SKU"
                      className={style.APSelect}
                      required
                      name="color"
                      onChange={handleChange}
                    >
                      {color.map((color) => <option value={color}> {color} </option>)}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13 }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Style*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    {/* <ReactSelect
                    options={[
                      ...styleArr.map((d) => ({ ...d, label: d, value: d })),
                    ]}
                    required
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    onChange={(e) => setInput({ ...inputs, style: e })}
                    isMulti
                    // handleChange={}
                    // components={{ DropdownIndicator }}
                    components={{ LoadingIndicator: null }}
                    isClearable={false}
                    hideSelectedOptions={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  /> */}
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      name="style"
                      onChange={handleChange}
                    >
                      {styleArr.map((style) => <option value={style}> {style} </option>)}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Pattern*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    {/* <ReactSelect
                    options={[
                      ...pattern.map((d) => ({ ...d, label: d, value: d })),
                    ]}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    isMulti
                    required
                    // handleChange={}
                    // components={{ DropdownIndicator }}
                    onChange={(e) => setInput({ ...inputs, pattern: e })}
                    components={{ LoadingIndicator: null }}
                    isClearable={false}
                    hideSelectedOptions={false}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  /> */}
                    <select
                      type="text"
                      placeholder="Product ID"
                      className={style.APSelect}
                      required
                      name="pattern"
                      onChange={handleChange}
                    >
                      {pattern.map((pattern) => <option value={pattern}> {pattern} </option>)}
                    </select>
                    <img style={{ position: 'absolute', right: 0, top: 13, }} src={'https://pixel-mortar.s3.ap-south-1.amazonaws.com/vendor-dashboard/Vector+(2).svg'} alt='' />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Stock  status (no of item in stock)*</p>
                  </div>
                  <div className="col-7 p-0" style={{ position: 'relative' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(205, 198, 195, 1)'
                      }}
                    >
                      <input
                        type="number"
                        placeholder="13"
                        className={style.APInputWithoutBorder}
                        name="stock"
                        required
                        value={inputs.stock}
                        onChange={(e) => { e.target.value < 1000 && setInput({ ...inputs, stock: e.target.value }) }}
                      />
                      <span
                        style={{ fontSize: '12px', color: '#CDC6C3' }}
                      >Items</span>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-4">
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Category*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Product ID"
                      className={style.APInput}
                      required
                      name="category"
                      onChange={(e) => handleCategory(e, e.target.value)}
                    >
                      <option value={"0"}>Select the category</option>
                      {categories.map((d) => <option key={d.id} value={d.id}>{d.id}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>SubCategory*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      style={{ textAlignLast: 'center' }}
                      placeholder="Product ID"
                      className={style.APInput}
                      required
                      name="subcategory"
                      onChange={handleChange}
                    >
                      <option value={"0"}>Select the subcategory</option>
                      {subCategories.map((d) => <option value={d.id}>{d.id}</option>)}
                    </select>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>Dimensions*</p>
                  </div>
                  <div className="col-6">
                    <select
                      type="text"
                      placeholder="Product ID"
                      style={{ textAlignLast: 'center' }}
                      className={style.APInput}
                      name="dimensions"
                      required
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: {
                            ...inputs.dimensions,
                            unit: e.target.value,
                          },
                        })
                      }
                    >
                      <option value={"F&I"}>Feet & Inches</option>
                      <option value={"MM"}>Milimeters</option>
                      <option value={"M"}>Meters</option>
                    </select>
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-1">
                    <p className={style.APLabel}>L*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      placeholder={
                        inputs.dimensions.unit === "F&I"
                          ? '0"'
                          : inputs.dimensions.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="l"
                      required
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, l: e.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>B*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      placeholder={
                        inputs.dimensions.unit === "F&I"
                          ? '0"'
                          : inputs.dimensions.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="b"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, b: e.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="col-1">
                    <p className={style.APLabel}>H*</p>
                  </div>
                  <div className="col-2">
                    <input
                      style={{ width: "35px" }}
                      type="number"
                      required
                      placeholder={
                        inputs.dimensions.unit === "F&I"
                          ? '0"'
                          : inputs.dimensions.unit === "MM"
                            ? "0 mm"
                            : "0 m"
                      }
                      className={style.APInput}
                      name="h"
                      onChange={(e) =>
                        setInput({
                          ...inputs,
                          dimensions: { ...inputs.dimensions, h: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-4">
                    <p className={style.APLabel}>As Viewed</p>
                  </div>
                  <div className="col-7">
                    <p className={style.APLabelStatic}>
                      {convert(parseInt(inputs.dimensions.l ? inputs.dimensions.l : 0)).from(inputs.dimensions.unit === 'F&I' ? 'ft' : inputs.dimensions.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      {" "}x{" "}

                      {convert(parseInt(inputs.dimensions.b ? inputs.dimensions.b : 0)).from(inputs.dimensions.unit === 'F&I' ? 'ft' : inputs.dimensions.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      {" "}x{" "}
                      {convert(parseInt(inputs.dimensions.h ? inputs.dimensions.h : 0)).from(inputs.dimensions.unit === 'F&I' ? 'ft' : inputs.dimensions.unit === 'MM' ? 'mm' : 'm').to('mm').toFixed(0)}
                      &nbsp;mm
                    </p>
                  </div>
                </div>




              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center ">
              <div className="d-flex justify-content-between align-items-center w-80">
                <p style={{ fontSize: "12px" }}>
                  <u onClick={handlecart} style={{ cursor: "pointer" }}>
                    Preview Product card as in MX
                  </u>
                </p>
                <h5 className="mt-0 ml-5">
                  {!threeDFiles ? (
                    <Upload {...props}>
                      <a style={{ color: "rgba(27, 116, 185, 1)", fontSize: "18px", fontWeight: 700 }}>
                        <u>UPLOAD 3D</u>
                      </a>
                    </Upload>
                  ) : (
                    <div
                      className="d-flex justify-content-start align-items-center flex-row mt-4"
                      style={{ fontSize: "12px" }}
                    >
                      <p> {threeDFiles && threeDFiles?.name} </p>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <p style={{ color: "#147C50" }}>Uploaded</p>
                      &nbsp;&nbsp;&nbsp;
                      <Upload {...props}>
                        <a style={{ color: "#1B74B9" }}>
                          <u>Re-Upload</u>
                        </a>
                      </Upload>
                    </div>
                  )}
                </h5>
              </div>
              <button
                className={classNames(style.blackButton, " ")}
                type="submit"
                disabled={loading || errors}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: (loading || errors) && 0.5
                }}
              >
                Submit
                {loading && <div class="loader"></div>}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        open={start}
        aria-labelledby="simple-modal-title"
        className={classNames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper3}>

          {/* <div className={style.APHeader2}>
          <i class="far fa-heart"></i> 
        
          </div> */}


          <div
            style={{ paddingTop: "30px", paddingBottom: "50px" }}
            className={style.middle}
          >
            <div className={classNames(style.APBody2, "mt-4")}>
              <div className={'d-flex w-100 justify-content-end align-item-center'}>
                <i class="far fa-times-circle" icon={faTimesCircle} onClick={close} style={{ marginTop: '-20px' }}  ></i>
              </div>
              <div style={{ maxWidth: '250px' }}>
                <img
                  alt=''
                  style={{ maxWidth: '21vw', height: '21vh', width: '100%' }}
                  src={previewImages[0]?.previewUrl}
                />
              </div>
              <p
                style={{
                  // paddingLeft: "14px",
                  paddingTop: "30px",
                  fontWeight: 100,
                }}
              >
                {vendorData.name}


              </p>
              <h5
                style={{ paddingLeft: "0px", paddingTop: "5px", fontFamily: 'Poppins' }}
                className="font-weight-500"
              >
                {inputs.name}
              </h5>
              <p style={{ marginTop: "5px" }}>INR {inputs.price}</p>
            </div>
          </div>
        </div>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.products.products,
});

export default connect(mapStateToProps, { fetchProducts })(withRouter(AddProductModal));

const material = [
  "Wood",
  "Silver Metal",
  "Rattan",
  "Marble",
  "Modern Wood",
  "Leather",
  "Jute",
  "Inlay",
  "Gold",
  "Glass",
  "Fabric",
  "Concrete",
  "Brass",
  "Bamboo",
  "Black Metal",
];

const color = [
  "Choose color",
  "White",
  "Grey",
  "Black",
  "Brown",
  "Beige",
  "Yellow",
  "Orange",
  "Red",
  "Pink",
  "Magenta/Burgundy",
  "Purple",
  "Blue",
  "Aqua/Teal",
  "Green",
  "Rust",
  "Multicolor"
];

const styleArr = [
  "Choose style",
  "North Indian",
  "South Indian",
  "Classic Indian",
  "Minimalistic",
  "Contemporary",
  "Mid Century modern",
  "Eclectic",
  "Pop Art",
  "Art Deco",
  "Industrial",
  "Gothic",
  "Rustic",
  "Farm House",
  "Boho",
  "Coastal",
];

const pattern = [
  "Choose pattern",
  "Animal Prints",
  "Abstract",
  "Indian Prints",
  "Argyle",
  "Chevron",
  "Floral",
  "Geometric",
  "Intricate",
  "Moroccon",
];

const rooms = [
  "Living Room",
  "Dining Room",
  "Bed Room",
  "Kids Room",
  "Puja Room",
  "Foyer",
  "Balcony",
  "Study Entertainment Room",
  "Others",
];

// const category_data = [
//   "Armchairs",
//   "Bed",
//   "Side Tables",
//   "Rugs"
// ];


const category_data =
  
    [
      { id: 'Bed', introline: 'Bed' },
      { id: 'Decor', introline: 'Decor' },
      { id: 'Dining Table', introline: 'Dining Table' },
      { id: 'Lighting', introline: 'Lighting' },
      { id: 'Mattress', introline: 'Mattress' },
      { id: 'Outdoor Furniture', introline: 'Outdoor Furniture' },
      { id: 'Seating', introline: 'Seating' },
      { id: 'Sofa', introline: 'Sofa' },
      { id: 'Soft Furnishings', introline: 'Soft Furnishings' },
      { id: 'Storage', introline: 'Storage' },
      { id: 'Tables', introline: 'Tables' },
      { id: 'Wall Coverings', introline: 'Wall Coverings' },
      { id: 'Wardrobes', introline: 'Wardrobes' }
     ]
  


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 0,
    textAlign: 'center',
    fontSize: "12px",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: '100%',
    borderBottom: "1px solid rgba(205, 198, 195, 1)",
    height: "30px",
    textAlign: 'center',
    overflowY: "auto",
  }),

  dropdownIndicator: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: 'center',
    "& svg": { display: "none" },
  }),

  dropdownIndicators: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
    textAlign: 'center',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
  placeholder: () => ({
    fontSize: "12px",
    padding: "0px",
    textAlign: 'center',
  }),

  multiValue: (base) => ({
    ...base,
    padding: 0,
    borderRadius: 100,
    background: "#7BC0D3",
    textAlign: 'center',
    color: "#4DA9FF",
    display: "flex",
    fontSize: "12px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#FFFFFF",
    textAlign: 'center',
    maxWidth: "120px",
    paddingRight: "6px",
    fontSize: "10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000",
    display: "none",
    ":hover": {
      color: "white",
      fontSize: "12px",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px 6px",
  }),
};