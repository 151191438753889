/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import style from './NotificationPage.module.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';
import { baseUrl } from 'config/config';
import dateFormat from 'dateformat';

const Notification = ({auth}) => {

  const [data, setData] = useState([]);
  const history = useHistory();

  document.body.style.setProperty('background', 'rgb(255,255,255)', 'important');
  useEffect(() => {
   
    (async () => {
      const res = await axios.get(`${baseUrl}/vendor/notification`,{
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setData(res.data)
    })()
  },[])
  


  return (
    <div className={classnames('containter pl-5 pr-5 pt-0 ml-5 mr-5', style.panel)}>
      <div className={classnames('row justify-content-between d-flex w-100')}>
        <div>
          <h2 className="ml-2">   <b>Notification</b></h2>
      
        </div>
        <div>
          <img alt='' onClick={() => history.push('/dashboard')} src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Close+(1).svg" />
        </div>
      </div>
      {data && data?.map((f) => (
        <div className={classnames('row', style.notifcation)}>
          <div className="col-12">
            <div className="row  justify-content-between w-100">
              <div className="col-md-9">
                <div className={classnames('row', style.notifcationtitle)}>
                  <b>{f.title}</b>
                </div>
                <div className={classnames('row m-0',style.notifcationcontent)} >
                  {f.body}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                </div>
              </div>
              <div className={classnames('row', style.notifcationtimestamp)}>
                <p className="text-right"> {dateFormat(f.createdAt, 'h:MM TT, d mmmm yyyy')} </p>          
              </div>
            </div>
          </div>
          <div className={style.divider}></div>
        </div>
      ))}
    
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Notification);
