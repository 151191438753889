import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/style.css";
import './assets/css/common.css';
import AdminLayout from "layouts/Admin.js";
import Login from "components/Login/Login";
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
const App = ({ auth }) => {
  const AuthRoute = ({
    component: Component,
    ...rest
  }) => (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component
            {...props}
          />
        )
      }
    />
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  return (
    <Switch>
      <AuthRoute path="/" exact component={Login} />
      <PrivateRoute path="/" component={AdminLayout} />
    </Switch>
  )
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(App));