import classNames from "classnames";
import React from "react";
import { Card } from "react-bootstrap";
import alert from "../../assets/img/alert.svg";
import style from "./Alert.module.css";

const Alertpage = ({title}) => {
  return (
    <Card className={classNames(style.cardstyle, style.slideBbottom)}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ textAlign: "center", padding: "60px" }}
      >
        <img alt='' style={{ paddingRight: "20px" }} src={alert} />
        <h1> {title || 'Updated'}</h1>
      </div>
    </Card>
  );
};

export default Alertpage;
