/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import style from '../Drawers/Drawer.module.css';
import classnames from 'classnames';
import { baseUrl } from '../../../config/config';
import axios from 'axios';
import { Modal } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { sortDown } from 'assets/icons';
import { sortUp } from 'assets/icons';
import { Badge, Card, Row } from 'reactstrap';
import dateformat from "dateformat";
import Timer from 'components/uiComponents/Timer';
import Ellipsis from 'components/uiComponents/Ellipsis';
const NewOrders = ({
  columns,
  currentOrder,
  NOrders,
  modal,
  classes,
  filteredorders,
  setDowloadfilters,
  products,
  downloadfilters,
  headers,
  searchString,
  NDrawer,
  users,
  token,
  Customer,
  setToggleDrawer,
  setCurrentOrder,
  toggleDrawer,
  setModal,
  currentProducts,
}) => {
  const [sortState, setSortState] = useState({
    order: false,
  });
  const [render, setRender] = useState(false);

  const [orders, setOrders] = useState(NOrders);
  useEffect(() => {
    let updatedOrders = []
    const checkTimeRemaining = async () => {
      await Promise.all(NOrders.map(async (f) => {
        const currentTime = Date.now()
        const orderCreatedAt = new Date(f.order.createdAt).getTime();
        //12 hr in milliseconds is 43200000  for testing use 1 minute(60000) or 5 min(300000)
        const timeLimit = 86400000;
        let timeRemaining = timeLimit - (currentTime - orderCreatedAt);
        if (timeRemaining < 0) {
          // try {
          //   await axios.put(`${baseUrl}/order/update/${f.order._id}`, { status: 'CANCELLED' }, {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     }
          //   });
          // } catch (err) {
          //   console.log(err)
          // }
        } else {
          updatedOrders.push(f)
        }
      }))
    }

    NOrders?.length && checkTimeRemaining()
    setOrders(updatedOrders)
  }, [NOrders])

  const statusColor = {
    "ACCEPTED": 'warning',
    "SHIPPED": 'success',
    "UNACCEPTED": 'danger',
    "ACKNOWLEDGED": "info",
    "NEW": 'primary'
  }
  const handleSort = (name, isDesc) => {
    if (isDesc && name === "order") {
      setSortState({ ...sortState, order: false });
      let arr = NOrders.sort((a, b) =>
        a.totalOrderValue < b.totalOrderValue ? 1 : -1
      );
      setOrders(arr);
    }
    if (!isDesc && name === "order") {
      setSortState({ ...sortState, order: true });
      let arr = NOrders.sort((a, b) =>
        a.totalOrderValue > b.totalOrderValue ? 1 : -1
      );
      setOrders(arr);
    }
  };

  return (
    <>
      <table className="w-100" style={{ fontWeight: 100, borderBottom: '7px  solid #eae8e6', marginBottom: '16px', height: 40 }}>
        <tr
          style={{
            textAlign: "center",
            borderRadius: "8px",
            backgroundColor: "#CDC6C3",
            fontWeight: 100,
            height: '40px',
            paddingTop: '10px',

          }}
        >
          {columns.map((d) => (
            <td style={{ padding: "10px", width: d.width, fontWeight: 700 }}>
              {" "}
              {d.text}{" "}
              {d.text === "Order Value" &&
                (sortState.order ? (
                  <img
                    alt=''
                    src={sortDown}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("order", true)}
                  />
                ) : (
                  <img
                    alt=''
                    src={sortUp}
                    style={{ paddingLeft: '5px' }}
                    onClick={() => handleSort("order", false)}
                  />
                ))}
            </td>
          ))}

        </tr>
      </table>
      {orders?.length === 0 ? (
        <Card className="card-white d-flex align-items-center justify-content-center" style={{ borderRadius: "8px", height: '40vh' }}>
          <div className={style.noorder} style={{ textAlign: "center", opacity: 1 }}>
            <h2 className="no-orders">No New Orders</h2>
          </div>
        </Card>
      ) : (
        <Card className="card-white" style={{ borderRadius: "8px", marginTop: "16px", marginBottom: '46px', paddingBottom: '1%' }}>

          <table className={style.table} style={{ width: '100%', borderCollapse: 'serperate', borderSpacing: '15px' }}>
            {orders?.filter((d) => searchString === "" || (d && d.order.order_id.toLowerCase().startsWith(searchString.toLowerCase()))).map((f, index) => {
              const currentTime = Date.now()
              const orderCreatedAt = new Date(f.order.createdAt).getTime();
              //12 hr in milliseconds is 43200000  for testing use 1 minute(60000) or 5 min(300000)
              const timeLimit = 86400000;
              let timeRemaining = timeLimit - (currentTime - orderCreatedAt);
              if (timeLimit < (currentTime - orderCreatedAt)) {
                timeRemaining = 0
              }
              return (
                <div style={{ width: '100%', }}>
                  {/* <Card style={{ borderRadius: '8px', display: 'table', width: '100%', marginBottom: '10px', backgroundColor: 'white' }}> */}
                  <tr style={{ textAlign: 'center', verticalAlign: 'initial', fontWeight: 100 }}>
                    <td className={style.tableContenttd} style={{ width: '5%' }}>{index + 1}</td>
                    <td className={style.tableContenttd} style={{ width: '10%' }}>{f.order?.order_id || "-"}</td>
                    <td className={style.tableContenttd} style={{ width: '10%' }}>
                      {f.orderItem?.length ? f.orderItem.map(item => <p>{item.order_sub_id}</p>) : "-"}
                      {f.cancelledOrderItems?.length ? f.cancelledOrderItems.map(item => <p>{item.order_sub_id}</p>) : "-"}

                    </td>

                    <td className={style.tableContenttd} style={{ width: '10%' }}>
                      {f.orderItem?.length ? f?.orderItem?.map(orderItem => {
                        return <p>{Ellipsis(orderItem.products?.name || "", 16)} {orderItem.quantity > 1 && `x ${orderItem.quantity}`}</p>
                      }) : null}
                      {f.cancelledOrderItems?.length ? f.cancelledOrderItems.map(orderItem => {
                        return <p>{Ellipsis(orderItem.products?.name || "", 16)} {orderItem.quantity > 1 && `x ${orderItem.quantity}`}</p>
                      }) : null}
                    </td>
                    <td className={style.tableContenttd} style={{ width: '10%' }}>
                      {`${f.address?.address?.city}, ${f.address?.address?.state}`}

                    </td>
                    <td className={style.tableContenttd} style={{ width: '10%' }}>
                      {f.totalQuantity}

                    </td>
                    <td className={style.tableContenttd} style={{ width: '10%', position: 'relative' }}>
                      <p>₹ {f.totalOrderValue}</p>
                    </td>
                    <td className={style.tableContenttd} style={{ width: '10%', position: 'relative' }}>
                      {f.orderItem.map(orderItem => {
                        return <div ><Badge size='small' color={statusColor[orderItem.status]} style={{ padding: 4, }}>{orderItem.status}</Badge></div>
                      })}
                      {f.cancelledOrderItems?.length ? f.cancelledOrderItems.map(orderItem => {
                        return <div ><Badge size='small' color={statusColor[orderItem.status]} style={{ padding: 4, }}>{orderItem.status}</Badge></div>
                      }) : null}

                    </td>
                  </tr>
                  <tr
                    style={{
                      textAlign: "center",
                      verticalAlign: "initial",
                      width: '100%',
                      display: 'inline-table',
                      marginTop: 0,
                      marginBottom: 10,
                      fontWeight: 100,
                    }}>
                    <td style={{ width: "5%", }}></td>
                    <td style={{ width: "10%", }}></td>

                    <td style={{ width: "10%", }}>
                      <div style={{
                        marginTop: '5%'
                      }}>
                        <p><span className='text-muted'>Order Date</span> : {dateformat(f.order.createdAt, "mmm dd yyyy")}
                        </p>
                      </div>
                    </td>
                    <td style={{ width: "10%", }}></td>
                    <td style={{ width: "20%", }}>
                      <div style={{
                        marginTop: '5%'
                      }}>
                        <p className='text-muted'>Time Left<span > :{timeRemaining !== 0 ? <Timer
                          timeRemaining={timeRemaining}
                          render={render}
                          setRender={setRender}
                        /> : "-"}</span>
                        </p>
                      </div>                    </td>
                    <td style={{ width: "10%", }}>
                      <div style={{ marginTop: "5%" }}>
                        <a className={style.linktext} style={{ textDecoration: 'underline', }} onClick={() => {
                          setCurrentOrder(f)
                          setToggleDrawer(true)
                        }}>View details</a>
                      </div>
                    </td>
                  </tr>
                </div>
              )
            })}
          </table>
        </Card>
      )
      }
      <NDrawer
        f={currentOrder}
        users={users}
        Customer={Customer}
        products={products}
        setToggleDrawer={setToggleDrawer}
        setCurrentOrder={setCurrentOrder}
        toggleDrawer={toggleDrawer}
        currentOrder={currentOrder}
        currentProducts={currentProducts}
        token={token}
        // setConfirmationModal={setConfirmationModal}
        orders={orders}
        setOrders={setOrders}
      />
      <Modal
        open={modal === "ADD"}
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        aria-describedby="simple-modal-description"
        style={{ padding: '20px' }}
      >
        <form className={classes.paper}   >
          <div className={style.APHeader}>
            <h2 className="font-weight-700" onClick={() => setModal(null)}>Report</h2>
            {/* <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={() => setModal(null)}
          /> */}
          </div>
          <div className={classnames(style.APBody, "mt-3")} style={{ padding: '10px' }} >
            <Row>
              <p className="col-4 APlab">Select Report:</p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ width: '100%', textAlign: 'center' }}
                name="status"
                id="report"
                onChange={(e) => (setDowloadfilters({ ...downloadfilters, status: e.target.value }))}
              >
                <option className="col-4" value="SHIPPED">
                  Shipping
                </option>
                <option className="col-4" value="ACCEPTED">
                  Accepted
                </option>
                <option className="col-4" value="NEW">
                  NEW
                </option>
                <option className="col-4" value="DELIVERED">
                  Delivered
                </option>
                <option className="col-4" value="CANCELLED">
                  Cancelled
                </option>
                <option className="col-4" value="RETURNED">
                  Returned
                </option>
              </select>
            </Row>

            <Row className={style.Ap}>
              <p className="col-2 APlab">From:</p>

              <input
                className={classnames(style.APborder1, "col-4")}
                type="date"
                id="date"
                name="from"
                style={{ borderBottom: '1px solid #000' }}
                onChange={(e) => (setDowloadfilters({ ...downloadfilters, from: e.target.value }))}

              />
              <p className="col-2 APlab">TO:</p>
              <input
                className={classnames(style.APborder1, "col-4")}
                type="date"
                id="date"
                name="to"
                style={{ borderBottom: '1px solid #000' }}
                onChange={(e) => (setDowloadfilters({ ...downloadfilters, to: e.target.value }))}
              />
            </Row>

            <Row>
              <p className="col-4 APlab ">Select format:</p>
              <select
                className={classnames(style.APborder, "col-4")}
                name="file"
                id="file"
              >
                <option lassName="col-4" value="pdf">
                  CSV
                </option>

              </select>
            </Row>
          </div>

          <div className={style.APDown}>
            <CSVLink
              data={filteredorders}
              headers={headers}
              className={classnames(style.APMButton, "mt-4 ")}
              filename={"report.csv"}
            >
              Download
            </CSVLink>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default NewOrders;

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener('resize', updateScreenSize);
//   }, []);

//   useEffect(() => {
//   }, [screenSize]);

//   return screenSize;
// }