import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import style from "./Drawer.module.css";
import { Badge, Col, Row, Modal, ModalBody, ModalFooter } from 'reactstrap'
import classnames from "classnames";
import { Drawer } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../../config/config";
import { fetch_orders } from "../../../store/actions/ordersActions";
import { ProductList } from "components";
import Button from "components/uiComponents/Button";
import NotificationAlert from "react-notification-alert"
import "react-notification-alert/dist/animate.css"

const NDrawer = ({
  setToggleDrawer,
  products,
  f,
  setCurrentOrder,
  toggleDrawer,
  currentOrder,
  currentProducts,
  auth,
  token,
  orders,
  setOrders
}) => {
  const dimensions = useMediaQuery();
  const [updateOrderItemId, setupdateOrderItemId] = useState("")
  const [acceptedOrderItems, setAcceptedOrderItems] = useState([])
  const [unacceptedOrderItems, setUnacceptedOrderItems] = useState([])
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [confirmationLoading, setConfirmationLoading] = useState(false)

  const acceptOrder = async () => {
    try {
      setConfirmationLoading(true)
       await axios.put(`${baseUrl}/order/accept/${f?.order?._id}`, { orderData: f, acceptedOrderItems, unacceptedOrderItems }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      setToggleDrawer(false)
      fetch_orders(token);
      setConfirmationLoading(false)
      setConfirmationModal(false)
      if (acceptedOrderItems.length) {
        notify("success", "Purchase Order is created for the accepted Order Items")
      }
      if (unacceptedOrderItems.length && !acceptedOrderItems.length) {
        notify("success", "Order Items are successfully unaccepted")
      }
      setAcceptedOrderItems([])
      setUnacceptedOrderItems([])
    } catch (error) {
      setOrders([...orders])
      notify("danger", error?.response?.data?.error?.message || "Something went wrong")
      setConfirmationLoading(false)
      setConfirmationModal(false)
    }
  };

  const updateOrderItem = async (orderItemId, data) => {
    if (data.status === 'ACCEPTED') {
      !acceptedOrderItems.includes(orderItemId) && setAcceptedOrderItems([...acceptedOrderItems, orderItemId])
    } else {
      !unacceptedOrderItems.includes(orderItemId) && setUnacceptedOrderItems([...unacceptedOrderItems, orderItemId])
    }
    setupdateOrderItemId(orderItemId)
    const orderItemIndex = f?.orderItem.findIndex(data => data.orderItemId === orderItemId)
    currentOrder.orderItem[orderItemIndex] = { ...currentOrder.orderItem[orderItemIndex], status: data.status }
    setCurrentOrder(currentOrder)
    setOrders([...orders])
  }

  const statusColor = {
    "CANCELLED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
    "DELIVERED": { color: 'success', padding: 4 },
    "RETURNED": { color: 'danger', padding: 4 },
    "ACCEPTED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
    "SHIPPED": { color: 'success', padding: 4 },
    "UNACCEPTED": { color: 'danger', padding: 4 },
    "ACKNOWLEDGED": { color: "info", padding: 2 },
    "NEW": { color: 'primary', padding: 2 }
  }
  const notificationAlertRef = useRef(null)
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title text-center" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 7,
      zIndex: 20,
      closeButton: false

    }
    notificationAlertRef.current.notificationAlert(options)
  }
  return (
    <React.Fragment>
      <NotificationAlert ref={notificationAlertRef} />
      <Drawer
        anchor={'right'}
        open={toggleDrawer}
        PaperProps={{
          style: {
            width: '40%',
            paddingTop: '3%'
          }
        }}
        onClose={() => setToggleDrawer(false)}
      >
        <div
          style={{
            width: dimensions[0] - (dimensions[0] * 60) / 100,
            paddingBottom: '100px',
            height: '100%',
            overflowY: 'hidden'
          }}
          className="container pl-0 pr-0"
        >
          <Row
            className={classnames(
              'd-flex justify-content-between pl-5 ml-0',
              style.drawerheader
            )}
          >

            <div className="d-flex justify-content-start align-items-start flex-row" >
              <div>
                <p className={style.drawerTitle}>
                  Order ID
                </p>

                <p className={classnames(style.drawerSpanOrderID, 'mt-1')}>{f?.order?.order_id}</p>
              </div>
            </div>
            <div onClick={() => { fetch_orders(token); setToggleDrawer(false) }}>
              <img alt='' src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Close+(1).svg" style={{ cursor: "pointer", marginRight: "47px" }} />
            </div>
          </Row>

          <Row
            className="d-flex mr-0  pr-0"
            style={{ width: '100%', fontSize: '14px', paddingLeft: '33px', fontFamily: 'Open Sans' }}
          >
            <div className="col-3">
              <p className={style.drawersubtitle}>
                Delivery Region
              </p>

              <p className={style.drawersubtitletext}>
                {`${f?.address?.address?.city}, ${f?.address?.address?.state}`}
              </p>
            </div>
            <div className="col-3">
              <p className={classnames(style.drawersubtitle, 'text-center')}>
                Num. of Products
              </p>

              <p className={classnames(style.drawersubtitletext, 'text-center')}>
                {f?.totalQuantity}
              </p>
            </div>

            <div
              className="d-flex col-3"
            >
              <div className="d-flex justify-content-center align-items-center flex-column mb-2 ">
                <p className={classnames(style.drawersubtitle, 'text-center')}>
                  Order Status
                </p>
                <div className="d-flex flex-row w-10 mt-3">
                  {f?.order?.status ? <Badge color={statusColor[f?.order?.status].color} style={{ padding: statusColor[f?.order?.status].padding, cursor: 'pointer', marginleft: statusColor[f?.order?.status].marginLeft, marginRight: statusColor[f?.order?.status].marginRight }}>{f?.order?.status}</Badge>
                    : null}
                </div>
              </div>
            </div>
          </Row>
          <div className={style.divider} />
          <div style={{ maxHeight: '68%', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            {f?.orderItem?.length && f?.orderItem.map((p, i) => {
              return <ProductList orderItem={p} columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']} tabName='NEW_ORDERS' updateOrderItem={updateOrderItem} updateOrderItemId={updateOrderItemId} />
            })}
            <Row className='d-flex justify-content-end mr-5'>
              <p className="p-header mt-1">
                Total Cost Price
              </p><span style={{
                marginLeft: 20,
                marginTop: 2,
                fontFamily: "Open Sans",
                fontSize: 14
              }}>{f?.totalCostPrice}</span>
            </Row>
            <Row className="w-100 product-list-row mb-3">
              <Col sm={12}><div className="p-divider mt-4"></div></Col>
            </Row>
            <div className={classnames("col-7", style.details)}>
              <div className="col-10">
                <div className="row m-0">
                  <b>Shipping Details</b>
                </div>
                <p className="p-content text-left">
                  {`${f?.address?.address?.firstname} ${f?.address?.address?.lastname}`}
                </p>
                <p className="p-content text-left">
                  {`${f?.address?.address?.line1} ${f?.address?.address?.line2}`}
                </p>
                <p className="p-content text-left">
                  {`${f?.address?.address?.city}, ${f?.address?.address?.state}, ${f?.address?.address?.postcode} `}
                </p>
                <div className="row m-0 pt-2">
                  {f?.address?.address?.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <div className={style.drawerbuttoncontainer} >
            <Row className="d-flex align-items-center justify-content-center mt-3 mx-2">
              <Col sm='9'>
                <p style={{ fontSize: 14, textAlign: 'center' }}>Purchase Order will be generated for Accepted Items</p>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button
                  block
                  size="sm"
                  variant="primary"
                  style={{ backgroundColor: 'black' }}
                  disabled={(acceptedOrderItems?.length + unacceptedOrderItems?.length) !== f?.orderItem?.length}
                  onClick={() => {

                    setToggleDrawer(false)
                    setConfirmationModal(true)

                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
      <Modal
        isOpen={confirmationModal}
        toggle={setConfirmationModal}
        style={{
          position: 'absolute', top: '30%', right: '40%'
        }}
      >
        <ModalBody>
          <p>
            Are you sure you want to proceed ?
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "flex-start" }}>
          <Button isLoading={confirmationLoading} onClick={() => acceptOrder()}>Yes</Button>
          <Button variant='outline-primary' className='ml-2' onClick={() => setConfirmationModal(false)}>No</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment >
  );
};

export default NDrawer;

function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
  }, [screenSize]);

  return screenSize;
}
