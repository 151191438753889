import { store } from '../store';

export const login = (data) => {
    store.dispatch({
        type: 'LOGIN',
        token: data.token,
        vendor: data.vendor
    });
}

export const logout = (history) => (dispatch) => {
    dispatch({ type: 'RESET' });
    dispatch({ type: 'REMOVE_ORDERS' })
    dispatch({ type: 'REMOVE_PRODUCTS' })
}

export const updateProfile = (data) => {
    store.dispatch({
        type: 'UPDATE_PROFILE',
        vendor: data
    });
}

export const searchAction = (query) => dispatch => {
    console.log("-------------------", query, "-----------------------")
    dispatch({
        type: 'SEARCH_QUERY',
        query: query
    })
}

export const updateProfileImageAction = (data) => {
    store.dispatch({
        type: 'UPDATE_PROFILE_IMAGE',
        vendor: data
    });
}

export const getNotificationAction = (data) => (dispatch) => {
    dispatch({
        type: 'POST_NOTIFICATION',
        notification: data
    });
}
