/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { Col, Row } from "react-bootstrap";
import style from "./Orders.module.css";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  fetch_orders,
  filterneworders,
  filterpastorders,
} from "store/actions/ordersActions";
import ODrawer from "./Drawers/ODrawer";
import NDrawer from "./Drawers/NDrawer";
import PDrawer from "./Drawers/PDrawer";
import dateformat from "dateformat";
import { Modal } from "@material-ui/core";
import FilterButton from "./FilterButton";
import Alertpage from "./Alertpage";
import NewOrders from "./OrdersTables/NewOrders";
import OpenOrders from "./OrdersTables/OpenOrders";
import PastOrders from "./OrdersTables/PastOrders";
import { CSVLink } from "react-csv";
import close from "../../assets/img/Close.svg";


export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem",
        fontFamily: "Open Sans",
        fontWeight: 400,
        color: "#888888",
        marginBottom: "0px !important",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    height: "auto",
  },
  paper1: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    height: "auto",
  },

  paper2: {
    position: "absolute",
    width: 280,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    margin: "10px",
    height: "56vh",
  },

  paper4: {
    position: "absolute",
    width: 498,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 3),
    margin: "10px",
    height: "58vh",
  },

  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "green",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 10,
      height: 10,

      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  typo: {
    fontSize: "7px",
  },
  filterHeader: {
    fontSize: "7px",
  },
  // root: {
  //   "& .MuiFormLabel-root": {
  //     color: "red", // or black
  //   },
  // },
}));


const Orders = ({
  orders,
  products,
  newOrders,
  query,
  searchString,
  auth,
  pastOrders,
  location,
  openOrders,
  users,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentOrder, setCurrentOrder] = useState({});
  const [currentProducts, setCurrentProduct] = useState([]);
  const [currentFilterOption, setCurrentFilterOption] = useState(0);
  const [region, setRegion] = useState("");
  const [modal, setModal] = useState("");
  const [downloadfilters, setDowloadfilters] = useState({
    from: "",
    to: "",
    status: "",
  });
  const [NOrders, setNOrders] = useState([]);
  const [OOrders, setOOrders] = useState([]);
  const [POrders, setPOrders] = useState([]);
  const [filteredorders, setFilteredOrders] = useState([]);
  useEffect(() => {
    setNOrders(newOrders);
    setOOrders(openOrders);
    setPOrders(pastOrders);

  }, [newOrders, openOrders, pastOrders]);


  const handleRegion = (event) => {
    setRegion(event.target.value);
  };

  const handleFilterNew = () => {
    const farr = orders
      .filter(
        (orders) =>
          orders.delivery_region.toLowerCase().replace(" ", "") ===
          region.toLowerCase().replace(" ", "")
      )
      .filter((orders) => orders.status === "NEW");

    filterneworders(farr);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    if (downloadfilters.from && downloadfilters.to && downloadfilters.status) {
      const arr = orders
        .filter(
          (f) =>
            f.delivery_date >=
            dateformat(
              new Date(downloadfilters.from),
              "yyyy-mm-dd"
            ) &&
            f.delivery_date <=
            dateformat(new Date(downloadfilters.to), "yyyy-mm-dd")
        )
        .filter((f) => f.status === downloadfilters.status).map((d) => ({
          ...d,
          delivery_date: dateformat(d.delivery_date, 'yyyy-mm-dd')
        }));

      setFilteredOrders(arr.map((d) => ({
        ...d,
        delivery_date: dateformat(d.delivery_date, 'yyyy-mm-dd')
      })));
      filterneworders(arr.map((d) => ({
        ...d,
        delivery_date: dateformat(d.delivery_date, 'yyyy-mm-dd')
      })));
    }
  }, [downloadfilters]);


  const handleDownloadReport = (e) => {
    setModal("DOW")
    // e.preventDefault();
    const filters = { ...downloadfilters, [e.target.name]: e.target.value };
    setDowloadfilters(filters);
  };

  const handleFilterPast = () => {
    const farr = orders
      .filter(
        (orders) =>
          orders.delivery_region.toLowerCase().replace(" ", "") ===
          region.toLowerCase().replace(" ", "")
      )
      .filter((orders) => orders.status === "CANCELLED");
    filterpastorders(farr);
  };


  const [activeTab, setActiveTab] = useState(0);
  const [toggleDrawer, setToggleDrawer] = useState(false);


  // The Colume For New Orders
  const columns = [
    { text: "S.No", width: "5%" },
    { text: "Orders ID", width: "10%" },
    { text: "Order Sub ID", width: "10%" },
    { text: "Products", width: "10%" },
    { text: "Delivery Region", width: "10%" },
    { text: "No.of Product", width: "10%" },
    { text: "Order Value", width: "10%" },
    { text: "Status", width: "10%" },
  ];
  //Headers for the Download Catalouge Button
  const headers = [
    { label: "Product", key: "product" },
    { label: "Status", key: "status" },
    { label: "Order ID", key: "order_id" },
    { label: "User ID", key: "user_id" },
    { label: "Order Value", key: "order_value" },
    { label: "Shipping Charge", key: "shipping_charge" },
    { label: "Delivery Region", key: "delivery_region" },
    { label: "Delivery Date ", key: "delivery_date" },
  ];
  // The Colume For Open Orders and Past Orders

  const [Customer, setCustomer] = useState({});

  useEffect(() => {
    getCustomer(currentOrder.user_id);
  }, [currentOrder]);

  // useEffect(() => {
  //   var sales_total = 0;
  //   var shipping_total = 0;
  //   setTotal({ sales_price: sales_total, shipping_price: shipping_total });
  // }, []);

  const getCustomer = (id) => {
    const arr = users.find((f) => f._id === id);

    setCustomer(arr);
  };
  useEffect(() => {
    fetch_orders(auth.token);
  }, []);

  useEffect(() => {
    setCurrentProduct(
      products.filter((d) => currentOrder.product?.map((e) => e === d._id))
    );
  }, [currentOrder]);

  const getProductName = (id) => {
    const arr = products?.filter((d) => id.map((e) => e === d._id))[0]?.name;
    return arr;
  };

  const getCustomerName = (id) => {
    const arr = users.find((f) => f._id === id);
    return arr.name;
  };


  const [alert, setAlert] = useState(null);

  useEffect(() => {
    window.setTimeout(() => {
      setAlert(false);
    }, 5000);
  }, [alert]);

  useEffect(() => {
    //   if (location.state?.activeTab === "ACCEPTED") {
    //     setActiveTab(1);
    //   } else if (location.state?.activeTab === "DELIVERED") {
    //     setActiveTab(2);
    //   } else {
    //     setActiveTab(0);
    //   }
    switch (location?.state?.activeTab) {
      case 'ACCEPTED':
        return setActiveTab(1);
      case 'DELIVERED':
        return setActiveTab(2);
      case 'SHIPPED':
        return setActiveTab(1)
      default:
        return null
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingLeft: '20px', paddingTop: '60px', paddingRight: '22px' }}>
        {alert && (
          <div
            className={style.animation}
            style={{
              position: "fixed",
              top: "3.5rem",
              width: "85%",
              left: "15%",
              zIndex: 100,
            }}
          >
            <Alertpage />
          </div>
        )}
        <Row>
          <Col sm={6}>
            <h1 className={classnames("font-weight-700", style.header)} style={{ fontSize: '2.2rem' }}>Orders</h1>
          </Col>
          <Col
            sm={6}
            className="d-flex justify-content-end align-items-center"

          >
            <FilterButton
              anchorEl={anchorEl}
              handleClose={handleClose}
              setCurrentFilterOption={setCurrentFilterOption}
              currentFilterOption={currentFilterOption}
              handleRegion={handleRegion}
              orders={orders}
              activeTab={activeTab}
              handleFilterPast={handleFilterPast}
              handleClick={handleClick}
              products={products}
              token={auth.token}
              auth={auth}
              setNOrders={setNOrders}
              NOrders={NOrders}
              setOOrders={setOOrders}
              setPOrders={setPOrders}
              handleFilterOpen={handleFilterPast}
              setAnchorEl={setAnchorEl}
              handleFilterNew={handleFilterNew}

            />

            <button
              type="button"
              className={"download-report"}
              onClick={handleDownloadReport}
            >
              Download Report
            </button>
          </Col>
        </Row>
        <br />
        <div className={style.tab_container}>
          <div
            className={classnames(
              "d-flex justify-content-center align-items-center",
              activeTab === 0 ? style.active : style.notactive
            )}
            onClick={() => setActiveTab(0)}
          >
            <h6>New Orders</h6>
          </div>
          <div
            className={classnames(
              "d-flex justify-content-center align-items-center",
              activeTab === 1 ? style.active : style.notactive
            )}
            onClick={() => setActiveTab(1)}
          >
            <h6>Open Orders</h6>
          </div>
          <div
            className={classnames(
              "d-flex justify-content-center align-items-center",
              activeTab === 2 ? style.active : style.notactive
            )}
            onClick={() => setActiveTab(2)}
          >
            <h6>Past Orders</h6>
          </div>
        </div>

        {activeTab === 0 && (
          <NewOrders
            filteredorders={filteredorders}
            getProductName={getProductName}
            currentProducts={currentProducts}
            users={users}
            Customer={Customer}
            searchString={searchString}
            setToggleDrawer={setToggleDrawer}
            setCurrentOrder={setCurrentOrder}
            toggleDrawer={toggleDrawer}
            currentOrder={currentOrder}
            NDrawer={NDrawer}
            headers={headers}
            modal={modal}
            products={products}
            classes={classes}
            setAlert={setAlert}
            token={auth.token}
            columns={columns}
            NOrders={NOrders}
          />
        )}
        {activeTab === 1 && (
          <OpenOrders
            getCustomerName={getCustomerName}
            filteredorders={filteredorders}
            getProductName={getProductName}
            currentProducts={currentProducts}
            searchString={searchString}
            users={users}
            Customer={Customer}
            setToggleDrawer={setToggleDrawer}
            setCurrentOrder={setCurrentOrder}
            token={auth.token}
            toggleDrawer={toggleDrawer}
            currentOrder={currentOrder}
            ODrawer={ODrawer}
            headers={headers}
            modal={modal}
            classes={classes}
            OOrders={location.state?.activeTab ? OOrders.filter((d) => d.status === location.state.activeTab) : OOrders}
            products={products}
          />
        )}
        {activeTab === 2 && (
          <PastOrders
            POrders={POrders}
            PDrawer={PDrawer}
            setPOrders={setPOrders}
            getCustomerName={getCustomerName}
            filteredorders={filteredorders}
            getProductName={getProductName}
            currentProducts={currentProducts}
            searchString={searchString}
            users={users}
            token={auth.token}
            Customer={Customer}
            setToggleDrawer={setToggleDrawer}
            setCurrentOrder={setCurrentOrder}
            toggleDrawer={toggleDrawer}
            currentOrder={currentOrder}
            headers={headers}
            modal={modal}
            classes={classes}
            products={products}
          />
        )}
      </div>
      <Modal
        open={modal === "DOW"}
        aria-labelledby="simple-modal-title"
        className={classnames(classes.modal, 'mui-modal')}
        aria-describedby="simple-modal-description"

      >
        <form className={classes.paper4}  >
          <div className={style.APHeader}>
            <h2
              style={{ fontSize: "2rem", fontWeight: "bolder" }}
              onClick={() => setModal(null)}
            >
              Report
            </h2>

            <img src={close} alt="close" onClick={() => setModal(null)} />
          </div>
          <div
            className={classnames(style.APBody,)}
            style={{ padding: "10px" }}
          >
            <Row>
              <p
                className={classnames(style.APlab, "col-4 ")}
                style={{ width: "17vw", marginTop: "25px" }}
              >
                Select report:
              </p>

              <select
                className={classnames(style.APborder, "col-8")}
                style={{ width: "10%", fontSize: '14px', textAlign: "center", marginTop: "20px" }}
                name="status"
                id="report"
                onChange={(e) =>
                  setDowloadfilters({
                    ...downloadfilters,
                    status: e.target.value,
                  })
                }
              >
                <option value="NEW">New Orders</option>
                <option value="ACCEPTED">Accepted Orders</option>
                <option value="SHIPPED">Shipped Orders</option>
                <option value="DELIVERED">Delivered Orders</option>
              </select>
            </Row>

            <Row>

              <p
                className={classnames(style.APlab, "col-3 ")}
                style={{ width: "20vw", marginTop: "25px" }}
              >
                Dates :
              </p>

              <div className="d-flex flex-row mt-4 col-9">
                <input type="date" style={{ width: '147px' }} id="Test_Date" required className={style.APborder} onChange={(e) => setDowloadfilters({ ...downloadfilters, from: e.target.value })} />
                <span style={{ fontWeight: "900", fontSize: '14px', width: "1vw", marginTop: "3px", paddingRight: '21px', paddingLeft: '14px' }}>-</span>
                <input type="date" style={{ width: '147px' }} id="Test_Date" required className={style.APborder} onChange={(e) => setDowloadfilters({ ...downloadfilters, to: e.target.value })} />
              </div>

            </Row>

            <Row>
              <p
                className={classnames(style.APlab, "col-4 ")}
                style={{ width: "20vw", marginTop: "25px" }}
              >
                Select format:
              </p>
              <select
                className={classnames(style.APborder, "col-8")}
                style={{ marginTop: "20px", fontSize: '14px' }}
                name="file"
                id="file"
              >

                <option className="col-4" value="pdf">
                  CSV
                </option>
              </select>
            </Row>
          </div>



          <div className={style.APDown}>
            <button
              type="submit"
              className={classnames("btn btn-dark", style.filterButton1)}
            >

              <CSVLink
                data={orders.filter((d) => d.status === downloadfilters.status).map((d) => ({
                  ...d,
                  delivery_date: dateformat(d.delivery_date, 'yyyy-mm-dd')
                }))}
                headers={headers}
                className={style.DownloadCatalouge}
                filename={"Report.csv"}
              >
                Download
              </CSVLink>

            </button>
          </div>
        </form>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  products: state.orders.products,
  newOrders: state.orders.newOrders,
  pastOrders: state.orders.pastOrders,
  openOrders: state.orders.openOrders,
  users: state.orders.users,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Orders));

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener("resize", updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener("resize", updateScreenSize);
//   }, []);

//   useEffect(() => {
//   }, [screenSize]);

//   return screenSize;
// }
