/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { connect, useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { logo } from "assets/icons";

function Sidebar({ color, image, routes, auth }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const images = useSelector(state => state.auth?.vendor?.bit_img);

  return (

    <div className="sidebar" style={{ borderRight: "1px solid #CDC6C3", zIndex: 10 }}  >

      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center" >
          <div style={{
            maxWidth: '200px',
            maxHeight: '200px',
            overflow: 'hidden'
          }}>
            <img alt='' src={images} style={{
              width: '100%'
            }} />
          </div>
        </div>

        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  {prop.name === 'Account Settings' &&
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        style={{
                          borderTop: prop.name === 'Account Settings' && '1px solid rgba(0 ,0, 0,0.3)',
                          borderRadius: prop.name === 'Account Settings' && '0px',
                          marginTop: '20px',
                          marginBottom: '20px',
                          width: '90%'
                        }}
                      >

                      </div>
                    </div>}
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"

                  >
                    <img alt='' className="Navbar-icon" src={prop.path === location.pathname ? prop.active : prop.icon} />
                    <p style={{ fontWeight: prop.path === location.pathname && 700 }} >{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Nav>
        <div className="align-items-start w-100  sidebar-logo" style={{ marginLeft: -15 }}>
          <p >Powered by</p> <br />
          <img alt='' src={logo} />
        </div>

      </div>
    </div>
  );
}

const mapsStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapsStateToProps)(withRouter(Sidebar));
