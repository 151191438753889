/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import style from './Drawer.module.css';
import dateformat from "dateformat";
import { Download, RefreshCw } from 'react-feather';
import { Col, Row } from 'react-bootstrap';
import { Drawer } from '@material-ui/core';
import { baseUrl } from 'config/config';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import { ProductList } from 'components';
import { Badge } from 'reactstrap';
import Button from 'components/uiComponents/Button';
import NotificationAlert from "react-notification-alert"
import "react-notification-alert/dist/animate.css"
const PDrawer = ({
  setToggleDrawer,
  f,
  products,
  setCurrentOrder,
  toggleDrawer,
  users,
  currentOrder,
  token,
  currentProducts,
  selectedOrderItemIds,
  setSelectedOrderItemIds,
  orders,
  setOrders
}) => {
  const notificationAlertRef = useRef(null)
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title text-center" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      // icon: "ni ni-bell-55",
      autoDismiss: 7,
      zIndex: 20,
      closeButton: false

    }
    notificationAlertRef.current.notificationAlert(options)
  }
  const paymentStatusColor = {
    "PENDING": { color: 'warning', padding: 2, marginLeft: 27, label: "PENDING" },
    "PAID": { color: 'success', padding: 3, label: 'PAID' },
    "OVER_DUE": { color: 'danger', padding: 2, label: 'OVER DUE' },
    "PARTIALLY_PAID": { color: "primary", padding: 2, label: 'PARTIALLY PAID' },
    "VOID": { color: "info", padding: 2, label: 'VOID' },

  }
  const [poEdit, setPOEdit] = useState(false)
  const [orderItemStatusObj, setOrderItemStatusObj] = useState({})
  const [vendorPaymentStatus, setVendorPaymentStatus] = useState('')

  const onSubmit = async () => {
    try {
      const res = await axios.patch(`${baseUrl}/order-item/update-status`, { orderItemStatusObj }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (res.data.message === 'updated') {
        Object.keys(orderItemStatusObj).map(orderItemId => {
          if (currentOrder.orderShipment) {
            const shipmentIndex = currentOrder.shipments.findIndex(shipment => shipment.order_item_ids.includes(orderItemId))
            if (shipmentIndex === -1) {
              const orderItemIndex = currentOrder.orderItem.findIndex(data => data.orderItemId === orderItemId)
              currentOrder.orderItem[orderItemIndex] = { ...currentOrder.orderItem[orderItemIndex], status: orderItemStatusObj[orderItemId] }
            } else {
              const orderItemIndex = currentOrder.shipments[shipmentIndex].orderItems.findIndex(data => data.orderItemId === orderItemId)
              currentOrder.shipments[shipmentIndex].orderItems[orderItemIndex] = { ...currentOrder.shipments[shipmentIndex].orderItems[orderItemIndex], status: orderItemStatusObj[orderItemId] }
            }
          } else {
            const orderItemIndex = currentOrder.orderItem.findIndex(data => data.orderItemId === orderItemId)
            currentOrder.orderItem[orderItemIndex] = { ...currentOrder.orderItem[orderItemIndex], status: orderItemStatusObj[orderItemId] }
          }
        })
        setCurrentOrder(currentOrder)
        const index = orders.findIndex(order => order.order._id === f.order._id)
        orders[index] = currentOrder
        setOrders(orders)
      }


    } catch (error) {
      // alert(error.response.data);
    }
  }


  useEffect(() => {
    f?.order?.vendor_payment && setVendorPaymentStatus(f?.order?.vendor_payment[f?.vendorId]?.vendor_payment_status)
  }, [f])


  const getVendorPaymentStatus = async (orderId) => {
    try {
      const res = await axios.get(`${baseUrl}/order-item/bill-status/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (res.status === 200) {
        const orderIndex = orders.findIndex(order => order.order.order_id === orderId)
        orders[orderIndex].order.vendor_payment[f.vendorId].vendor_payment_status = res.data.billStatus
        setCurrentOrder(orders[orderIndex])
        setOrders(orders)
        setVendorPaymentStatus(res.data.billStatus)
        notify("success", "Bill status is updated")

      }
    } catch (error) {
      notify("danger", error?.response?.data?.error?.message || "Something went wrong")
    }
  }

  return (
    <React.Fragment>
      <NotificationAlert ref={notificationAlertRef} />

      <Drawer
        anchor={'right'}
        open={toggleDrawer}
        PaperProps={{
          style: {
            width: '40%',
            paddingTop: '3%'
          }
        }}
        onClose={() => setToggleDrawer(false)}
      >
        <div style={{ marginTop: 40, marginLeft: 30, marginBottom: 70 }}>
          <Row>
            <Col>
              <div style={{ fontSize: 15, fontWeight: 700 }}>ORDER ID</div>
              <div>{f?.order?.order_id}</div>
            </Col>
            {vendorPaymentStatus ? <Col>
              <div style={{ fontSize: 14, fontWeight: 700 }}>Payment Status <span className='ml-1'><RefreshCw size={15} onClick={() => { getVendorPaymentStatus(f.order.order_id) }} /></span></div>
              <Badge color={paymentStatusColor[vendorPaymentStatus]?.color} style={{ padding: paymentStatusColor[vendorPaymentStatus]?.padding, marginLeft: paymentStatusColor[vendorPaymentStatus]?.marginLeft, cursor: 'pointer' }}>{paymentStatusColor[vendorPaymentStatus]?.label}</Badge>
            </Col> : null}
            <Col className='d-flex justify-content-end align-items-center'>
              <img alt='' onClick={() => setToggleDrawer(false)} src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/UI+images/Close+(1).svg" style={{ height: 20, width: 20, cursor: "pointer", marginRight: "47px" }} />
            </Col>
          </Row>
          <Row className='mt-4' style={{ fontSize: 14 }}>
            <Col>
              <div style={{ fontWeight: 700 }}>Delivery Region</div>
            </Col>
            <Col>
              <div style={{ fontWeight: 700 }}>Num. of Products</div>
            </Col>
            <Col>
              <div style={{ fontWeight: 700 }}>Order Date</div>
            </Col>
            {f?.order?.vendor_payment ? <Col>
              <Download size={15} color='#1B74B9' />
              <span style={{ color: '#1B74B9', marginLeft: 2, fontSize: 12 }}><a style={{ color: '#1B74B9' }} href={f?.order?.vendor_payment[f?.vendorId]?.purchaseorder_link} target='_blank' download rel="noreferrer">Purchase Order</a></span>
            </Col> : null}

          </Row>
          <Row style={{ fontSize: 14 }}>
            <Col>
              <div>{`${f?.address?.address?.city || ""}, ${f?.address?.address?.state}` || ""}</div>
            </Col>
            <Col>
              <div>{`${f?.totalQuantity}`}</div>
            </Col>
            <Col>
              <div>{`${dateformat(f?.order?.createdAt, "dd mmm yyyy")}`}</div>
            </Col>
            {f?.order?.vendor_payment ? <Col></Col> : null}
          </Row>
          <div className={style.divider} />
          <Row>
            {
              f.orderShipment ? <>{f?.shipments.map(shipment => {
                const { orderItems, ...shipmentDetails } = shipment

                return <div style={{ paddingRight: 20, fontSize: 12 }}>
                  <Row style={{ backgroundColor: " #F1EEEA", margin: 5, padding: 5, marginBottom: 20 }}>
                    <Col sm={6}>{shipment.name}</Col>
                    <Col>
                      <Row>
                        <Col>
                          <Download size={15} color='#1B74B9' />
                          <span style={{ color: '#1B74B9', marginLeft: 2, fontSize: 12 }}><a style={{ color: '#1B74B9' }} href={shipmentDetails.order_summary} target='_blank' download rel="noreferrer">Order Summary</a></span>
                        </Col>
                        <Col>
                          <Download size={15} color='#1B74B9' />
                          <span style={{ marginLeft: 2, fontSize: 12 }}><a style={{ color: '#1B74B9' }} href={shipmentDetails.e_way_bill} download target='_blank' rel="noreferrer">E-way Bill</a></span></Col>
                        <Col>
                          <Download size={15} color='#1B74B9' />
                          <span style={{ color: '#1B74B9', marginLeft: 2, fontSize: 12 }}><a style={{ color: '#1B74B9' }} href={shipmentDetails.invoice} download target='_blank' rel="noreferrer">Invoice</a></span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {shipment.orderItems.map((p, i) => {
                    return <ProductList
                      shipmentDetails={shipmentDetails}
                      orderItem={p}
                      columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                      tabName='OPEN_ORDERS'
                      poEdit={poEdit}
                      setPOEdit={setPOEdit}
                      orderItemStatusObj={orderItemStatusObj} setOrderItemStatusObj={setOrderItemStatusObj}
                    />
                  })}
                </div>
              })}
                <div style={{ paddingRight: 20, fontSize: 12 }}>
                  {f?.orderItem?.length ? <Row style={{ backgroundColor: " #F1EEEA", margin: 5, padding: 5, marginBottom: 20 }}>
                    <Col sm={6}>Accepted Items</Col>
                  </Row> : null}
                  {f?.orderItem?.length ? f?.orderItem?.map((p, i) => {
                    return <ProductList
                      orderItem={p}
                      columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                      tabName='OPEN_ORDERS' poEdit={poEdit} setPOEdit={setPOEdit}
                      orderItemStatusObj={orderItemStatusObj} setOrderItemStatusObj={setOrderItemStatusObj}

                    />
                  }) : null}
                </div>
                {f?.cancelledOrderItems?.length ? <div style={{ paddingRight: 20, fontSize: 12 }}><Row style={{ backgroundColor: " #F1EEEA", margin: 5, padding: 5, marginBottom: 20 }}>
                  <Col sm={6}>Cancelled Items</Col>
                </Row>
                  {f?.cancelledOrderItems?.map((p, i) => {
                    return <ProductList
                      orderItem={p}
                      columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                      tabName='OPEN_ORDERS'
                      poEdit={poEdit} setPOEdit={setPOEdit}
                      orderItemStatusObj={orderItemStatusObj}
                      setOrderItemStatusObj={setOrderItemStatusObj}
                    />
                  })}</div> : null}
              </> : <div>
                {f?.orderItem?.length ? <div style={{ paddingRight: 20, fontSize: 12 }}> <Row style={{ backgroundColor: " #F1EEEA", margin: 5, padding: 5, marginBottom: 20 }}>
                  <Col sm={6}>Accepted Items</Col>
                </Row>
                  {f?.orderItem?.map((p, i) => {
                    return <ProductList
                      orderItem={p}
                      columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                      tabName='OPEN_ORDERS'
                      poEdit={poEdit} setPOEdit={setPOEdit}
                      orderItemStatusObj={orderItemStatusObj} setOrderItemStatusObj={setOrderItemStatusObj}
                    />

                  })}</div> : null}
                {f?.cancelledOrderItems?.length ? <div style={{ paddingRight: 20, fontSize: 12 }}><Row style={{ backgroundColor: " #F1EEEA", margin: 5, padding: 5, marginBottom: 20 }}>
                  <Col sm={6}>Cancelled Items</Col>
                </Row>
                  {f?.cancelledOrderItems?.map((p, i) => {
                    return <ProductList
                      orderItem={p}
                      columns={['Product', 'Qty.', 'Cost Price', 'Pixel Price']}
                      tabName='OPEN_ORDERS'
                      poEdit={poEdit} setPOEdit={setPOEdit}
                      orderItemStatusObj={orderItemStatusObj}
                      setOrderItemStatusObj={setOrderItemStatusObj}
                    />
                  })}</div> : null}
              </div>
            }
          </Row>
          <Row style={{ fontSize: 13 }} >
            {/* <div><span style={{ fontWeight: 700 }}>Cost Price Total</span> {f.totalCostPrice}</div>
             */}
            <Col sm={10} className='d-flex justify-content-end'>
              <div style={{ fontWeight: 700 }}>Cost Price Total</div>
            </Col>
            <Col sm={2}>
              <div>{f?.totalCostPrice}</div>
            </Col>
          </Row>
          {/* <div className="p-divider" style={{ marginTop: 15, marginLeft: 13, marginRight: 25 }}></div> */}
          <Row style={{ fontWeight: 700, fontSize: 13 }} className='mt-4' >
            <Col>
              <div>Shipping Details</div>
            </Col>
          </Row>

          <Row style={{ fontSize: 13 }}>
            <Col>
              {`${f?.address?.address?.firstname} ${f?.address?.address?.lastname}`}
            </Col>
          </Row>
          <Row style={{ fontSize: 13 }}>
            <Col>
              {`${f?.address?.address?.line1} ${f?.address?.address?.line2}`}
            </Col>
          </Row>
          <Row style={{ fontSize: 13 }}>
            <Col>
              {`${f?.address?.address?.city}, ${f?.address?.address?.state}, ${f?.address?.address?.postcode} `}
            </Col>
          </Row>
          <Row style={{ fontSize: 13 }}>
            <Col>
              {f?.address?.address?.phone}
            </Col>
          </Row>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <div className={style.drawerbuttoncontainer} >
            <Row className="d-flex align-items-center justify-content-center mt-2 mx-2">
              <Col sm={4}>
                {
                  poEdit ? <Button
                    block
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      setPOEdit(false)
                    }}
                  >
                    Go Back
                  </Button> : null
                }
              </Col>
              <Col sm={4}>
              </Col>
              <Col className="d-flex justify-content-end">
                {poEdit ? <Button
                  block
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    orderItemStatusObj && Object.keys(orderItemStatusObj).length && onSubmit()
                    setPOEdit(false)
                  }}
                >
                  Submit
                </Button>
                  : <Button
                    block
                    size="sm"
                    variant="primary"
                    style={{ backgroundColor: 'black' }}
                    onClick={() => {
                      setPOEdit(true)
                    }}
                  >
                    Manage Order
                  </Button>}
              </Col>
            </Row>
          </div>
        </div>
      </Drawer >
    </React.Fragment >
  );
};

export default PDrawer;

// function useMediaQuery() {
//   const [screenSize, setScreenSize] = useState([0, 0]);

//   useLayoutEffect(() => {
//     function updateScreenSize() {
//       setScreenSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateScreenSize);
//     updateScreenSize();
//     return () => window.removeEventListener('resize', updateScreenSize);
//   }, []);

//   useEffect(() => {
//   }, [screenSize]);

//   return screenSize;
// }


