/* eslint-disable array-callback-return */
import axios from 'axios';

export async function Tags() {
  const data = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/v1/tags`);
  const tags = data.data.data;

  const category_id = tags.find((tag) => tag.name === 'category');
  var cat_id = category_id._id;
  var categories = tags.filter((tag) => tag.parent_id === cat_id);
  const cats = [];
  // var category_dict = {}
  categories.map((category, index) => {
    var temp = {};
    temp['name'] = category.name;
    temp['id'] = category._id;
    cats.push(temp);
  });
  cats.map((cat, index) => {
    const subcats = tags.filter((subcat) => subcat.parent_id === cat.id);
    var sct = [];
    subcats.map((sc) => {
      var sub_temp = {}  
      sub_temp["name"] = sc.name
      sct.push(sub_temp);
    });
    // console.log(sct);
    cats[index]['subcat'] = sct;
  });
  return cats
}
