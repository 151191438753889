import {store} from '../store';
import axios from 'axios';
import { baseUrl } from '../../config/config';

export const fetchProducts = async (token)  => {

    try{
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const res = await axios.get(`${baseUrl}/vendor/product/list`, config);


        store.dispatch({
            type: 'POST_PRODUCTS',
            products: res.data
        });
        
    }
    catch(error){

    }
} 
