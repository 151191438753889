import React, { useState } from 'react';
import { Badge, Input, Col, Row } from 'reactstrap';
import { baseUrl } from "../../../config/config";
import axios from "axios";
import StatusSelectBox from 'components/uiComponents/StatusSelectBox';

const ProductListComponent = ({ token, orderItem, columns, selectedOrderItemIds, setSelectedOrderItemIds, index, selectableProducts, currentOrder, setCurrentOrder, poEdit, orders, setOrders }) => {
    const statusColor = {
        "CANCELLED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
        "DELIVERED": { color: 'success', padding: 4 },
        "RETURNED": { color: 'danger', padding: 4 },
        "ACCEPTED": { color: 'warning', padding: 4, marginLeft: 27, marginRight: 0 },
        "SHIPPED": { color: 'success', padding: 4 },
        "UNACCEPTED": { color: 'danger', padding: 4 },
        "ACKNOWLEDGED": { color: "info", padding: 2 },
        "NEW": { color: 'primary', padding: 2 }
    }
    const [status, setStatus] = useState(orderItem.status)
    const disableEdit = (status === 'CANCELLED' || status === 'UNACCEPTED')

    const badgeClick = async (data) => {
        //api call to change the status of the orderItem
        try {
            const res = await axios.patch(`${baseUrl}/order-item/update/${orderItem.orderItemId}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (res.status === 200) {
                if (currentOrder.orderShipment) {
                    const shipmentIndex = currentOrder.shipments.findIndex(shipment => shipment.order_item_ids.includes(orderItem.orderItemId))
                    if (shipmentIndex === -1) {
                        const orderItemIndex = currentOrder.orderItem.findIndex(data => data.orderItemId === orderItem.orderItemId)
                        currentOrder.orderItem[orderItemIndex] = { ...currentOrder.orderItem[orderItemIndex], status: data.status }
                    } else {
                        const orderItemIndex = currentOrder.shipments[shipmentIndex].orderItems.findIndex(data => data.orderItemId === orderItem.orderItemId)
                        currentOrder.shipments[shipmentIndex].orderItems[orderItemIndex] = { ...currentOrder.shipments[shipmentIndex].orderItems[orderItemIndex], status: data.status }
                    }
                } else {
                    const orderItemIndex = currentOrder.orderItem.findIndex(data => data.orderItemId === orderItem.orderItemId)
                    currentOrder.orderItem[orderItemIndex] = { ...currentOrder.orderItem[orderItemIndex], status: data.status }

                }
                if (res.data.orderStatus) {
                    currentOrder.status = res.data.orderStatus
                }
                setCurrentOrder(currentOrder)
                setStatus(data.status)
                const orderIndex = orders.findIndex(o => o.order._id === currentOrder.order._id)
                orders[orderIndex] = currentOrder
                setOrders(orders)
            }

        } catch (error) {
            // alert(error.response.data);
        }
    }
    return (
        <div style={{ padding: 0 }}>
            {selectableProducts ? <Row>
                <Col sm={6}>
                    <p className="p-header text-left" >
                        Order Sub ID <span className="p-content text-left pl-4">{orderItem.order_sub_id}</span>
                    </p>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            {disableEdit ?
                                <Badge color={statusColor[status].color} style={{ padding: statusColor[status].padding, cursor: 'pointer', marginleft: statusColor[status].marginLeft, marginRight: statusColor[status].marginRight }}>{status}</Badge>
                                : <StatusSelectBox
                                    options={[{ label: "SHIPPED", value: "SHIPPED" }, { label: "DELIVERED", value: "DELIVERED" }, { label: "CANCELLED", value: "CANCELLED" }]}
                                    onClickFunc={(data) => badgeClick({ status: data })}
                                    status={orderItem.status}
                                    poEdit={poEdit}
                                />}
                        </Col>
                    </Row>
                </Col>



            </Row> : <Row>
                <Col sm={6}>
                    <p className="p-header text-left" >
                        Order Sub ID <span className="p-content text-left pl-4">{orderItem.order_sub_id}</span>
                    </p>
                </Col>
                <Col className='d-flex justify-content-end'>
                    {disableEdit ?
                        <Badge color={statusColor[status].color} style={{ padding: statusColor[status].padding, cursor: 'pointer', marginleft: statusColor[status].marginLeft, marginRight: statusColor[status].marginRight }}>{status}</Badge>
                        : <StatusSelectBox
                            options={[{ label: "SHIPPED", value: "SHIPPED" }, { label: "DELIVERED", value: "DELIVERED" }, { label: "CANCELLED", value: "CANCELLED" }]}
                            onClickFunc={(data) => badgeClick({ status: data })}
                            status={orderItem.status}
                            poEdit={poEdit}
                        />}
                </Col>

            </Row>}

            <Row className="mt-5">
                {selectableProducts && <Col sm={1} className='d-flex justify-content-center align-items-center'>
                    <div style={{
                        paddingLeft: "2rem",

                    }}>
                        <Input
                            type="checkbox"
                            id={`${orderItem.orderItemId}-orderitem`}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedOrderItemIds(prev => [...prev, orderItem.orderItemId])
                                } else {
                                    setSelectedOrderItemIds(selectedOrderItemIds.filter(data => data !== orderItem.orderItemId))
                                }
                            }}
                            checked={selectedOrderItemIds.includes(orderItem.orderItemId)}
                        />
                    </div>
                </Col>}
                <Col sm={3} className='d-flex justify-content-center align-items-center'>
                    <div className="img-container">
                        <img src={orderItem.products?.image[0]} alt='' />
                    </div>
                </Col>
                <Col sm={8} className='d-flex justify-content-center align-items-center'>
                    <Row>
                        <Col sm={4}>
                            <p className="p-header text-left" >
                                {columns[0]}
                            </p>
                            <p className="p-content text-left">
                                {orderItem.products?.name || ""}
                            </p>
                            <p className="p-content text-left">
                                {orderItem.products?.SKU || ""}
                            </p>
                        </Col>
                        <Col sm={2}>
                            <p className="p-header">
                                {columns[1]}
                            </p>
                            <p className="p-content">
                                {orderItem.quantity}
                            </p>
                        </Col>
                        <Col sm={3}>
                            <p className="p-header">
                                {columns[2]}
                            </p>
                            <p className="p-content">
                                ₹ {orderItem.products?.cost_price || ""}
                            </p>
                        </Col>
                        <Col sm={3}>
                            <p className="p-header">
                                {columns[3]}
                            </p>
                            <p className="p-content">
                                ₹ {orderItem.products?.pixel_price || ""}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={12}><div className="p-divider mt-4"></div></Col>
            </Row>
        </div>
    )
}
export default ProductListComponent